import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const etdSettingsReducer = createSlice({
  name: 'etdSettings',
  initialState: {
    isEtdSettingsOpen: false,
  },
  reducers: {
    setIsEtdSettingsOpen(state, action) {
      state.isEtdSettingsOpen = action.payload;
    },
  },
});

export const { setIsEtdSettingsOpen } = etdSettingsReducer.actions;
export const selectIsEtdSettingOpen = (state: RootState) => {
  return state.etdSettingsReducer.isEtdSettingsOpen;
};
export default etdSettingsReducer.reducer;
