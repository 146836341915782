import { useSelector } from 'react-redux';
import { selectRoles } from '../../redux/reducers/settingsReducer';
import { Permission } from '../generated/graphql';
import { hasPermissions } from '../helpers';

export enum CheckType {
  PARTIAL,
  FULL,
}

/**
 * Custom hook to check if the user has the required permissions.
 * @param {Permission[]} requiredPermissions - An array of required permissions.
 *  * @param {CheckType} [checkingMode=CheckType.FULL] - Optional parameter specifying the checking mode:
 *                                                     - CheckType.PARTIAL: Returns true if the user has at least one of the required permissions.
 *                                                     - CheckType.FULL: Returns true if the user has all required permissions.
 * @param {string} [carrier] - Optional parameter specifying the carrier for which permissions are checked.
 * @param {string[]} [checkedInRoleIds] - Optional parameter specifying the checkedIn roles for which permissions are checked, if found.
 * @returns {boolean} - Returns true if the user has all required permissions, otherwise returns false.
 */
const useCheckPermissions = (
  requiredPermissions: Permission[],
  checkingMode: CheckType = CheckType.FULL,
  checkedInRoleIds: string[] | null = null,
  carrier?: string
): boolean => {
  const userRoles = useSelector(selectRoles);

  return hasPermissions(
    userRoles,
    requiredPermissions,
    checkingMode,
    carrier,
    checkedInRoleIds
  );
};

export default useCheckPermissions;
