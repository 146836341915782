import React from 'react';
import { number, shape, string } from 'prop-types';
import Title from '../../Title/Title';
import Grid from '../../Grid/Grid';
import { CATERING } from '../../../utils/constants';

const containerClassNames =
  'bg-white rounded-8 mobile:mx-24 py-32 pl-32 dark:bg-grey-90 tablet:col-span-1 laptop:col-span-1 desktop:col-span-1 mobile:order-4 order-6 max-h-[488px]';
const cateringClassNames =
  'font-body-text text-primary text-12 leading-[12px] mb-40 dark:text-grey-12 mobile:mb-24';
const subtitleClassNames =
  'font-head-light text-primary text-18 dark:text-grey-12 leading-[19px] mt-4';
const titleClassNames = 'mb-4 text-grey-40';
const inboundItemsClassNames =
  'dark:text-grey-12 text-primary font-body-thin text-80 leading-[80px]';
const totalItemsClassNames =
  'font-head-light text-18 leading-[19px] text-grey-40 mobile:mb mb-[9px] ml-8';
const inlineSubtitleClassNames = 'flex items-end';
const dividerClassNames = 'h-[1px] bg-grey-12 w-[100%]';
const totalLoadedClassNames = 'mb-32';
const gridClassNames = 'mt-32';

interface CateringProps {
  catering: {
    totalInboundLoaded?: number;
    totalLoaded?: number;
    first?: string;
    business?: string;
    premium?: string;
    economy?: string;
    vegetarian?: string;
    vegan?: string;
    diabetic?: string;
    standard?: string;
  };
}

const Catering = ({
  catering: {
    totalInboundLoaded,
    totalLoaded,
    first,
    business,
    premium,
    economy,
    vegetarian,
    vegan,
    diabetic,
    standard,
  } = {},
  ...others
}: CateringProps) => {
  return (
    <div className={containerClassNames} {...others}>
      <div className={cateringClassNames}>{CATERING.TITLE}</div>
      <Title
        title={CATERING.TOTAL_LOADED}
        titleColorClass={titleClassNames}
        className={totalLoadedClassNames}>
        <div className={inlineSubtitleClassNames}>
          <p className={inboundItemsClassNames}>{totalInboundLoaded}</p>
          <p className={totalItemsClassNames}>{totalLoaded}</p>
        </div>
      </Title>
      <hr className={dividerClassNames} />
      <Grid col={[2, 0, 24]} className={gridClassNames}>
        <Title title={CATERING.FIRST}>
          <div className={subtitleClassNames}>{first}</div>
        </Title>
        <Title title={CATERING.BUSINESS}>
          <div className={subtitleClassNames}>{business}</div>
        </Title>
        <Title title={CATERING.PREMIUM}>
          <div className={subtitleClassNames}>{premium}</div>
        </Title>
        <Title title={CATERING.ECONOMY}>
          <div className={subtitleClassNames}>{economy}</div>
        </Title>
        <Title title={CATERING.VEGETARIAN}>
          <div className={subtitleClassNames}>{vegetarian}</div>
        </Title>
        <Title title={CATERING.VEGAN}>
          <div className={subtitleClassNames}>{vegan}</div>
        </Title>
        <Title title={CATERING.DIABETIC}>
          <div className={subtitleClassNames}>{diabetic}</div>
        </Title>
        <Title title={CATERING.STANDARD}>
          <div className={subtitleClassNames}>{standard}</div>
        </Title>
      </Grid>
    </div>
  );
};

export default Catering;
