import { gql } from '@apollo/client';

export const CHAT_MESSAGES = gql`
  query ChatMessages(
    $lastStatusUpdate: String
    $role: String
    $type: ChatUpdateType!
  ) {
    chatMessages(
      lastStatusUpdate: $lastStatusUpdate
      role: $role
      type: $type
    ) {
      conversations {
        flightId
        flightName
        chatId
        lastConversationId
        lastTopic
        messages {
          conversationId
          createdAt
          messageId
          body
          createdBy
          isMe
          userName
          newUserJoined
          status
        }
      }
      lastStatusUpdate
    }
  }
`;
