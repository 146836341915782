import { gql } from '@apollo/client';

export const UPDATE_BOARDING_TIME = gql`
  mutation UpdateBoardingTime($input: UpdateBoardingTimeInput!) {
    updateBoardingTime(input: $input) {
      timeUpdated
      errors {
        code
        message
        description
      }
    }
  }
`;
