import React, { useRef } from 'react';
import moment, { Moment } from 'moment';
import Button from '../Button/Button';
import { DAY_PERIOD } from '../../utils/constants';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { useSelector } from 'react-redux';
import { select24Format } from '../../redux/reducers/settingsReducer';
import Datetime from 'react-datetime';
import './TimePickerInput.scss';

interface ITimePickerInput {
  dateTime: Moment;
  setDateTime: any;
  delayMinutes?: number;
  delayDays?: number;
  disabledButtons?: boolean;
}

const ICON_SIZE = 18;

const renderIcon = (icon) => {
  return (
    <Icon
      width={ICON_SIZE}
      height={ICON_SIZE}
      variant={icon}
      className="fill-white dark:fill-primary"
    />
  );
};

const TimePickerInput = ({
  dateTime,
  setDateTime,
  delayMinutes = 0,
  delayDays = 0,
  disabledButtons = false,
}: ITimePickerInput) => {
  const is24Format = useSelector(select24Format);
  const isNextDay = delayDays > 0;

  const isPastDate = dateTime.clone().isBefore(moment());
  const counter = useRef(0);
  const timeFormat = is24Format ? 'HH:mm' : 'hh:mm A';

  const handleMinutesChange = (action: string) => {
    if (action === 'ADD') {
      if (
        dateTime.clone().set('seconds', 1).isBefore(moment().set('seconds', 0))
      ) {
        const parsedDate = moment.parseZone(dateTime);
        const timezoneOffset = parsedDate.utcOffset();
        const currentDateInTimezone = moment()
          .set('seconds', 1)
          .clone()
          .utcOffset(timezoneOffset);
        setDateTime(currentDateInTimezone);
      } else {
        setDateTime(dateTime.clone().add(1, 'minutes'));
      }
    } else {
      setDateTime(dateTime.clone().subtract(1, 'minutes'));
    }
    counter.current++;
  };

  const handleDayPeriod = (input) => {
    if (input === 'PM') {
      handleDateTimeChange(dateTime.clone().add(12, 'hours'));
    } else {
      handleDateTimeChange(dateTime.clone().subtract(12, 'hours'));
    }
    counter.current++;
  };

  const renderDayPeriodButton = (dayPeriod) => {
    return (
      <Button
        text={dayPeriod}
        textClassName={classNames(
          'text-12 font-body-text font-bold text-grey-40 dark:text-grey-40',
          {
            'text-primary dark:text-white': dateTime.format('A') === dayPeriod,
          }
        )}
        onClick={() => handleDayPeriod(dayPeriod)}
      />
    );
  };

  const handleDateTimeChange = (newDateTime: Moment | string) => {
    if (typeof newDateTime !== 'string') {
      if (newDateTime.isBefore(moment())) {
        setDateTime(dateTime.clone());
        counter.current++;
      } else {
        setDateTime(newDateTime);
      }
    }
  };

  return (
    <div className="pt-32 pb-16">
      <div className="flex justify-evenly items-center">
        <Button
          Icon={renderIcon('minus')}
          className={classNames(
            'dark:bg-white h-40 w-40 rounded-full flex justify-center items-center',
            {
              'bg-grey-12': disabledButtons || isPastDate,
              'bg-primary': !(disabledButtons || isPastDate),
            }
          )}
          textClassName="leading-[32px] text-32 text-white dark:text-primary font-body-text"
          onClick={() => handleMinutesChange('SUBTRACT')}
          disabled={disabledButtons || isPastDate}
        />
        <div className="relative flex justify-center items-center w-[184px] h-[40px] py-40 px-32 rounded-12 border-grey-12 border-1 text-primary  font-head-light text-center">
          <Datetime
            key={counter.current}
            initialViewMode="time"
            isValidDate={(current) =>
              current.isAfter(moment().subtract(1, 'day'))
            }
            closeOnSelect
            timeFormat={timeFormat}
            onChange={handleDateTimeChange}
            initialViewDate={dateTime}
            renderInput={(props) => (
              <input
                disabled={disabledButtons}
                {...props}
                className="w-[180px] text-56 text-center dark:text-white dark:bg-grey-100 leading-none [-moz-appearance:textfield]"
                readOnly
                value={dateTime.format(is24Format ? 'HH:mm' : 'hh:mm')}
              />
            )}
          />
          {!!delayMinutes && (
            <div
              className={classNames(
                'absolute -top-[20px] right-[10px] dark:text-red text-red text-12 font-body-text flex items-center justify-center',
                { 'right-[32px]': isNextDay }
              )}>
              ETD +{delayMinutes} min
            </div>
          )}
          {isNextDay && (
            <div className="absolute -top-12 -right-12 font-body text-primary text-16 border-primary dark:border-grey-12 border-1 rounded-full h-32 w-32 bg-white flex items-center justify-center">
              {`+${delayDays}`}
            </div>
          )}
        </div>
        <Button
          Icon={renderIcon('plus')}
          className={classNames(
            ' dark:bg-white h-40 w-40 rounded-full flex justify-center items-center',
            { 'bg-grey-12': disabledButtons, 'bg-primary': !disabledButtons }
          )}
          textClassName="leading-[32px] text-32 text-white dark:text-primary font-body-text"
          onClick={() => handleMinutesChange('ADD')}
          disabled={disabledButtons}
        />
      </div>
      {!is24Format && (
        <div className="flex justify-center gap-10 pt-16">
          {renderDayPeriodButton(DAY_PERIOD.AM)}
          {renderDayPeriodButton(DAY_PERIOD.PM)}
        </div>
      )}
    </div>
  );
};

export default TimePickerInput;
