import { useQuery } from '@apollo/client';
import { Query, FlightSearch } from '../generated/graphql';
import { IDataResponse } from '../types';
import { SEARCH_FLIGHTS } from '../../graphql/flightSearch/flight-search.queries';

export const useSearchFlights = (
  query: string | null
): IDataResponse<FlightSearch[]> => {
  const skipCondition = query ? query.length > 0 && query.length < 3 : false;
  const { data, loading, error } = useQuery<Query>(SEARCH_FLIGHTS, {
    variables: {
      genericQuery: query,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipCondition,
  });
  return { data: data?.flightSearch?.data ?? null, loading, error };
};
