import React, {
  useState,
  KeyboardEvent,
  ChangeEvent,
  FocusEvent,
  createRef,
  useRef,
  RefObject,
  useEffect,
} from 'react';
import { MY_STATION } from '../../../utils/constants';
import classNames from 'classnames';
import StationTag from './StationTag/StationTag';
import Title from '../../Title/Title';
import { useForm } from '../../../utils/hooks/useForm';
import { validateStations } from '../../../utils/helpers';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';

const STATION_LETTERS = 3;
const MAX_NO_STATIONS =
  parseInt(process.env.REACT_APP_TAC_STATION_LIST_LIMIT ?? '1') || 1;

const stationOnChange = (value: string) => {
  return value.toUpperCase().replace(/[^A-Z]/g, '');
};

interface IStationInput {
  stationsArray?: string[];
  onStationsChange: (arr: string[]) => void;
  title?: string;
  containerClassName?: string;
  hasMaxNoStation?: boolean;
  className?: string;
  isOnNotification?: boolean;
  titleClassName?: string;
  disabled?: boolean;
}

const StationInput = ({
  stationsArray = [],
  onStationsChange,
  title = MY_STATION,
  containerClassName,
  hasMaxNoStation = true,
  className,
  isOnNotification = false,
  titleClassName,
  disabled = false,
  ...others
}: IStationInput) => {
  const [stations, setStations] = useState<
    { ref: RefObject<HTMLDivElement>; label: string }[]
  >(
    stationsArray.map((station) => {
      return {
        ref: createRef(),
        label: station,
      };
    })
  );

  useEffect(() => {
    setStations(
      stationsArray.map((station) => {
        return {
          ref: createRef(),
          label: station,
        };
      })
    );
  }, [stationsArray]);

  const [input, setInput] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const clickOutsideRef = useRef(null);
  useClickOutside(clickOutsideRef, () => setIsEditMode(false));

  const isOffline = !useNetworkStatus();

  const {
    isError,
    errorMessage,
    onBlur,
    onKeyDown,
    validate,
    setIsTouched,
    isTouched,
  } = useForm({
    validation: (value) => validateStations(value, 2),
    validateIfTouched: true,
    currentValue: input,
  });
  const containerClassNames = classNames(
    { [containerClassName ?? '']: containerClassName },

    'w-full overflow-hidden bg-white border-1 border-primary rounded-4 px-14 py-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:bg-grey-90 dark:border-grey-12 dark:text-grey-12 flex flex-wrap items-center gap-4',
    { 'min-h-[35px] mt-0': isOnNotification },
    { 'mt-8 min-h-[46px]': !isOnNotification },
    {
      'border-red': isError,
      absolute: !isOnNotification && isEditMode,
      relative: !isEditMode,
      '!border-black': isOnNotification,
    }
  );
  const errorClassName = 'text-red pt-[4px]';

  const transformIntoLabel = (value: string) => {
    if (value) {
      if (!stations.length) {
        setStations([...stations, { label: value, ref: createRef() }]);
      } else if (stations.findIndex((station) => station.label === value) < 0) {
        setStations([...stations, { label: value, ref: createRef() }]);
      }
    }
  };

  const handleTransformIntoLabel = (
    e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value;
    if (validateStations(value, 2).isError) return;
    transformIntoLabel(value);
    setInput('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onKeyDown(e);
      handleTransformIntoLabel(e);
    }
    if (e.key === 'Backspace' && input === '') {
      const lastRef = stations[stations.length - 1]?.ref;
      if (lastRef) {
        if (lastRef.current?.getAttribute('data-selected') === 'true') {
          removeTag(stations.length - 1);
        }
        lastRef.current?.setAttribute('data-selected', 'true');
      }
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    stations.forEach((station) => {
      station.ref.current?.setAttribute('data-selected', 'false');
    });
    const value = e.target.value;
    const [nextStation, nextLetter] = [
      value.slice(0, STATION_LETTERS),
      value.slice(STATION_LETTERS),
    ];
    if (hasMaxNoStation && stations.length >= MAX_NO_STATIONS) return;
    if (value.length <= STATION_LETTERS) {
      setInput(stationOnChange(nextStation));
    } else if (hasMaxNoStation && stations.length === MAX_NO_STATIONS - 1) {
      transformIntoLabel(nextStation);
      setInput('');
    } else {
      transformIntoLabel(nextStation);
      setInput(stationOnChange(nextLetter));
    }
  };

  const removeTag = (index: number) => {
    setStations(stations.filter((_, i) => i !== index));
  };

  const sortStations = (index: number) => {
    const itemToMove = stations[index];
    const newStations = [
      itemToMove,
      ...stations.slice(0, index),
      ...stations.slice(index + 1),
    ];
    onStationsChange(newStations.map((station) => station.label));
    setStations(newStations);
  };

  useEffect(() => {
    if (isTouched) {
      validate(input);
    }
    onStationsChange(stations.map((station) => station.label));
  }, [stations.length]);

  return (
    <>
      <Title
        title={title}
        titleColorClass={titleClassName}
        className={classNames('relative min-h-[67px]', {
          [className ?? '']: className,
        })}
        {...others}>
        <div
          className={classNames(containerClassNames, {
            'opacity-50': isOffline || disabled,
          })}
          onClick={(e) => {
            e.stopPropagation();
            !isOffline && setIsEditMode(true);
          }}
          ref={clickOutsideRef}>
          {!!stations.length &&
            (isEditMode ? (
              <>
                {stations.map((station, index) => (
                  <StationTag
                    key={station.label}
                    station={station.label}
                    ref={station.ref}
                    onClose={() => removeTag(index)}
                    onSort={() => sortStations(index)}
                    data-testid={`station-tag-${index}`}
                  />
                ))}
              </>
            ) : (
              <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap gap-0.5">
                {stations.map((station) => station.label).join(', ')}
              </div>
            ))}
          {(isEditMode || !stations.length) && (
            <input
              name="station2"
              autoFocus={isEditMode}
              type="text"
              pattern="[A-Za-z]"
              value={input}
              onBlur={(e) => {
                handleTransformIntoLabel(e);
                onBlur(e);
              }}
              onFocus={() => {
                setIsTouched(true);
              }}
              onChange={handleOnChange}
              className={classNames(
                'border-none outline-none w-[50px] grow dark:bg-grey-90 ',
                { 'h-[29px]': !isOnNotification },
                { 'h-[21px]': isOnNotification }
              )}
              onKeyDown={handleKeyDown}
              disabled={isOffline || disabled}
            />
          )}
        </div>
      </Title>
      {errorMessage && <p className={errorClassName}>{errorMessage}</p>}
    </>
  );
};

export default StationInput;
