import { useQuery } from '@apollo/client';
import { Query, InboundFlight, DataError } from '../generated/graphql';
import { IDataResponse } from '../types';
import { AIRCRAFT_ROTATION } from '../../graphql/flightDetails/flight-details.queries';
import useNetworkStatus from './useNetworkStatus';

interface IGetAircraftRotation extends IDataResponse<InboundFlight> {
  refetchAircraftRotation: () => Promise<InboundFlight | null>;
  loadingFirstTime: boolean;
  responseErrors: DataError[] | null;
}

export const useGetAircraftRotation = (
  flightId: string
): IGetAircraftRotation => {
  const isOffline = !useNetworkStatus();
  const { data, loading, error, networkStatus, refetch } = useQuery<
    Pick<Query, 'aircraftRotation'>
  >(AIRCRAFT_ROTATION, {
    variables: {
      flightId,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    skip: isOffline,
  });

  const refetchAircraftRotation = async () => {
    const result = await refetch();

    return result?.data?.aircraftRotation?.data ?? null;
  };

  const convertedErrors =
    data?.aircraftRotation?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ?? null;

  return {
    data: data?.aircraftRotation?.data ?? null,
    loading,
    error,
    responseErrors: convertedErrors,
    refetchAircraftRotation,
    loadingFirstTime: networkStatus === 1,
  };
};
