import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UtilFactory from '../../factory/UtilFactory';
import { DataStatus } from '../../models/DataStatus';
import { RootState } from '../store';

interface MqttState {
  status: DataStatus;
  clientId: string;
  mqttJwtToken: string;
  mqttTokenError: string | null;
}

const initialState: MqttState = {
  clientId: '',
  status: DataStatus.IDLE,
  mqttJwtToken: '',
  mqttTokenError: null,
};

interface IGetMqttTokenParams {
  clientId: string;
}

export const getMqttToken = createAsyncThunk<MqttState, IGetMqttTokenParams>(
  'mqtt/auth',
  async (params: IGetMqttTokenParams): Promise<MqttState> => {
    try {
      const jwt = await UtilFactory.newMessageHandler().authenticate(
        params.clientId
      );
      if (!jwt) {
        console.error('Invalid mqtt token!');
        return {
          status: DataStatus.FAILED,
          clientId: '',
          mqttJwtToken: '',
          mqttTokenError:
            'Could not authenticate to message service for notifications!',
        };
      }

      return {
        status: DataStatus.IDLE,
        clientId: params.clientId,
        mqttJwtToken: jwt,
        mqttTokenError: null,
      };
    } catch (err) {
      console.error(
        'Could not authenticate to message service for notifications!'
      );
      return {
        status: DataStatus.FAILED,
        clientId: '',
        mqttJwtToken: '',
        mqttTokenError:
          'Could not authenticate to message service for notifications!',
      };
    }
  }
);

export const mqttReducer = createSlice({
  name: 'mqtt',
  initialState,
  reducers: {
    setMqttJwtToken(state, action) {
      state.mqttJwtToken = action.payload;
    },
    setError(state, action) {
      state.mqttTokenError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMqttToken.pending, (state) => {
        state.status = DataStatus.LOADING;
      })
      .addCase(
        getMqttToken.fulfilled,
        (state, action: PayloadAction<MqttState>) => {
          state.status = action.payload.status;
          state.clientId = action.payload.clientId;
          state.mqttJwtToken = action.payload.mqttJwtToken;
          state.mqttTokenError = action.payload.mqttTokenError;
        }
      );
  },
});

export const getMqttState = (state: RootState): MqttState => state.mqttReducer;

export default mqttReducer.reducer;
