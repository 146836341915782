import { useMutation } from '@apollo/client';
import {
  Mutation,
  UpdateBoardingTimeInput,
  UpdateBoardingTimeOutput,
} from '../generated/graphql';
import { UPDATE_BOARDING_TIME } from '../../graphql/flightDetails/flight-details.mutations';
import { IMutationResponse } from '../types';

interface UpdateBoardingTimeResponse
  extends IMutationResponse<UpdateBoardingTimeOutput> {
  onUpdateBoardingTime: (
    input: UpdateBoardingTimeInput
  ) => Promise<UpdateBoardingTimeOutput | null>;
}

export const useUpdateBoardingTime = (): UpdateBoardingTimeResponse => {
  const [updateBoardingTime, { data, error, loading }] =
    useMutation<Pick<Mutation, 'updateBoardingTime'>>(UPDATE_BOARDING_TIME);
  const onUpdateBoardingTime = async (input: UpdateBoardingTimeInput) => {
    try {
      const result = await updateBoardingTime({
        variables: { input },
      });

      return result.data?.updateBoardingTime ?? null;
    } catch (err) {
      return null;
    }
  };

  return {
    onUpdateBoardingTime,
    data: data?.updateBoardingTime ?? null,
    loading,
    error,
  };
};
