import { useQuery } from '@apollo/client';
import { Query, BaggageList, DataError } from '../generated/graphql';
import { IDataResponse } from '../types';
import { BAGGAGE_LIST } from '../../graphql/flightDetails/flight-details.queries';

interface IBaggageListParams {
  flightId: string;
  localStation: string;
  sortBy: string;
  order: string;
}

interface IGetBaggageList extends IDataResponse<BaggageList[]> {
  refetchBaggageList: () => Promise<BaggageList[] | null>;
  responseErrors: DataError[] | null;
}

export const useGetBaggageList = (
  flightInfo: IBaggageListParams
): IGetBaggageList => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'baggageList'>
  >(BAGGAGE_LIST, {
    variables: {
      flightInfo,
    },
    notifyOnNetworkStatusChange: true,
  });

  const filteredBaggageList = data?.baggageList?.data ?? null;

  const baggageListData: BaggageList[] = (filteredBaggageList || []).filter(
    (baggage): baggage is BaggageList => baggage !== null
  );

  const refetchBaggageList = async () => {
    const result = await refetch();
    const filteredBaggageList = result?.data.baggageList?.data ?? null;

    const baggageListData: BaggageList[] = (filteredBaggageList || []).filter(
      (baggage): baggage is BaggageList => baggage !== null
    );
    return baggageListData;
  };

  const convertedErrors =
    data?.baggageList?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ?? null;

  return {
    data: baggageListData,
    loading,
    error,
    responseErrors: convertedErrors,
    refetchBaggageList,
  };
};
