import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const boardingTimeReducer = createSlice({
  name: 'boardingTime',
  initialState: {
    isBoardingTimeOpen: false,
  },
  reducers: {
    setIsBoardingTimeOpen(state, action) {
      state.isBoardingTimeOpen = action.payload;
    },
  },
});

export const { setIsBoardingTimeOpen } = boardingTimeReducer.actions;
export const selectIsBoardingTimeOpen = (state: RootState) => {
  return state.boardingTimeReducer.isBoardingTimeOpen;
};
export default boardingTimeReducer.reducer;
