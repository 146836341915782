import React, { useId } from 'react';
import styles from './LoadingSpinner.module.scss';
import { useSelector } from 'react-redux';
import { selectTheming } from '../../redux/reducers/settingsReducer';
import { THEME } from '../../utils/constants';
import classNames from 'classnames';

interface ILoadingSpinner {
  width?: number;
  height?: number;
  extraClassNames?: string;
}

const LoadingSpinner = ({
  width = 40,
  height = 40,
  extraClassNames,
  ...others
}: ILoadingSpinner) => {
  const theme = useSelector(selectTheming);
  const svgId = useId();

  return (
    <div
      className={classNames(
        'flex grow justify-center items-center py-4',
        extraClassNames
      )}
      {...others}>
      <svg
        className={styles['spinner-svg']}
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="20"
          cy="20"
          r="19.5"
          stroke={theme === THEME.DARK ? 'white' : '#828AA6'}
        />
        <mask id={svgId} fill="white" width="40" height="40">
          <path d="M40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65491 36.9392 5.85786 34.1421C3.06081 31.3451 1.156 27.7814 0.384294 23.9018C-0.38741 20.0222 0.00865672 16.0009 1.52241 12.3463C3.03616 8.69181 5.59961 5.56824 8.8886 3.37061C12.1776 1.17298 16.0444 -4.71704e-08 20 0V7C17.4288 7 14.9154 7.76244 12.7776 9.1909C10.6397 10.6194 8.97351 12.6497 7.98957 15.0251C7.00563 17.4006 6.74818 20.0144 7.24979 22.5362C7.7514 25.0579 8.98953 27.3743 10.8076 29.1924C12.6257 31.0105 14.9421 32.2486 17.4638 32.7502C19.9856 33.2518 22.5994 32.9944 24.9749 32.0104C27.3503 31.0265 29.3806 29.3603 30.8091 27.2224C32.2376 25.0846 33 22.5712 33 20H40Z" />
        </mask>
        <path
          d="M40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65491 36.9392 5.85786 34.1421C3.06081 31.3451 1.156 27.7814 0.384294 23.9018C-0.38741 20.0222 0.00865672 16.0009 1.52241 12.3463C3.03616 8.69181 5.59961 5.56824 8.8886 3.37061C12.1776 1.17298 16.0444 -4.71704e-08 20 0V7C17.4288 7 14.9154 7.76244 12.7776 9.1909C10.6397 10.6194 8.97351 12.6497 7.98957 15.0251C7.00563 17.4006 6.74818 20.0144 7.24979 22.5362C7.7514 25.0579 8.98953 27.3743 10.8076 29.1924C12.6257 31.0105 14.9421 32.2486 17.4638 32.7502C19.9856 33.2518 22.5994 32.9944 24.9749 32.0104C27.3503 31.0265 29.3806 29.3603 30.8091 27.2224C32.2376 25.0846 33 22.5712 33 20H40Z"
          stroke="#FFAD00"
          strokeWidth="8"
          mask={`url(#${svgId})`}
        />
      </svg>
    </div>
  );
};

export default LoadingSpinner;
