import { useQuery } from '@apollo/client';
import { Query, BelowTheWing, DataError } from '../generated/graphql';
import { IDataResponse } from '../types';
import { BELOW_THE_WING } from '../../graphql/flightDetails/flight-details.queries';
import useNetworkStatus from './useNetworkStatus';

interface IGetBelowTheWing extends IDataResponse<BelowTheWing> {
  refetchBelowTheWing: () => Promise<BelowTheWing | null>;
  loadingFirstTime: boolean;
  responseErrors: DataError[] | null;
}

export const useGetBelowTheWing = (
  flightId: string,
  localStation?: string,
  shouldSkip?: boolean
): IGetBelowTheWing => {
  const isOffline = !useNetworkStatus();
  const { data, loading, error, networkStatus, refetch } = useQuery<
    Pick<Query, 'belowTheWing'>
  >(BELOW_THE_WING, {
    variables: {
      flightInfo: {
        flightId,
        localStation,
      },
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    skip: shouldSkip || isOffline,
  });

  const refetchBelowTheWing = async () => {
    const result = await refetch();

    return result?.data?.belowTheWing?.data ?? null;
  };

  const convertedErrors =
    data?.belowTheWing?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ?? null;

  return {
    data: data?.belowTheWing?.data ?? null,
    loading,
    error,
    responseErrors: convertedErrors,
    refetchBelowTheWing,
    loadingFirstTime: networkStatus === 1,
  };
};
