import { useQuery } from '@apollo/client';
import { Query, CachedMyFlight } from '../generated/graphql';
import { IDataResponse } from '../types';
import { GET_MY_FLIGHTS_CACHED } from '../../graphql/myFlights/my-flights.queries';
import { isUserLoggedIn } from '../helpers';

interface IGetMyFlightsCached extends IDataResponse<CachedMyFlight[]> {
  refetchMyFlightsCache: () => Promise<CachedMyFlight[]>;
}

export const useGetMyFlightsCached = (userId: string): IGetMyFlightsCached => {
  const isLoggedIn = isUserLoggedIn();

  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'cachedMyFlights'>
  >(GET_MY_FLIGHTS_CACHED, {
    variables: { userId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !isLoggedIn,
  });

  const refetchMyFlightsCache = async () => {
    const result = await refetch();

    return result.data.cachedMyFlights ?? [];
  };

  return {
    data: data?.cachedMyFlights ?? [],
    loading,
    error,
    refetchMyFlightsCache,
  };
};
