import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckedoutHighlander,
  setCheckedoutHighlander,
} from '../../redux/reducers/checkinReducer';
import Modal from '../Modal/Modal';
import { CONFIRM, HIGHLANDER_CHECKOUT_MODAL_COPY } from '../../utils/constants';
import { getFlightName } from '../../utils/helpers';

interface IHighlanderProvider {
  children: ReactNode;
}

const HighlanderProvider = ({ children }: IHighlanderProvider) => {
  const dispatch = useDispatch();
  const {
    isCheckedOut: isShowingModal,
    checkedoutFlight,
    checkedoutRole,
  } = useSelector(selectCheckedoutHighlander) || {};

  const handleCloseModal = () => {
    dispatch(
      setCheckedoutHighlander({
        isCheckedOut: false,
        checkedoutFlight: null,
        checkedoutRole: null,
      })
    );
  };

  return (
    <>
      {children}
      {isShowingModal && (
        <Modal
          title=""
          showModal={isShowingModal}
          setShowModal={handleCloseModal}
          onSubmit={handleCloseModal}
          submitText={CONFIRM}
          showControls={true}>
          <div className="flex flex-col gap-y-6 items-center justify-center pb-[50px]">
            {HIGHLANDER_CHECKOUT_MODAL_COPY(
              checkedoutRole,
              getFlightName(checkedoutFlight)
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default HighlanderProvider;
