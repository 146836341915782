import { GET_MY_FLIGHTS } from '../../graphql/myFlights/my-flights.queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Query, MyFlight } from '../generated/graphql';
import { IDataResponse } from '../types';
import { useCallback } from 'react';

interface IMyFlightsFilter {
  flightId?: string;
  stationsList?: string[];
}

interface IGetMyFlights extends IDataResponse<MyFlight[]> {
  refetchMyFlights: (filter?: IMyFlightsFilter) => Promise<MyFlight[]>;
}

export const useGetMyFlights = (
  filter: IMyFlightsFilter = {}
): IGetMyFlights => {
  const { data, loading, error, refetch } = useQuery<Pick<Query, 'myFlights'>>(
    GET_MY_FLIGHTS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        flightId: filter.flightId,
        stationsList: filter.stationsList,
      },
    }
  );

  const refetchMyFlights = useCallback(
    async (filter: IMyFlightsFilter = {}) => {
      try {
        const result = await refetch({
          flightId: filter.flightId,
          stationsList: filter.stationsList,
        });

        return result.data.myFlights?.data ?? [];
      } catch (err) {
        return err;
      }
    },
    [refetch]
  );

  return {
    data: data?.myFlights?.data ?? null,
    loading,
    error,
    refetchMyFlights,
  };
};

interface IGetMyFlightsLazy extends IDataResponse<MyFlight[]> {
  getMyFlights: (filter: IMyFlightsFilter) => Promise<MyFlight[]>;
}

export const useGetMyFlightsLazy = (): IGetMyFlightsLazy => {
  const [onGetMyFlights, { data, loading, error }] = useLazyQuery<
    Pick<Query, 'myFlights'>
  >(GET_MY_FLIGHTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const getMyFlights = async (filter: IMyFlightsFilter) => {
    const result = await onGetMyFlights({
      variables: {
        flightId: filter.flightId,
        stationsList: filter.stationsList,
      },
    });

    return result?.data?.myFlights?.data ?? [];
  };

  return { getMyFlights, data: data?.myFlights?.data ?? null, loading, error };
};
