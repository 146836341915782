import star from './star.svg';
import logout from './logout.svg';
import plane from './plane.svg';
import cancel from './cancel.svg';
import search from './search.svg';
import defaultIcon from './defaultIcon.svg';
import close from './close.svg';
import alert from './alert.svg';
import upsidePlane from './upsidePlane.svg';
import arrow from './arrow.svg';
import mute from './mute.svg';
import arrowUp from './arrowUp.svg';
import info from './info.svg';
import sunRising from './sunRising.svg';
import sun from './sun.svg';
import moon from './moon.svg';
import arrowExpand from './arrowExpand.svg';
import call from './call.svg';
import lightning from './lightning.svg';
import arrowCollapse from './arrowCollapse.svg';
import gear from './gear.svg';
import home from './home.svg';
import baggage from './baggage.svg';
import person from './person.svg';
import triangleUp from './triangleUp.svg';
import triangleDown from './triangleDown.svg';
import circle from './circle.svg';
import seat from './seat.svg';
import people from './people.svg';
import arrowBack from './arrowBack.svg';
import menu from './menu.svg';
import backArrow from './backArrow.svg';
import reload from './reload.svg';
import listMenu from './listMenu.svg';
import message from './message.svg';
import feedback from './feedback.svg';
import arrowPrevious from './arrowPrevious.svg';
import arrowNext from './arrowNext.svg';
import pen from './pen.svg';
import checkmark from './checkmark.svg';
import unmute from './unmute.svg';
import checked from './checked.svg';
import unchecked from './unchecked.svg';
import filter from './filter.svg';
import deleteIcon from './delete.svg';
import admin from './admin.svg';
import chat from './chat.svg';
import takeoff from './takeoff.svg';
import QR from './QR.svg';
import messageSent from './messageSent.svg';
import messageDelivered from './messageDelivered.svg';
import messageFailed from './messageFailed.svg';
import messageRead from './messageRead.svg';
import messageSending from './messageSending.svg';
import plus from './plus.svg';
import minus from './minus.svg';
import more from './more.svg';
import simpleMessage from './simpleMessage.svg';
import profile from './profile.svg';

const icons = {
  star,
  logout,
  plane,
  cancel,
  close,
  alert,
  search,
  upsidePlane,
  arrow,
  mute,
  arrowUp,
  info,
  sunRising,
  sun,
  moon,
  arrowExpand,
  call,
  lightning,
  arrowCollapse,
  simpleMessage,
  gear,
  home,
  baggage,
  person,
  triangleUp,
  triangleDown,
  circle,
  seat,
  people,
  arrowBack,
  menu,
  backArrow,
  reload,
  listMenu,
  message,
  feedback,
  arrowPrevious,
  arrowNext,
  pen,
  profile,
  checkmark,
  unmute,
  checked,
  unchecked,
  filter,
  admin,
  deleteIcon,
  chat,
  takeoff,
  QR,
  messageSending,
  messageSent,
  messageRead,
  messageDelivered,
  messageFailed,
  plus,
  minus,
  more,
  default: defaultIcon,
};

export default icons;
