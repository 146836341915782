import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ITimesObject } from '../../utils/types';

interface HeaderReducerState {
  headerTypeMobile: string;
  headerTypeTablet: string | null;
  themeColor: string | null;
  hasBackArrow: boolean;
  title: string;
  flightName: string;
  time: string;
  departureAirport: string;
  arrivalAirport: string;
  gate: string;
  position: string;
  type: string;
  registration: string;
  bestTimeLogic: {
    timeLeft: string;
    delay: string;
  } | null;
  departureTimes: ITimesObject | null;
  isMenuOpen: boolean;
  isCheckedIn?: boolean;
}

const headerState: HeaderReducerState = {
  headerTypeMobile: 'simple', // simple | flight
  headerTypeTablet: null, //null | flight
  themeColor: null,
  hasBackArrow: false,
  title: '',
  flightName: '',
  time: '',
  departureAirport: '',
  arrivalAirport: '',
  gate: '',
  position: '',
  type: '',
  registration: '',
  bestTimeLogic: null,
  departureTimes: null,
  isMenuOpen: false,
};

//entire reducer
export const headerReducer = createSlice({
  name: 'HEADER_TAC',
  initialState: headerState,
  reducers: {
    setConfig(state, action) {
      const {
        headerTypeMobile,
        headerTypeTablet,
        hasBackArrow,
        themeColor,
        title,
        flightName,
        time,
        departureAirport,
        arrivalAirport,
        gate,
        position,
        type,
        registration,
        bestTimeLogic,
        departureTimes,
        isCheckedIn,
      } = action.payload;
      state.headerTypeMobile = headerTypeMobile;
      state.headerTypeTablet = headerTypeTablet;
      state.hasBackArrow = hasBackArrow;
      state.themeColor = themeColor;
      state.title = title;
      state.flightName = flightName;
      state.time = time;
      state.departureAirport = departureAirport;
      state.arrivalAirport = arrivalAirport;
      state.gate = gate;
      state.position = position;
      state.type = type;
      state.registration = registration;
      state.bestTimeLogic = bestTimeLogic;
      state.departureTimes = departureTimes;
      state.isCheckedIn = isCheckedIn;
    },
    setIsCheckIn(state, action) {
      state.isCheckedIn = action.payload;
    },
    setIsMenuOpen(state, action) {
      state.isMenuOpen = action.payload;
    },
  },
});

export const { setConfig, setIsMenuOpen, setIsCheckIn } = headerReducer.actions;
export const selectIsMenuOpen = (state: RootState) =>
  state.headerReducer.isMenuOpen;
export default headerReducer.reducer;
