import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useId,
  useRef,
  KeyboardEvent,
} from 'react';
import classNames from 'classnames';
import Icon from '../../../../Icon/Icon';
import Button from '../../../../Button/Button';
import TextArea from '../../../../TextArea/TextArea';
import {
  getIsTouchDevice,
  scrollToBottomOfContainer,
} from '../../../../../utils/helpers';
import { MOBILE_BREAKPOINT } from '../../../../../utils/constants';

export enum SubmitIcon {
  Search = 'search',
  Plane = 'plane',
}

interface ISearchInputProps {
  placeholderValue?: string;
  width?: string;
  className: string;
  onSubmit: (value: string | null) => void;
  defaultValue?: string | null;
  error?: string | null;
  containerId: string;
  disabled?: boolean;
}

const MAX_INPUT_CHARACTERS = 200;
const MAX_ROWS = 6;
const MIN_ROWS = 1;

const isTouchDevice = getIsTouchDevice();

const ChatInput = ({
  placeholderValue = '',
  width = '',
  className,
  onSubmit,
  defaultValue = '',
  error,
  containerId,
  disabled,
  ...others
}: ISearchInputProps) => {
  const [chatInputValue, setChatInputValue] = useState(defaultValue);
  const [rows, setRows] = React.useState(MIN_ROWS);
  const textareaId = useId();
  const isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
  const textAreaRef: React.RefObject<HTMLTextAreaElement> = useRef(null);
  const isTextAreaEmpty = () => !/\S+/g.test(chatInputValue ?? '');

  useEffect(() => {
    if (!textAreaRef.current) {
      return;
    }
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height =
      textAreaRef.current.scrollHeight + 2 + 'px';
  }, [chatInputValue]);

  useLayoutEffect(() => {
    scrollToBottomOfContainer(`messagesContainer${containerId}`);
  }, [rows]);

  const containerClassNames = 'relative w-full rounded-4';
  const inputClassNames = classNames(
    'max-h-[118px] py-16 pl-14 pr-32 w-full leading-[14px] bg-white rounded-4 text-14 focus:outline-none font-body-text border-1 border-grey-25 focus:border-1 focus:border-primary dark:bg-grey-90 dark:border-grey-40 dark:text-grey-12 overflow-y-auto',
    {
      'border-red': error,
      [className]: className,
    }
  );
  const buttonClassNames = 'mb-[2px] p-16 rounded-4 z-10';
  const errorClassNames = classNames(
    'text-red font-body-text text-12 h-12 mt-8',
    {
      invisible: !error,
    }
  );

  const handleOnSubmit = () => {
    chatInputValue !== '' && onSubmit(chatInputValue);
    setChatInputValue('');
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= MAX_INPUT_CHARACTERS) {
      setChatInputValue(inputValue);
    }
  };

  const enterHandler = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (isTextAreaEmpty()) return;

    if (!isMobile && e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleOnSubmit();
    }
  };

  useEffect(() => {
    const rowlen = chatInputValue?.split('\n');
    if (!rowlen) {
      return;
    }

    if (rowlen.length <= MAX_ROWS) {
      setRows(rowlen.length);
    } else {
      setRows(MAX_ROWS);
    }
  }, [chatInputValue]);

  return (
    <>
      <div
        className={containerClassNames}
        style={{ maxWidth: width }}
        {...others}>
        <TextArea
          value={chatInputValue === null ? '' : chatInputValue}
          onChange={handleSearchInputChange}
          className={inputClassNames}
          rows={1}
          onKeyDown={enterHandler}
          placeholder={placeholderValue}
          autoFocus={!isTouchDevice}
          onBlur={() => {
            if (!isTouchDevice) {
              document.getElementById(textareaId)?.focus();
            }
          }}
          id={textareaId}
          ref={textAreaRef}
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 flex items-end">
          <Button
            data-testid="search-icon-button"
            className={buttonClassNames}
            disabled={disabled || isTextAreaEmpty()}
            onClick={handleOnSubmit}
            Icon={
              <Icon
                variant="plane"
                className={
                  isTextAreaEmpty()
                    ? 'fill-grey-40 dark:fill-grey-1'
                    : 'fill-primary dark:fill-white'
                }
                width={24}
                height={24}
              />
            }
          />
        </div>
      </div>
      <div className={errorClassNames}>{error}</div>
    </>
  );
};

export default ChatInput;
