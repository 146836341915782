import moment from 'moment'

const getWidth = (end, start, minutePixels) =>
  ((moment(end, 'DD-MM-YYYY HH:mm').format('X') -
      moment(start, 'DD-MM-YYYY HH:mm').format('X')) /
    60) *
  //-1 to remove the 1px border
  minutePixels -
  1 +
  'px'

export const barWidthDelay = (
  { start, end, delayStart, delayEnd },
  currentTime,
  minutePixels,
  processVariable
) => (
  //Return for Margins Duplicated. WIP
  {
    marginLeft:
      ((moment(currentTime, 'HH:mm').subtract(2.5, 'hours').format('X') -
          moment(delayStart, 'DD-MM-YYYY HH:mm').format('X')) /
        60) *
      -minutePixels +
      processVariable +
      'px',
    width:
      delayEnd ? getWidth(delayEnd, delayStart, minutePixels) : getWidth(end, start, minutePixels)
  }
)
