import React, { useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  HERO_COPY,
  GREETING,
  MY_FLIGHTS,
  IS_MORNING_HOURS,
  IS_EVENING_HOURS,
  IS_DAY_TIME,
  IS_NOON_HOURS,
  ERRORS,
} from '../../utils/constants';
import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import SearchInput from '../SearchInput/SearchInput';
import FlightBlock from '../MyFlights/FlightBlock/FlightBlock';
import { setQuery } from '../../redux/reducers/searchReducer';
import { IFlightDataObject } from '../MyFlights/FlightBlock/FlightBlock';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { Permission } from '../../utils/generated/graphql';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';

const myFlightsButton =
  'flex justify-center items-center bg-primary h-[49px] w-full max-w-[294px] rounded-4 mb-16';
const myFlightsText =
  'leading-[18px] ml-[15px] font-head-bold font-[700] text-white';

interface IHero {
  name: string;
  hasFavourites: boolean;
  weatherData?: {
    temperature: string;
    description: string;
  } | null;
  nextFavouriteFlight?: IFlightDataObject | null;
}

const Hero = ({
  name,
  hasFavourites = false,
  weatherData = null,
  nextFavouriteFlight = null,
  ...others
}: IHero) => {
  const hours = new Date().getHours();
  const isOnline = useNetworkStatus();

  const eveningHoursDarkStyle = {
    'dark:text-white': IS_EVENING_HOURS(hours),
    'dark:text-primary': !IS_EVENING_HOURS(hours),
  };
  const greetingClass = classNames(
    'font-[700] font-head-bold',
    eveningHoursDarkStyle
  );
  const copyClass = classNames(
    'font-[250] font-head-light',
    eveningHoursDarkStyle
  );

  const headlineContainer = classNames(
    'mobile:max-w-[218px] max-w-[333px] break-words leading-[42px] text-42',
    {
      'text-primary': IS_DAY_TIME(hours),
      'text-white': !IS_DAY_TIME(hours),
    }
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);
  const handleNavigate = () => navigate('/favourites');

  const heroContainer = classNames(
    'flex mobile:px-0 mobile:items-center p-32 mobile:max-w-[342px] max-w-[716px] mobile:min-h-[480px] min-h-[551px] flex-col justify-between rounded-8 bg-cover',
    {
      'bg-morning': IS_MORNING_HOURS(hours) && !nextFavouriteFlight,
      'bg-center bg-day': IS_NOON_HOURS(hours) && !nextFavouriteFlight,
      'bg-evening': IS_EVENING_HOURS(hours) && !nextFavouriteFlight,
    },
    {
      'mobile:max-w-[342px] laptop:max-w-[350px] desktop:max-w-[350px] bg-cover bg-[20%]':
        nextFavouriteFlight,
      'bg-morning': IS_MORNING_HOURS(hours) && nextFavouriteFlight,
      'bg-center bg-[50%] bg-day': IS_NOON_HOURS(hours) && nextFavouriteFlight,
      'bg-evening bg-[25%]': IS_EVENING_HOURS(hours) && nextFavouriteFlight,
    }
  );

  const headerWeatherContainer = classNames({
    'flex justify-between': weatherData,
    'mobile:self-start mobile:ml-24': !weatherData,
  });

  const greeting = GREETING(hours) + (name ? ', ' + name : '') + '!';
  const container = classNames('flex mobile:flex-col tablet:flex-col gap-16', {
    'laptop:flex-col desktop:flex-col': !nextFavouriteFlight,
  });

  const hasFlightSearchPermission = useCheckPermissions(
    [Permission.SEARCH_FLIGHTS],
    CheckType.PARTIAL
  );

  return (
    <div className={container}>
      <div className={heroContainer} {...others}>
        <div className={headerWeatherContainer}>
          <div className={headlineContainer}>
            <h1 className={greetingClass}>{greeting}</h1>
            <h2 className={copyClass}>{HERO_COPY(hours)}</h2>
          </div>
          {weatherData && (
            <Badge
              text={`${weatherData.temperature} ${weatherData.description}`}
              textClassName="text-primary font-[400]"
              className="bg-white w-[92px]"
            />
          )}
        </div>
        <div
          className={classNames(
            'mobile:w-full mobile:flex mobile:flex-col mobile:items-center',
            {
              'laptop:w-full laptop:flex laptop:flex-col laptop:items-center':
                hasFavourites,
            }
          )}>
          {hasFavourites && (
            <Button
              onClick={handleNavigate}
              Icon={<Icon width={15} height={17} variant="upsidePlane" />}
              className={classNames(myFlightsButton, {
                'cursor-none pointer-events-none': !isOnline,
              })}
              textClassName={myFlightsText}
              text={MY_FLIGHTS}
            />
          )}
          {hasFlightSearchPermission && (
            <SearchInput
              width="294px"
              className="h-[49px]"
              onSubmit={(searchValue) => {
                if (searchValue && searchValue.length >= 3) {
                  dispatch(setQuery(searchValue));
                  navigate(`/search?query=${searchValue}`, {
                    state: { shouldKeepQuery: true },
                  });
                } else {
                  setError(ERRORS.MIN_3_CHAR);
                }
              }}
              error={error}
              disabled={!isOnline}
            />
          )}
        </div>
        {weatherData && (
          <Badge
            text={`${weatherData.temperature} ${weatherData.description}`}
            textClassName="text-primary font-[400]"
            className="bg-white w-[92px]"
          />
        )}
      </div>
      <div>
        {nextFavouriteFlight && (
          <FlightBlock isFromHomepage={true} flightData={nextFavouriteFlight} />
        )}
      </div>
    </div>
  );
};

export default Hero;
