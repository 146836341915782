import { useMutation } from '@apollo/client';
import { CHECK_IN_FLIGHT } from '../../graphql/checkInFlight/check-in-flight.mutations';
import {
  Mutation,
  CheckInFlightInput,
  CheckInFlightOutput,
  Permission,
} from '../generated/graphql';
import { IMutationResponse } from '../types';
import { GET_MY_FLIGHTS_CACHED } from '../../graphql/myFlights/my-flights.queries';
import { AppDispatch } from '../../redux/store';
import { subscribeToFlightCheckin } from '../notifications/subscribeToFlight';
import { GET_CHECKED_IN_ROLES } from '../../graphql/checkInFlight/checked-in-roles.queries';
import { addSuccess } from '../../redux/reducers/notificationReducer';
import { useGetCheckedInRoles } from './useGetCheckedInRoles';
import useCheckPermissions from './useCheckPermissions';
import {
  addNotification,
  updateFlightNotificationsHistory,
} from '../../redux/reducers/newsfeedReducer';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import { handleNewNotification } from '../notifications/notificationsUtil';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../redux/reducers/authReducer';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { subscribeToHighlander } from '../notifications/subscribeToHighlander';

interface CheckOutResponse extends IMutationResponse<CheckInFlightOutput> {
  onCheckIn: (
    data: CheckInFlightInput,
    dispatch: AppDispatch,
    isHighlander: boolean
  ) => Promise<CheckInFlightOutput | null>;
}

export const useCheckIn = (): CheckOutResponse => {
  const [checkIn, { data, loading, error }] = useMutation<
    Pick<Mutation, 'checkInFlight'>
  >(CHECK_IN_FLIGHT, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GET_MY_FLIGHTS_CACHED }],
  });

  const { refetchCheckInRoles } = useGetCheckedInRoles();

  const userId = useSelector(selectUserId);
  const useUTC = useSelector(selectUTC);
  const use24Format = useSelector(select24Format);

  const hasFlightNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_FLIGHT_NOTIFICATIONS,
  ]);

  const onCheckIn = async (
    data: CheckInFlightInput,
    dispatch: AppDispatch,
    isHighlander: boolean
  ) => {
    const onMessage = async (topic: string, message: string) => {
      handleNewNotification(
        message,
        userId,
        (userNotification) => dispatch(addNotification(userNotification)),
        useUTC,
        use24Format
      );
      try {
      } catch (error) {
        console.error('Could not parse notification');
        console.error(error);
      }
    };

    try {
      const result = await checkIn({
        variables: {
          flight: data,
        },
        refetchQueries: [GET_CHECKED_IN_ROLES, GET_MY_FLIGHTS_CACHED],
      });
      if (
        result?.data?.checkInFlight?.errors &&
        result?.data.checkInFlight?.errors[0]?.message
      ) {
        dispatch(
          addSuccess(
            result?.data && result?.data?.checkInFlight?.errors[0]?.message
          )
        );
        return null;
      }
      if (result?.data?.checkInFlight?.flight && hasFlightNotificationAccess) {
        dispatch(updateFlightNotificationsHistory());
        UtilFactory.newMessageHandler().subscribe({
          topic: `${MQTT_TOPIC.FLIGHT}${result.data.checkInFlight?.flight?.flightId}`,
          onMessage,
        });
      }

      subscribeToFlightCheckin(data.checkInInput.flightId, refetchCheckInRoles);
      if (isHighlander) {
        subscribeToHighlander(data.checkInInput.flightId, userId, dispatch);
      }
      return result.data?.checkInFlight ?? null;
    } catch (error) {
      throw error;
    }
  };

  return {
    data: data?.checkInFlight ?? null,
    loading,
    error,
    onCheckIn,
  };
};
