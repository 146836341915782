import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListHeader from '../Chat/ChatHeader/ChatHeader';
import {
  DEVICE_TYPE,
  MY_CHECKIN_FLIGHTS,
  NO_CHECKED_IN_FLIGHT,
  NO_TELEPHONY_STATION,
  TELEPHONE_BOOKS,
  TELEPHONY_OVERLAY,
} from '../../utils/constants';
import { selectUserId } from '../../redux/reducers/authReducer';
import { useGetMyFlightsCached } from '../../utils/hooks/useGetMyFlightsCached';
import { getFlightName } from '../../utils/helpers';
import FlightPreview from '../Chat/FlightPreview';
import TeamContact from '../TeamContact/TeamContact';
import {
  selectActiveChatScreen,
  setActiveFlightIdScreen,
  setStationScreen,
  selectStationScreen,
} from '../../redux/reducers/newsfeedReducer';
import classNames from 'classnames';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import { TelephonySearch } from './TelephonySearch';
import StationPreview from '../StationContact/StationPreview';
import StationContact from '../StationContact/StationContact';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';
import {
  CacheTelephonyEntry,
  Permission,
  TelephonySearchType,
} from '../../utils/generated/graphql';
import { selectTelephonyStation } from '../../redux/reducers/telephoneBookReducer';
import { useSearchTelephonyLazy } from '../../utils/hooks/useGetTelephonySearch';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import { selectStationsList } from '../../redux/reducers/settingsReducer';
import Title from '../Title/Title';

interface ITelephonyContainer {
  handleCloseTelephony: () => void;
}
const containerClass = 'grid grid-cols-2 mobile:grid-cols-1 divide-x-1 h-full';
const headerClassName =
  'text-grey-40 text-12 font-body-text dark:text-grey-12 ml-8 mobile:ml-0';

const overlayTitleClass =
  'text-grey-40 font-semibold text-14 font-body-text dark:text-grey-12 ml-8 pt-[7px] mobile:ml-0';

const TelephonyContainer = ({ handleCloseTelephony }: ITelephonyContainer) => {
  const isOffline = !useNetworkStatus();

  const dispatch = useDispatch();
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  const activeTelephonyScreen = useSelector(selectActiveChatScreen);
  const stationScreen = useSelector(selectStationScreen);
  const userId = useSelector(selectUserId);
  const { data } = useGetMyFlightsCached(userId);
  const checkedInFlights = data?.filter((flight) => flight.isCheckedIn);

  const hasGeneralSectionAccess = useCheckPermissions([
    Permission.READ_PHONE_BOOK_GENERAL,
  ]);

  const flights = checkedInFlights?.map((flight) => {
    return {
      flightName: getFlightName(flight.flightId),
      flightId: flight.flightId,
      status: flight.flightStatus,
    };
  });
  const firstFlight = flights?.[0]?.flightId;

  const stationsList = useSelector(selectTelephonyStation);
  const { loading: loadingSearch, getSearchValue } = useSearchTelephonyLazy();
  const userSettingsStations = useSelector(selectStationsList);
  const telephonyStationsList = hasGeneralSectionAccess
    ? ['General', ...userSettingsStations]
    : [...userSettingsStations];
  const firstStation = telephonyStationsList[0];
  const [selectedStation, setSelectedStation] = useState<string | null>(null);
  const [selectedFlightId, setSelectedFlightId] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [currentStationInfo, setCurrentStationInfo] =
    useState<CacheTelephonyEntry | null>(stationsList[firstStation]);
  const upperSearchValue = searchValue.toUpperCase();

  const handleFlightSelect = (flightId: string | null) => {
    setShowEmptyMessage(false);
    setSelectedFlightId(flightId);
    setSelectedStation(null);
    setCurrentStationInfo(null);
    isMobile && dispatch(setActiveFlightIdScreen(flightId));
  };

  const handleStationSelect = (
    stationName: string | null,
    stationInfo?: CacheTelephonyEntry | null
  ) => {
    setShowEmptyMessage(false);
    setSelectedStation(stationName);
    stationName && setCurrentStationInfo(stationsList[stationName]);
    stationInfo && setCurrentStationInfo(stationInfo);
    setSelectedFlightId(null);
    isMobile && dispatch(setStationScreen(stationName));
  };

  const handleOnClick = async () => {
    const stations = Object.keys(stationsList)?.filter(
      (value) => value === upperSearchValue
    );

    if (stations.includes(upperSearchValue)) {
      handleStationSelect(
        stationsList?.[upperSearchValue]?.station ?? '',
        stationsList?.[upperSearchValue]
      );
      return;
    }
    if (!isOffline) {
      const responseSearch = await getSearchValue(upperSearchValue);
      const type = responseSearch?.type;
      if (
        type === TelephonySearchType.FLIGHT &&
        responseSearch?.checkinRoles?.[0]
      ) {
        handleFlightSelect(responseSearch?.checkinRoles?.[0]?.flightId ?? '');
      } else if (
        type === TelephonySearchType.STATION &&
        responseSearch?.stations?.[0]
      ) {
        handleStationSelect(
          responseSearch?.stations?.[0]?.station ?? '',
          responseSearch?.stations?.[0]
        );
      } else {
        setEmptyMessage();
      }
    }
  };

  const setEmptyMessage = async () => {
    isMobile && dispatch(setActiveFlightIdScreen(' '));
    setSelectedFlightId(' ');
    setSelectedStation(' ');
    setCurrentStationInfo(null);
    setShowEmptyMessage(true);
  };

  const hasSearchAccess = useCheckPermissions([Permission.TELEPHONY_SEARCH]);

  const hasStationAccess = useCheckPermissions([Permission.TELEPHONY_STATION]);
  return (
    <div className="bg-white dark:bg-grey-90 h-dvh flex flex-col">
      <div className={containerClass}>
        <div
          className={classNames('relative flex flex-col h-dvh', {
            'mobile:hidden': activeTelephonyScreen || stationScreen,
          })}>
          <ListHeader handleClose={isMobile ? handleCloseTelephony : undefined}>
            <Title
              title={TELEPHONY_OVERLAY}
              titleColorClass={overlayTitleClass}
            />
          </ListHeader>
          <div className="flex-1 overflow-y-auto">
            <ListHeader>
              <div className={headerClassName}>{MY_CHECKIN_FLIGHTS}</div>
            </ListHeader>
            <div className="flex-1 overflow-y-auto">
              {flights?.length ? (
                flights?.map((flight) => (
                  <FlightPreview
                    key={flight.flightName}
                    title={flight.flightName}
                    flightId={flight.flightId}
                    status={flight.status}
                    onFlightSelect={() => handleFlightSelect(flight.flightId)}
                    isOnTelephony
                    selected={
                      !selectedStation &&
                      flight.flightId === (selectedFlightId ?? firstFlight)
                    }
                  />
                ))
              ) : (
                <Title
                  title={NO_CHECKED_IN_FLIGHT}
                  titleColorClass="flex justify-center text-primary text-14 font-body-text dark:text-grey-12"
                  className="h-[66px] p-24 bg-white dark:bg-grey-90 border-b-1 border-b-grey-12 dark:border-b-grey-60"></Title>
              )}
            </div>

            {hasStationAccess && (
              <>
                <ListHeader>
                  <div className={headerClassName}>{TELEPHONE_BOOKS}</div>
                </ListHeader>
                <div className="flex-1 overflow-y-auto">
                  {telephonyStationsList.length ? (
                    telephonyStationsList.map((station) => (
                      <StationPreview
                        key={station ?? ''}
                        stationName={station ?? ''}
                        onStationSelect={() =>
                          handleStationSelect(station ?? '')
                        }
                        selected={
                          !selectedFlightId &&
                          station ===
                            (selectedStation ??
                              (!checkedInFlights?.length ? firstStation : null))
                        }
                      />
                    ))
                  ) : (
                    <Title
                      title={NO_TELEPHONY_STATION}
                      titleColorClass="flex justify-center text-primary text-14 font-body-text dark:text-grey-12"
                      className="h-[66px] p-24 bg-white dark:bg-grey-90 border-b-1 border-b-grey-12 dark:border-b-grey-60"></Title>
                  )}
                </div>
              </>
            )}
          </div>
          {hasSearchAccess && (
            <TelephonySearch
              searchValue={searchValue}
              onInputChange={setSearchValue}
              onSearchButtonClick={handleOnClick}
              loading={loadingSearch}
            />
          )}
        </div>
        {selectedFlightId || (checkedInFlights?.length && !selectedStation) ? (
          <div
            className={classNames({
              'flex mobile:hidden': !activeTelephonyScreen,
            })}>
            <TeamContact
              flightId={selectedFlightId ?? firstFlight}
              title={
                selectedFlightId || firstFlight || showEmptyMessage
                  ? getFlightName(selectedFlightId ?? firstFlight)
                  : ''
              }
              handleClose={handleCloseTelephony}
              handleBack={() => handleFlightSelect(null)}
              isOnTelephony
              showEmptyMessage={showEmptyMessage}
            />
          </div>
        ) : stationsList && !selectedFlightId && hasStationAccess ? (
          <div
            className={classNames('h-screen mobile:h-dvh overflow-y-hidden', {
              'flex mobile:hidden': !stationScreen,
            })}>
            <StationContact
              key={currentStationInfo?.station ?? firstStation}
              stationInfo={currentStationInfo ?? stationsList[firstStation]}
              handleClose={handleCloseTelephony}
              handleBack={() => handleStationSelect(null)}
              showEmptyMessage={showEmptyMessage}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TelephonyContainer;
