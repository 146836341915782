import React from 'react';
import AdminNotificationCenter from '../../components/AdminNotificationCenter/AdminNotificationCenter';
import Title from '../../components/Title/Title';
import { BROADCAST } from '../../utils/constants';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';

const headerConfig: { headerTypeMobile: 'simple' | 'flight'; title: string } = {
  headerTypeMobile: 'simple',
  title: BROADCAST,
};

const Admin = () => {
  useCustomHeader(headerConfig);
  return (
    <div className="p-24 mobile:px-0 mobile:pt-[60px]">
      <div className="flex items-center mobile:hidden">
        <Title
          title={BROADCAST}
          titleColorClass={'py-16 text-grey-60 text-18'}
          className="mobile:hidden"
        />
      </div>
      <AdminNotificationCenter />
    </div>
  );
};

export default Admin;
