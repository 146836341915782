import React from 'react';
import classNames from 'classnames';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';

interface IChatCountProps {
  isOnlyOneRole?: boolean;
  toggle: () => void;
  isPhoneAvailable?: boolean;
  rolesCheckedIn?: number;
}

interface IBadgeProps {
  text?: string | number;
  toggle: () => void;
  isPhoneAvailable?: boolean;
}

const ChatBadge = ({ text, toggle, isPhoneAvailable }: IBadgeProps) => {
  return (
    <Button
      text={text}
      className={classNames(
        'rounded-100 h-16 flex flex-row items-center gap-[6px] px-8 py-[10px] justify-evenly mobile:my-10',
        {
          'bg-primary': isPhoneAvailable,
          'bg-grey-60 dark:bg-grey-60': !isPhoneAvailable,
        }
      )}
      textClassName="text-white dark:text-white"
      Icon={
        <Icon width={12} height={12} variant="profile" className="fill-white" />
      }
      onClick={(event) => {
        event.stopPropagation();
        toggle();
      }}
    />
  );
};

const ChatCount = ({
  isOnlyOneRole,
  toggle,
  isPhoneAvailable,
  rolesCheckedIn,
}: IChatCountProps) => {
  return (
    <>
      {isOnlyOneRole ? (
        <Button
          className="mobile:mx-14 mobile:pt-1 mobile:my-10"
          Icon={
            <Icon
              variant="call"
              className={classNames('dark:fill-white', {
                'fill-grey-40 dark:fill-grey-40': !isPhoneAvailable,
                'fill-primary': isPhoneAvailable,
              })}
            />
          }
          onClick={(event) => {
            event.stopPropagation();
            toggle();
          }}
        />
      ) : (
        <ChatBadge
          text={rolesCheckedIn}
          isPhoneAvailable={isPhoneAvailable}
          toggle={() => toggle()}
        />
      )}
    </>
  );
};

export default ChatCount;
