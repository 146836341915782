import React, { ForwardedRef, MouseEvent, forwardRef } from 'react';

interface IButton {
  Icon?: React.ReactNode;
  className?: string;
  text?: string | React.ReactNode;
  textClassName?: string;
  role?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  form?: string;
  type?: 'button' | 'reset' | 'submit';
}

const Button = forwardRef(
  (
    {
      Icon = null,
      className,
      text,
      textClassName,
      role,
      onClick,
      disabled = false,
      form = '',
      type = 'button',
      ...others
    }: IButton,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        form={form}
        type={type}
        className={className}
        onClick={onClick}
        disabled={disabled}
        {...others}>
        {Icon}
        <span className={textClassName}>{text}</span>
      </button>
    );
  }
);

Button.displayName = 'Button';
export default Button;
