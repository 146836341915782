import { useQuery } from '@apollo/client';
import { IataDelayCode, Query } from '../generated/graphql';
import { IDataResponse } from '../types';
import { ETD_DELAYS } from '../../graphql/etdSetting/etdSetting.queries';

const CACHE_TTL_MINUTES = 15 * 60 * 1000;

export const useGetDelayCodes = (
  carrier: string
): IDataResponse<IataDelayCode[]> => {
  const { data, loading, error, client } = useQuery<
    Pick<Query, 'etdDelayCodes'>
  >(ETD_DELAYS, { variables: { carrier }, fetchPolicy: 'cache-first' });

  setTimeout(() => {
    client.cache.evict({ fieldName: 'etdDelayCodes', args: { carrier } });
    client.cache.gc();
  }, CACHE_TTL_MINUTES);

  return {
    data: data?.etdDelayCodes ?? null,
    loading,
    error,
  };
};
