export const catering = {
  totalInboundLoaded: 0,
  totalLoaded: 0,
  first: 0,
  business: 0,
  premium: 0,
  economy: 0,
  vegetarian: 0,
  vegan: 0,
  diabetic: 0,
  standard: 0,
};
