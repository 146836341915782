import { useMutation } from '@apollo/client';
import { DELETE_NOTIFICATION } from '../../graphql/notifications/notifications.mutations';
import { DeleteNotificationOutput, Mutation } from '../generated/graphql';
import { IMutationResponse, MutationOptions } from '../types';

interface DeleteNotificationResponse
  extends IMutationResponse<DeleteNotificationOutput> {
  onDeleteNotification: (
    notificationId: string
  ) => Promise<DeleteNotificationOutput | null>;
}

export const useDeleteNotification = (
  options?: MutationOptions
): DeleteNotificationResponse => {
  const [deleteNotification, { data, error, loading }] = useMutation<
    Pick<Mutation, 'deleteNotification'>
  >(DELETE_NOTIFICATION, options);
  const onDeleteNotification = async (notificationId: string) => {
    try {
      const result = await deleteNotification({
        variables: { notificationId },
      });

      return result.data?.deleteNotification ?? null;
    } catch (err) {
      return null;
    }
  };
  return {
    onDeleteNotification,
    data: data?.deleteNotification ?? null,
    loading,
    error,
  };
};
