import moment from 'moment';

export const barWidthNoDelay = (
  { start, end },
  currentTime,
  minutePixels,
  processVariable
) => ({
    marginLeft:
      ((moment(currentTime, 'HH:mm').subtract(2.5, 'hours').format('X') -
        moment(start, 'DD-MM-YYYY HH:mm').format('X')) /
        60) *
        -minutePixels +
      processVariable +
      'px',
    width:
      ((moment(end, 'DD-MM-YYYY HH:mm').format('X') -
        moment(start, 'DD-MM-YYYY HH:mm').format('X')) /
        60) *
        //-1 to remove the 1px border
        minutePixels -
      1 +
      'px',
  })
