import React, { useState } from 'react';
import Button from '../Button/Button';
import { ETD_SETTINGS } from '../../utils/constants';
import { IReason } from './ETDSetting';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { MAX_ETD_NO_REASONS } from '../../utils/constants';
import { IataDelayCode } from '../../utils/generated/graphql';

const inputClassName =
  'mt-8 p-4 pt-12 pl-14 mx-8 pr-32 w-auto leading-[14px] bg-white rounded-4 text-14 focus:outline-none font-body-text border-1 border-grey-25 focus:border-1 focus:border-primary dark:bg-grey-90 dark:border-grey-40 dark:text-grey-12 placeholder:normal-case resize-none';

interface IEtdReasons {
  handleCloseEtdSettings: () => void;
  etdUpdateReasons: IReason[];
  setSelectedUpdateReasons: React.Dispatch<React.SetStateAction<IReason[]>>;
  delayReasons: IataDelayCode[] | null;
}

interface IReasonItem {
  etdReason: IReason;
  isBelowLimit: boolean;
  toggleSelect: (id: string) => void;
}

const ReasonItem = ({ etdReason, isBelowLimit, toggleSelect }: IReasonItem) => {
  const isItemDisabled = (etdReason: IReason) =>
    !etdReason.isSelected && !isBelowLimit;

  return (
    <li
      key={etdReason.id}
      className={classNames(
        'list-none text-18 text-primary font-body-text border-b-1 border-b-grey-12 py-4 last:border-b-0 flex justify-between',
        {
          'opacity-50': isItemDisabled(etdReason),
          'opacity-100': isBelowLimit,
        }
      )}>
      <input
        id={etdReason.id ?? ''}
        type="checkbox"
        className="hidden"
        disabled={isItemDisabled(etdReason)}
        onChange={() => toggleSelect(etdReason.id ?? '')}
      />
      <div className="flex flex-row w-11/12">
        <label
          htmlFor={etdReason.id ?? ''}
          className="w-56 top-0 bottom-0 my-auto">
          {etdReason.id}
        </label>
        <label htmlFor={etdReason.id ?? ''} className="w-full">
          {etdReason.reason}
        </label>
      </div>
      {etdReason.isSelected && (
        <Icon
          variant="checkmark"
          className="dark:fill-white top-0 bottom-0 my-auto"
        />
      )}
    </li>
  );
};

const generateReasonsArray = (
  data: IataDelayCode[],
  etdUpdateReasons: IReason[]
): IReason[] => {
  const mappedReasons = data.map((elem: IataDelayCode) => ({
    id: elem.subcode !== '' ? elem.subcode : elem.code,
    reason: elem.description,
    isSelected: false,
    value: 1,
    code: elem.code ?? '',
    subcode: elem.subcode ?? '',
  }));

  return mappedReasons
    .filter((reason) => {
      const existingIds = etdUpdateReasons.map(
        (existingReason) => existingReason.id
      );
      return !existingIds.includes(reason.id);
    })
    .sort((a, b) => {
      return a.id && b.id ? a.id.localeCompare(b.id) : 0;
    });
};

const EtdReasons = ({
  handleCloseEtdSettings,
  etdUpdateReasons,
  setSelectedUpdateReasons,
  delayReasons,
}: IEtdReasons) => {
  const [searchValue, setSearchValue] = useState('');

  const genericReasonId = '99';

  const reasons = generateReasonsArray(delayReasons ?? [], etdUpdateReasons);

  reasons.at(-1)?.id === genericReasonId &&
    reasons.unshift(...reasons.splice(-1));

  const [etdReasonArr, setEtdReasonArr] = useState([
    ...etdUpdateReasons,
    ...reasons,
  ]);

  const [reasonsNo, setReasonsNo] = useState(
    etdReasonArr.filter((reason) => reason.isSelected).length
  );

  const isBelowLimit = reasonsNo < MAX_ETD_NO_REASONS;

  const buttonClassNames = classNames(
    'rounded-4 bg-green w-auto mx-[30px] py-12 mb-32',
    {
      'bg-grey-12': reasonsNo === 0,
    }
  );

  const onConfirmButtonClick = () => {
    const selectedReasons = etdReasonArr
      .filter((reason) => reason.isSelected)
      .sort((a, b) => (a.id && b.id ? a.id.localeCompare(b.id) : 0));
    selectedReasons.at(-1)?.id === genericReasonId &&
      selectedReasons.unshift(...selectedReasons.splice(-1));
    setSelectedUpdateReasons(selectedReasons);
    handleCloseEtdSettings();
  };

  const toggleSelect = (id: string) => {
    setEtdReasonArr(
      etdReasonArr.map((reason) => {
        if (reason.id === id) {
          if (reasonsNo >= MAX_ETD_NO_REASONS && !reason.isSelected) {
            return reason;
          }
          reason.isSelected
            ? setReasonsNo(reasonsNo - 1)
            : setReasonsNo(reasonsNo + 1);
          return { ...reason, isSelected: !reason.isSelected };
        }
        return reason;
      })
    );
  };

  const unselectedReasons = etdReasonArr
    .filter((reason) => !reason.isSelected)
    .filter((reason) =>
      searchValue === ''
        ? true
        : reason.id
            ?.toLocaleUpperCase()
            .includes(searchValue.toLocaleUpperCase()) ||
          reason.reason
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
    );

  const selectedReasons = etdReasonArr
    .filter((reason) => reason.isSelected)
    .filter((reason) =>
      searchValue === ''
        ? true
        : reason.id
            ?.toLocaleUpperCase()
            .includes(searchValue.toLocaleUpperCase()) ||
          reason.reason
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
    );

  return (
    <>
      <div className="p-16 flex justify-between content-center items-center border-b-grey-12 border-b-1 pb-16">
        <div>
          <div className="text-12 text-primary dark:text-white font-body-text">
            {ETD_SETTINGS.TITLE}
          </div>
          <div className="text-12 text-primary dark:text-white font-body-text">
            Select reason ({reasonsNo}/{MAX_ETD_NO_REASONS})
          </div>
        </div>
        <Button
          text={ETD_SETTINGS.CANCEL}
          className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
          textClassName="text-14 !text-primary font-body-text leading-[18px]"
          onClick={handleCloseEtdSettings}
        />
      </div>
      <textarea
        className={inputClassName}
        value={searchValue}
        placeholder={'Search for a code...'}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div className="overflow-y-auto flex-1 px-16">
        {selectedReasons.map((etdReason) => (
          <ReasonItem
            key={etdReason.id}
            etdReason={etdReason}
            isBelowLimit={isBelowLimit}
            toggleSelect={toggleSelect}
          />
        ))}
        {unselectedReasons.map((etdReason) => (
          <ReasonItem
            key={etdReason.id}
            etdReason={etdReason}
            isBelowLimit={isBelowLimit}
            toggleSelect={toggleSelect}
          />
        ))}
      </div>
      <Button
        text={ETD_SETTINGS.CONFIRM}
        className={buttonClassNames}
        textClassName={classNames(
          'font-body-text font-bold text-white text-14',
          {
            'dark:text-grey-40': !reasonsNo,
          }
        )}
        disabled={reasonsNo === 0}
        onClick={onConfirmButtonClick}
      />
    </>
  );
};

export default EtdReasons;
