import React from 'react';
import classNames from 'classnames';
import IconComp from '../Icon/Icon';
import Button from '../Button/Button';

interface IBadgeProps {
  Icon?: React.ReactNode;
  className?: string;
  text?: string | number;
  textClassName?: string;
  isClosable?: boolean;
  onClose?: () => void;
  width?: string;
}

const Badge = ({
  Icon = null,
  className,
  text,
  textClassName,
  isClosable,
  onClose,
  width,
  ...others
}: IBadgeProps) => {
  return (
    <div
      className={classNames(
        'rounded-100  h-24 flex flex-row items-center',
        className,
        {
          'gap-[6px] px-8 py-[5px] justify-evenly': text,
          [`${width}`]: width,
          'w-fit': !width,
        }
      )}
      {...others}>
      {Icon}
      <span
        className={classNames(
          'text-12',
          { 'text-white': !textClassName },
          textClassName
        )}>
        {text}
      </span>
      {isClosable && (
        <Button
          Icon={
            <IconComp
              width={11}
              height={11}
              variant="close"
              className="fill-white"
            />
          }
          onClick={onClose}
          data-testid="closeBtn"
        />
      )}
    </div>
  );
};

export default Badge;
