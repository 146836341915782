import React from 'react';
import classNames from 'classnames';
import { ChatColor } from './ChatMessages';

interface IRoleAvatar {
  userName: string;
  color: ChatColor;
  className?: string;
}

const RoleAvatar = ({ userName, color, className }: IRoleAvatar) => {
  return (
    <div
      className={classNames(
        className,
        'flex justify-center items-center rounded-full w-[35px] h-[35px] text-14 font-head-bold',
        {
          'bg-primary text-white dark:bg-grey-100': color === 'primary',
          'bg-grey-12 text-primary dark:text-grey-100': color === 'grey',
        }
      )}>
      {userName}
    </div>
  );
};

export default RoleAvatar;
