const getDepartureStationFromId = (flightId) => {
  if (!flightId) {
    return '';
  }

  const parts = flightId.split('-');
  if (parts.length < 3) {
    return '';
  }

  return parts[2];
};

export { getDepartureStationFromId };
