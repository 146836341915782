import { gql } from '@apollo/client';
import { TIME_OBJECT } from '../fragments';

export const GET_MY_FLIGHTS = gql`
  query GetMyFlights($flightId: String, $stationsList: [String!]) {
    myFlights(flightId: $flightId, stationsList: $stationsList) {
      data {
        flightId
        countdown {
          delay
          time
          time12format
          timeLeft
          timeString
          timeLogicUsed
        }
        isCheckedIn
        isFavourite
        pos
        flightStatus
        flightName
        destination
        type
        gate
        processes
        registration
        departureTimes {
          ...TimeObject
        }
        errorMessage
        arrivalTimes {
          ...TimeObject
        }
        notificationMuted
        origin
        role {
          roleGroup
          name
          technicalRole
          carrier
          permissions
          _id
        }
      }
    }
  }
  ${TIME_OBJECT}
`;

export const GET_MY_FLIGHTS_CACHED = gql`
  query CachedMyFlights {
    cachedMyFlights {
      departureInfo {
        airportLocation {
          airportCode
        }
        bestDepartureTime
      }
      flightId
      timezonePackets {
        airportcode
        utc
      }
      isCheckedIn
      isFavourite
      flightStatus
      notificationMuted
    }
  }
`;

export const GET_CHECKED_IN_FLIGHTS_CACHED = gql`
  query CachedCheckedInFlights {
    cachedCheckedInFlights {
      departureInfo {
        airportLocation {
          airportCode
        }
        bestDepartureTime
      }
      flightId
      timezonePackets {
        airportcode
        utc
      }
      flightStatus
    }
  }
`;
