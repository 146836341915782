import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import {
  BAGGAGE_STATUS_COLOR,
  BAGGAGE_MAP,
  BAGGAGE_LIST,
  NO_BAGGAGE_FOUND,
  SORT_ORDER,
  FLIGHT_BLOCK_COPY,
} from '../../utils/constants';
import './BagageStatusTable.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectStation } from '../../redux/reducers/settingsReducer';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import Button from '../Button/Button';
import { useGetBaggageList } from '../../utils/hooks/useGetBaggageList';
import { DataError } from '../../utils/generated/graphql';

interface HeaderItemProps {
  name: string;
  activeHeaderButton: {
    criteria: string;
    sortOrder: string;
  };
  handleOnClick: (name: string) => void;
}

interface IBagageStatusTableProps {
  flightId: string;
  className: string;
  isDataRefetching: boolean;
  setErrors: (errors: DataError[] | null) => void;
  setErrorsEncountered: (errors?: Error) => void;
}

const BAGGAGE_COLUMN_DATA = [
  {
    label: 'Status',
    dataKey: 'status',
    minWidth: 130,
  },
  {
    label: 'Tag Number',
    dataKey: 'tagNumber',
    minWidth: 150,
  },
  {
    label: 'ULD',
    dataKey: 'ULD',
    minWidth: 130,
  },
  {
    label: 'Pos',
    dataKey: 'pos',
    minWidth: 65,
  },
  {
    label: 'Sec. Nr.',
    dataKey: 'securityNumber',
    minWidth: 85,
  },
];

const headerItemClassNames = 'flex font-body-text text-12 gap-4';
const trianglesClassNames =
  'flex flex-col gap-[2px] justify-center self-center';
const statusClassNames = 'flex items-center gap-8';
const tableItemClass =
  'font-head-light dark:text-grey-12 text-primary text-16 leading-[21px]';
const filterLabelClass =
  'ml-8 font-body-text text-grey-40 text-12 leading-[12px]';
const defaultSortCriteria = Object.keys(BAGGAGE_MAP).find(
  (key) => key === 'Status'
);

const positionCriteria = Object.keys(BAGGAGE_MAP).find((key) => key === 'POS');

const HeaderItem = ({
  name,
  activeHeaderButton,
  handleOnClick,
}: HeaderItemProps) => {
  const isSelected = name === activeHeaderButton.criteria;
  const isAscending =
    isSelected && activeHeaderButton.sortOrder === SORT_ORDER.ASC;
  return name !== FLIGHT_BLOCK_COPY.POS ? (
    <button
      className={classNames(headerItemClassNames, {
        'text-grey-25 font-[400]': !isSelected,
        'text-grey-80 font-[700]': isSelected,
      })}
      onClick={() => handleOnClick(name)}>
      <span>{name}</span>
      <span className={trianglesClassNames}>
        <Icon
          width={6}
          height={3}
          variant="triangleUp"
          className={classNames(`fill-${isAscending ? 'grey-80' : 'grey-25'}`, {
            hidden: name === positionCriteria,
          })}
        />
        <Icon
          width={6}
          height={3}
          variant="triangleDown"
          className={classNames(
            `fill-${isSelected && !isAscending ? 'grey-80' : 'grey-25'}`,
            {
              hidden: name === positionCriteria,
            }
          )}
        />
      </span>
    </button>
  ) : (
    <span
      className={classNames(headerItemClassNames, 'text-grey-25 font-[400]')}>
      {name}
    </span>
  );
};

const BagageStatusTable = ({
  className,
  flightId,
  isDataRefetching,
  setErrors,
  setErrorsEncountered,
  ...others
}: IBagageStatusTableProps) => {
  const tableClassNames = classNames(
    'bagage-status-grid flex flex-col dark:bg-grey-90 dark:rounded-8',
    className
  );
  const [filter, setFilter] = useState({
    'Not Loaded': true,
    Loaded: true,
    Offload: true,
  });

  const settingsStation = useSelector(selectStation);
  const station = flightId?.split('-')?.[2] ?? settingsStation;
  const [activeHeaderButton, setActiveHeaderButton] = useState({
    criteria: defaultSortCriteria || '',
    sortOrder: SORT_ORDER.ASC,
  });

  const {
    data,
    error,
    responseErrors,
    loading: isLoading,
    refetchBaggageList,
  } = useGetBaggageList({
    flightId,
    sortBy: BAGGAGE_MAP[activeHeaderButton.criteria],
    order: activeHeaderButton.sortOrder,
    localStation: station,
  });

  useEffect(() => {
    if (!isLoading) {
      setErrors(responseErrors);
      setErrorsEncountered(error);
    }
  },[isLoading]);

  useEffect(() => {
    if (isDataRefetching) {
      refetchBaggageList();
    }
  }, [isDataRefetching]);

  const bagageStatusArray = (data || []).filter(({ status }) => filter[status]);

  const handleOnClick = (name: string) => {
    if (name !== positionCriteria) {
      if (name === activeHeaderButton.criteria) {
        setActiveHeaderButton((prev) => ({
          ...activeHeaderButton,
          sortOrder:
            prev.sortOrder === SORT_ORDER.ASC
              ? SORT_ORDER.DESC
              : SORT_ORDER.ASC,
        }));
      } else {
        setActiveHeaderButton({
          criteria: name,
          sortOrder: SORT_ORDER.ASC,
        });
      }
    }
  };

  const renderHeader = ({ dataKey, label }) => {
    return (
      <HeaderItem
        key={dataKey}
        name={label}
        activeHeaderButton={activeHeaderButton}
        handleOnClick={handleOnClick}
      />
    );
  };

  const cellRender = ({ columnIndex, dataKey, rowData }) => {
    if (columnIndex === 0)
      return (
        <div className={statusClassNames}>
          <Icon
            width={8}
            height={8}
            variant="circle"
            className={`fill-${BAGGAGE_STATUS_COLOR[rowData.status]}`}
          />
          <span className={tableItemClass}>{rowData.status}</span>
        </div>
      );
    return <div className={tableItemClass}>{rowData[dataKey]}</div>;
  };

  return (
    <div className={tableClassNames} {...others}>
      <div className="flex px-[16px] gap-[40px] items-center mb-16 ml-10 mt-16">
        <div className="flex items-center">
          <Button
            Icon={
              <Icon
                variant={filter['Offload'] ? 'checked' : 'unchecked'}
                width={18}
                height={18}
                className="dark:fill-white"
              />
            }
            onClick={() => {
              setFilter((prev) => ({ ...prev, Offload: !prev['Offload'] }));
            }}
          />
          <span className={filterLabelClass}> Offloaded</span>
        </div>
        <div className="flex items-center">
          <Button
            Icon={
              <Icon
                variant={filter['Not Loaded'] ? 'checked' : 'unchecked'}
                width={18}
                height={18}
                className="dark:fill-white"
              />
            }
            onClick={() => {
              setFilter((prev) => ({
                ...prev,
                'Not Loaded': !prev['Not Loaded'],
              }));
            }}
          />
          <span className={filterLabelClass}> Not Loaded</span>
        </div>
        <div className="flex items-center">
          <Button
            Icon={
              <Icon
                variant={filter['Loaded'] ? 'checked' : 'unchecked'}
                width={18}
                height={18}
                className="dark:fill-white"
              />
            }
            onClick={() => {
              setFilter((prev) => ({ ...prev, Loaded: !prev['Loaded'] }));
            }}
          />
          <span className={filterLabelClass}> Loaded</span>
        </div>
      </div>
      <hr className="min-w-[576px] h-[1px] bg-grey-12 mb-32" />
      {!!bagageStatusArray?.length ? (
        <AutoSizer disableHeight>
          {({ width }) => {
            const allRowsHeight = 30 * bagageStatusArray.length;
            const calculatedHeight =
              allRowsHeight >= 494 ? 494 : allRowsHeight + 60;
            const calculatedWidth = width < 576 ? 576 : width - 0.6;
            return (
              <Table
                width={calculatedWidth}
                headerHeight={30}
                headerClassName="h-[30px] px-[16px] flex flex-1 items-start "
                rowHeight={30}
                height={calculatedHeight}
                rowCount={bagageStatusArray.length}
                rowGetter={({ index }) => bagageStatusArray[index]}>
                {BAGGAGE_COLUMN_DATA.map(({ label, dataKey, minWidth }) => (
                  <Column
                    label={label}
                    dataKey={dataKey}
                    minWidth={minWidth}
                    className="flex flex-1 px-[16px]"
                    headerRenderer={renderHeader}
                    cellRenderer={cellRender}
                  />
                ))}
              </Table>
            );
          }}
        </AutoSizer>
      ) : (
        <h1 className="self-center text-primary pb-32">
          {isLoading ? BAGGAGE_LIST : NO_BAGGAGE_FOUND}
        </h1>
      )}
    </div>
  );
};

export default BagageStatusTable;
