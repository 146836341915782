interface AnyObject {
  [key: string]: any;
}

export const shallowCompare = <T extends AnyObject>(
  obj1: T,
  obj2: T
): boolean => {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    )
  );
};

export const arraysEqual = <T extends AnyObject>(a1: T[], a2: T[]): boolean => {
  return (
    a1.length === a2.length && a1.every((o, idx) => shallowCompare(o, a2[idx]))
  );
};
