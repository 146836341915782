import React, { useState, FormEvent } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

export enum SubmitIcon {
  Search = 'search',
  Plane = 'plane',
}

interface ISearchInputProps {
  placeholderValue?: string;
  width?: string;
  className: string;
  onSubmit: (value: string | null) => void;
  setResetToDefault?: (value: boolean) => void;
  defaultValue?: string | null;
  error?: string | null;
  icon?: SubmitIcon;
  disabled?: boolean;
}

const SearchInput = ({
  placeholderValue = 'Search all flights',
  width = '',
  className,
  onSubmit,
  setResetToDefault,
  defaultValue = '',
  error,
  icon = SubmitIcon.Search,
  disabled,
  ...others
}: ISearchInputProps) => {
  const [searchValue, setSearchValue] = useState(defaultValue);

  const containerClassNames = classNames('relative w-full rounded-4');
  const inputClassNames = classNames(
    'py-12 pl-14 pr-32 w-full leading-[14px] bg-white rounded-4 text-14 focus:outline-none font-body-text border-1 border-grey-25 focus:border-1 focus:border-primary dark:bg-grey-90 dark:border-grey-40 dark:text-grey-12 uppercase placeholder:normal-case',
    {
      'border-red': error,
      [className]: className,
    }
  );
  const buttonClassNames = 'p-16 rounded-4 z-10';
  const errorClassNames = classNames(
    'text-red font-body-text text-12 h-12 mt-8',
    {
      invisible: !error,
    }
  );

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(searchValue);
    setResetToDefault?.(true);
  };

  return (
    <>
      <div
        className={classNames(containerClassNames, {
          'cursor-none pointer-events-none': disabled,
        })}
        style={{ maxWidth: width }}
        {...others}>
        <form onSubmit={handleOnSubmit}>
          <input
            type="text"
            value={searchValue === null ? '' : searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={inputClassNames}
            placeholder={placeholderValue}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <button
              type="submit"
              data-testid="search-icon-button"
              className={buttonClassNames}
              disabled={disabled}>
              <Icon variant={icon} className="fill-grey-40 dark:fill-grey-12" />
            </button>
          </div>
        </form>
      </div>
      <div className={errorClassNames}>{error}</div>
    </>
  );
};

export default SearchInput;
