import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

interface IChatItem {
  stationName: string;
  onStationSelect: (station: string) => void;
  selected?: boolean;
}

const StationPreview = ({
  stationName,
  onStationSelect,
  selected = false,
}: IChatItem) => {
  const containerClassNames = classNames(
    'w-full py-[19px] mobile:py-19 pl-[26px] pr-32 dark:bg-grey-90 border-l-6 border-b-1 border-b-grey-12 flex flex-col border-white mobile:border-l-0  dark:border-l-grey-90',
    {
      'bg-grey-6 mobile:bg-white': selected,
    }
  );

  return (
    <button
      onClick={() => onStationSelect(stationName)}
      className={containerClassNames}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center justify-between mobile:w-full tablet:gap-[50px] laptop:gap-[50px] desktop:gap-[50px]">
          <div className="whitespace-normal font-head-bold text-18 text-primary dark:text-white flex items-center w-[170px] gap-10">
            {stationName}
          </div>
        </div>

        <Button
          Icon={
            <Icon
              variant="arrowNext"
              className="fill-primary dark:fill-white mobile:hidden"
            />
          }
        />
      </div>
    </button>
  );
};

export default StationPreview;
