import { useQuery } from '@apollo/client';
import { Query, UpdateBoardingReasons } from '../generated/graphql';
import { IDataResponse } from '../types';
import { BOARDING_REASONS } from '../../graphql/flightDetails/flight-details.queries';

export const useGetBoardingReasons = (
  carrier: string
): IDataResponse<UpdateBoardingReasons> => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'updateBoardingReasons'>
  >(BOARDING_REASONS, { variables: { carrier } });

  return {
    data: data?.updateBoardingReasons ?? null,
    loading,
    error,
  };
};
