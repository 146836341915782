import React, { ReactNode } from 'react';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';

interface IHeader {
  children: ReactNode;
  handleClose?: () => void;
}

const ListHeader = ({ children, handleClose }: IHeader) => {
  return (
    <div className="h-[66px] p-24 bg-white dark:bg-grey-90 flex justify-between border-b-1 border-b-grey-12 dark:border-b-grey-60 flex-shrink-0">
      {children}
      <div className="flex items-center">
        {handleClose && (
          <Button
            onClick={handleClose}
            Icon={
              <Icon
                width={18}
                height={18}
                variant="close"
                className="fill-grey-25"
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default ListHeader;
