import { gql } from '@apollo/client';

export const GET_CHECKED_IN_ROLES = gql`
  query FlightCheckedInRoles($flightId: String!, $flightName: String) {
    flightCheckedInRoles(flightId: $flightId, flightName: $flightName) {
      flightId
      roles {
        name
        roleGroup
        shortName
        checkedInUsers {
          userId
          phone
          technicalRole
          userType
        }
        canChat
        canCall
        isHighlander
        isChatAvailable
      }
      myRole {
        roleGroup
        name
        _id
        shortName
        isHighlander
      }
    }
  }
`;
