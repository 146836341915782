import React from 'react';
import classNames from 'classnames';
import Badge from '../Badge/Badge';
import Icon from '../Icon/Icon';
import { BADGE_CONFIG } from '../../utils/components_config';
import { FlightStatus } from '../../utils/generated/graphql';
import Button from '../Button/Button';

interface IChatItem {
  title: string;
  flightId: string;
  messagesCount?: number;
  status: FlightStatus;
  onFlightSelect: (flight: string) => void;
  isOnTelephony?: boolean;
  selected?: boolean;
}

const FlightPreview = ({
  title,
  flightId,
  messagesCount,
  status,
  onFlightSelect,
  isOnTelephony = false,
  selected = false,
}: IChatItem) => {
  const containerClassNames = classNames(
    'w-full mobile:p-24px py-40 pl-[26px] pr-32 dark:bg-grey-90 border-l-6 border-b-1 border-b-grey-12 flex flex-col dark:border-b-grey-60 mobile:border-l-0',
    {
      'border-secondary': !!messagesCount,
      'border-white dark:border-l-grey-90': !messagesCount,
      'py-[19px]': isOnTelephony,
      'bg-grey-6 mobile:bg-white': selected,
    }
  );

  return (
    <button
      onClick={() => onFlightSelect(flightId)}
      className={containerClassNames}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center justify-between mobile:w-full tablet:gap-[50px] laptop:gap-[50px] desktop:gap-[50px]">
          <div
            className={classNames(
              'whitespace-normal font-head-bold text-15 text-primary dark:text-white flex items-center w-[170px] gap-10',
              { 'text-18': isOnTelephony }
            )}>
            {title}
          </div>
          <div className={classNames('flex', { 'gap-16': !isOnTelephony })}>
            <div className="mobile:w-32 w-[83px]">
              {status && status !== FlightStatus.DEFAULT && (
                <>
                  <div className="tablet:hidden tabletL:hidden laptopL:hidden">
                    <Badge
                      {...(status === FlightStatus.CANCELLED
                        ? BADGE_CONFIG.CANCELLED_NO_TEXT
                        : BADGE_CONFIG.DEPARTED_NO_TEXT)}
                    />
                  </div>
                  <div className="mobile:hidden">
                    <Badge
                      {...(status === FlightStatus.CANCELLED
                        ? BADGE_CONFIG.CANCELLED
                        : BADGE_CONFIG.DEPARTED)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="w-[29px]">
              {!!messagesCount && (
                <Icon
                  variant="simpleMessage"
                  className="fill-secondary"
                  width={28}
                  height={28}
                  text={messagesCount}
                  textClassName="text-primary text-12 dark:text-grey-90"
                />
              )}
            </div>
          </div>
        </div>

        <Button
          Icon={
            <Icon
              variant="arrowNext"
              className="fill-primary dark:fill-white mobile:hidden"
            />
          }
        />
      </div>
    </button>
  );
};

export default FlightPreview;
