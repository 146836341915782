import React from 'react';
import { Notification } from '../../../utils/generated/graphql';
import Grid from '../../Grid/Grid';
import { IModalData } from '../AdminNotificationCenter';
import NotificationHistoryItem from '../NotificationHistoryItem/NotificationHistoryItem';

interface INotificationHistoryList {
  notifications: Notification[];
  setModalData: React.Dispatch<React.SetStateAction<IModalData | null>>;
}

const LIST_HEADER = {
  SUBJECT: 'Subject',
  USER_ID: 'User ID',
  TYPE: 'Type',
  CATEGORY: 'Category',
  STATION: 'Station',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
};

const NotificationHistoryList = ({
  notifications,
  setModalData,
}: INotificationHistoryList) => {
  return (
    <div className="overflow-y-auto">
      <Grid col={[1, 'px', 0]}>
        <div className="flex justify-between mobile:w-fit mobile:hidden">
          <div className="flex w-fit gap-x-[10px] ">
            <h1 className="w-[200px] text-grey-40 font-body-text text-12 leading-[12px]">
              {LIST_HEADER.SUBJECT}
            </h1>
            <h1 className="w-[65px] text-grey-40 font-body-text text-12 leading-[12px]">
              {LIST_HEADER.USER_ID}
            </h1>
            <h1 className="w-[65px] text-grey-40 font-body-text text-12 leading-[12px]">
              {LIST_HEADER.TYPE}
            </h1>
            <h1 className="w-[65px] text-grey-40 font-body-text text-12 leading-[12px]">
              {LIST_HEADER.STATION}
            </h1>
          </div>
          <div className="flex mr-[102px] gap-x-[10px]">
            <h1 className="w-[110px] text-grey-40 font-body-text text-12 leading-[12px]">
              {LIST_HEADER.START_DATE}
            </h1>
            <h1 className="w-[110px] text-grey-40 font-body-text text-12 leading-[12px]">
              {LIST_HEADER.END_DATE}
            </h1>
          </div>
        </div>
        {notifications.map((item) => (
          <NotificationHistoryItem
            key={item._id}
            notification={item}
            setModalData={setModalData}
          />
        ))}
      </Grid>
    </div>
  );
};

export default NotificationHistoryList;
