import React from 'react';
import {
  BAGGAGE_STATUS,
  EMPTY_STATE,
  LIGHT_FONT_AIRLINES_BG,
} from '../../utils/constants';
import Title from '../Title/Title';
import classNames from 'classnames';

const numbersClass =
  'text-white text-18 leading-[19px] h-[19px] pt-4 mb-[10px] font-body-thin';

interface IHeroBaggage {
  loaded: number;
  checkedIn: number;
  offload: number;
  className: string;
  themeColor: string;
}

const HeroBaggage = ({
  loaded,
  checkedIn,
  offload,
  className,
  themeColor,
  ...others
}: IHeroBaggage) => {
  const containerClass = classNames(
    'flex flex-col rounded-8 p-32 justify-between  dark:bg-grey-90',
    themeColor,
    className
  );

  const titleColorClass = classNames({
    'text-white': LIGHT_FONT_AIRLINES_BG.includes(themeColor),
    'text-grey-40': !LIGHT_FONT_AIRLINES_BG.includes(themeColor),
  });

  return (
    <div className={containerClass} {...others}>
      <Title
        title={BAGGAGE_STATUS.BAGGAGE_STATUS}
        titleColorClass="text-white"
      />
      <div className="flex flex-row flex-nowrap mobile:flex-wrap pt-90 mobile:pt-32">
        <Title
          titleColorClass={titleColorClass}
          title={BAGGAGE_STATUS.LOADED}
          className="pr-104">
          <p className="text-white font-body-thin text-80 h-[80px] leading-[80px]">
            {loaded ?? EMPTY_STATE}
          </p>
        </Title>
        <div className="flex flex-row gap-x-40 self-end mobile:gap-x-90">
          <Title
            titleColorClass={titleColorClass}
            title={BAGGAGE_STATUS.CHECKED_IN}>
            <p className={numbersClass}>{checkedIn ?? EMPTY_STATE}</p>
          </Title>
          <Title
            titleColorClass={titleColorClass}
            title={BAGGAGE_STATUS.OFFLOAD}>
            <p className={numbersClass}>{offload ?? EMPTY_STATE}</p>
          </Title>
        </div>
      </div>
    </div>
  );
};

export default HeroBaggage;
