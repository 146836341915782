import React from 'react';
import { string, bool } from 'prop-types';
import { LOGIN_BUTTON_COPY } from '../../../utils/constants';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

interface ILoginButtonProps {
  authorizationUrl: string;
  isLoading: boolean;
}

const LoginButton = ({
  authorizationUrl,
  isLoading,
  ...others
}: ILoginButtonProps) => {
  const buttonClass =
    'flex justify-center mobile:w-[329px] w-[290px] h-[42px] py-12 bg-white rounded-4';
  const buttonTextClass =
    'text-primary dark:text-primary font-head-bold text-14 leading-[18px]';

  return (
    <div {...others}>
      <a className={buttonClass} href={authorizationUrl}>
        {isLoading ? (
          <LoadingSpinner width={24} height={24} />
        ) : (
          <span className={buttonTextClass}>{LOGIN_BUTTON_COPY}</span>
        )}
      </a>
    </div>
  );
};

export default LoginButton;
