import React, { ChangeEvent } from 'react';
import Title from '../../Title/Title';
import classNames from 'classnames';
import { useForm } from '../../../utils/hooks/useForm';

interface IInput {
  inputName: string;
  currentValue: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  title: string;
  validation?: (value: string) => { isError: boolean; errorMessage: string };
  disabled?: boolean;
  handleFocus?: () => void;
}

const Input = ({
  inputName,
  currentValue,
  handleChange,
  title,
  validation,
  disabled,
  handleFocus,
  ...others
}: IInput) => {
  const { isError, errorMessage, onBlur } = useForm({
    validation,
    currentValue,
  });
  const inputClassNames = classNames(
    'bg-white w-full border-1 border-primary rounded-4 py-12 pl-14 font-body-bold text-primary text-14 appearance-none focus:outline-none mt-8 dark:bg-grey-90 dark:border-grey-12 dark:text-grey-12',
    { 'border-red': isError, 'opacity-50': disabled }
  );

  const errorClassName = 'text-red pt-[4px]';

  return (
    <Title title={title} {...others}>
      <input
        name={inputName}
        type="text"
        pattern="[A-Za-z]"
        value={currentValue}
        onBlur={onBlur}
        onFocus={() => handleFocus?.()}
        onChange={(e) => handleChange?.(e)}
        className={inputClassNames}
        disabled={disabled}
      />
      {errorMessage && <p className={errorClassName}>{errorMessage}</p>}
    </Title>
  );
};

export default Input;
