import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  FLIGHT_CREW,
  GENERAL_NOTIFICATION_ARRAY,
  TEAM,
} from '../../utils/constants';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import HeroCrew from '../../components/HeroHeader/HeroCrew';
import ReloadButton from '../../components/CustomButtons/ReloadButton/ReloadButton';
import FlightCrew from '../../components/FlightCrew/FlightCrew';
import { useParams } from 'react-router-dom';
import {
  getFlightName,
  getThemeColor,
  getErrorMessage,
} from '../../utils/helpers';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useNotifications } from '../../utils/hooks/useNotifications';
import { INITIAL_STATE } from '../../utils/constants';
import TeamContact from '../../components/TeamContact/TeamContact';
import { useGetDetailsCrew } from '../../utils/hooks/useGetDetailsCrew';
import { useGetDetailsHeader } from '../../utils/hooks/useGetDetailsHeader';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';

const colClassNames = 'tablet:col-span-2 laptop:col-span-2 desktop:col-span-2';
const teamHeaderClassNames = classNames(
  'font-head-light text-18 text-grey-60 leading-[19px] dark:text-grey-40',
  colClassNames
);

const Team = () => {
  const { flight } = useParams();
  const isOnline = useNetworkStatus();

  const {
    data,
    loading: isFetchingTeam,
    error: errorEncountered,
    responseErrors: responseErrorsTeam,
    refetchDetailsCrew,
  } = useGetDetailsCrew(flight);

  const {
    data: dataHeader,
    loading: isFetchingHeader,
    error: errorEncounteredHeader,
    responseErrors: responseErrorsHeader,
    refetchDetailsHeader,
  } = useGetDetailsHeader(flight, 'header');

  const {
    deadheadCrewAssignments,
    onDutyCrewAssignments,
    cockpit,
    cabin,
    deadhead,
  } = data || {};

  const isFetching = isFetchingTeam || isFetchingHeader;
  const [errorsReceived, setErrorsReceived] = useState([]);
  const [allErrorsEncountered, setAllErrorsEncountered] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      let allErrorsReceived: any = [];
      let allErrorsEncountered: any = [];

      if (errorEncounteredHeader) {
        allErrorsEncountered.push(errorEncounteredHeader);
      }

      if (errorEncountered) {
        allErrorsEncountered.push(errorEncountered);
      }

      if (allErrorsEncountered.length > 1) {
        allErrorsEncountered = GENERAL_NOTIFICATION_ARRAY;
      }

      setAllErrorsEncountered(allErrorsEncountered);

      if (responseErrorsTeam) {
        allErrorsReceived.push(...responseErrorsTeam);
      }
      if (responseErrorsHeader) {
        allErrorsReceived.push(...responseErrorsHeader);
      }
      if (allErrorsReceived.length > 1) {
        allErrorsReceived = GENERAL_NOTIFICATION_ARRAY;
      }
      setErrorsReceived(allErrorsReceived);
    } else {
      setErrorsReceived([]);
    }
  }, [isFetching, errorEncounteredHeader, errorEncountered]);
  const [error, errorMessage] = getErrorMessage(
    errorsReceived,
    allErrorsEncountered
  );

  useNotifications(error ? { ...error } : null, errorMessage);
  const {
    flightName = getFlightName(flight),
    arrivalAirport = INITIAL_STATE,
    departureAirport = INITIAL_STATE,
    registration = INITIAL_STATE,
    type = INITIAL_STATE,
    bestTimeLogic = { timeLeft: INITIAL_STATE },
    departureTimes = { bestTimeUTC: INITIAL_STATE },
    isCheckedIn,
  } = dataHeader || {};

  const themeColor = getThemeColor(flightName);

  useCustomHeader({
    headerTypeMobile: 'flight',
    departureAirport,
    arrivalAirport,
    type,
    headerTypeTablet: 'flight',
    flightName,
    registration,
    bestTimeLogic,
    departureTimes,
    themeColor,
    isCheckedIn,
  });

  return (
    <div className="px-24 pt-32 pb-[98px] mobile:pt-0">
      <div className="mobile:relative mobile:left-0 w-fit absolute top-24 mobile:top-16 z-40 mobile:z-0 left-[63px] right-0 mx-auto">
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          isOnline && (
            <ReloadButton
              refetchFunctions={[refetchDetailsCrew, refetchDetailsHeader]}
            />
          )
        )}
      </div>
      <h1 className={teamHeaderClassNames}>{TEAM}</h1>
      <div className="flex gap-16 mt-44 mobile:flex-col">
        {process.env.REACT_APP_TAC_TEAM_CONTACT_FEATURE_DISABLED?.toUpperCase() !==
          'TRUE' && <TeamContact />}
        <div className="w-2/3 mobile:w-full">
          <HeroCrew
            cockpit={cockpit}
            cabin={cabin}
            deadhead={deadhead}
            themeColor={themeColor}
          />
          <FlightCrew
            hasNoData={!isFetching && !onDutyCrewAssignments?.length}
            crewTitle={FLIGHT_CREW.CREW_ON_DUTY}
            crewList={onDutyCrewAssignments}
          />
          <FlightCrew
            hasNoData={!isFetching && !deadheadCrewAssignments?.length}
            crewTitle={FLIGHT_CREW.CREW_DEADHEAD}
            crewList={deadheadCrewAssignments}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
