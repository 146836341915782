import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const briefingPackageReducer = createSlice({
  name: 'briefingPackage',
  initialState: {
    isBriefingPackageOpen: false,
  },
  reducers: {
    setIsBriefingPackageOpen(state, action) {
      state.isBriefingPackageOpen = action.payload;
    },
  },
});

export const { setIsBriefingPackageOpen } = briefingPackageReducer.actions;
export const selectIsBriefingPackageOpen = (state: RootState) => {
  return state.briefingPackageReducer.isBriefingPackageOpen;
};
export default briefingPackageReducer.reducer;
