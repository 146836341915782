import React from 'react';
import classNames from 'classnames';
import ChatBubble from './ChatBubble';
import RoleAvatar from './RoleAvatar';
import { IGroupMessessage } from '../ChatWindow';
import { createUniqueId } from '../../../../../utils/createUniqueId';

export enum ChatPosition {
  Left = 'left',
  Right = 'right',
}

export enum ChatColor {
  Primary = 'primary',
  Grey = 'grey',
}

interface IChatMessages {
  position: ChatPosition;
  color: ChatColor;
  userName: string;
  createdAt: string;
  messages: IGroupMessessage[];
  retrySendMessage: () => void;
  deleteChatMessage: (messageId: string) => void;
  actionsDisabled: boolean;
}
const ChatMessages = ({
  position,
  color,
  userName,
  createdAt,
  messages,
  retrySendMessage,
  deleteChatMessage,
  actionsDisabled,
}: IChatMessages) => {
  const renderMessageTimestamp = (message, previousMessage) => {
    if (!previousMessage || message.createdAt === previousMessage.createdAt) {
      return '';
    } else {
      return message.createdAt;
    }
  };

  return (
    <>
      <div
        className={classNames('flex items-center gap-16', {
          'flex-row-reverse': position === 'right',
        })}>
        <RoleAvatar userName={userName} color={color} />
        <div className="text-grey-40 text-12 font-body-text">{createdAt}</div>
      </div>
      <>
        {messages.map((message, index, array) => (
          <div
            key={createdAt + createUniqueId()}
            className={classNames('flex flex-col ', {
              'flex-row-reverse items-end': position === 'right',
            })}>
            <div
              key={createdAt + createUniqueId()}
              className={classNames(
                'text-grey-40 text-12 font-body-text ml-48 mr-48',
                { 'mb-4': message.createdAt }
              )}>
              {renderMessageTimestamp(message, array[index - 1])}
            </div>
            <ChatBubble
              text={message.body}
              position={position}
              color={color}
              className={classNames({
                'flex flex-row-reverse w-full': position === 'right',
              })}
              status={message.status}
              retrySendMessage={retrySendMessage}
              deleteChatMessage={() =>
                deleteChatMessage(message?.messageId || '')
              }
              actionsDisabled={actionsDisabled}
            />
          </div>
        ))}
      </>
    </>
  );
};

export default ChatMessages;
