import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import {
  removeNotification,
  selectNotifications,
} from '../../redux/reducers/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '../Badge/Badge';
import Icon from '../Icon/Icon';
import { VARIANTS } from '../../utils/constants';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';

const getElapsedTime = (startTime: Moment) => {
  const now = moment();
  const diffInSeconds = now.diff(startTime, 'seconds');
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = Math.floor(diffInSeconds % 60);
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};

const NotificationsList = ({ ...others }) => {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const [elapsedTime, setElapsedTime] = useState('00:00');
  const isOnline = useNetworkStatus();
  const handleRemoveItem = (id: string) => {
    dispatch(removeNotification(id));
  };

  useEffect(() => {
    if (
      !isOnline &&
      process.env.REACT_APP_TAC_OFFLINE_BANNER_FEATURE_DISABLED?.toUpperCase() !==
        'TRUE'
    ) {
      const startTime = moment();
      const intervalId = setInterval(() => {
        setElapsedTime(getElapsedTime(startTime));
      }, 1000);
      return () => clearTimeout(intervalId);
    }
  }, [isOnline]);

  useEffect(() => {
    const successNotification = notifications.find(
      (notification) => notification.variant === VARIANTS.SUCCESS
    );
    if (successNotification) {
      const timeoutId = setTimeout(() => {
        dispatch(removeNotification(successNotification.id));
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [notifications, dispatch]);

  return (
    <div className="flex flex-col gap-14 items-center" {...others}>
      {!!notifications.length &&
        notifications.map(({ id, variant, text }) => (
          <Badge
            key={id}
            Icon={
              <Icon
                width={11}
                height={11}
                variant={`${variant === VARIANTS.ERROR ? 'cancel' : 'message'}`}
                className="fill-white"
              />
            }
            className={classNames('gap-x-14 leading-3 text-center', {
              'bg-red border-1': variant === VARIANTS.ERROR,
              'bg-green': variant === VARIANTS.SUCCESS,
              'bg-orange': variant === VARIANTS.WARNING,
            })}
            text={
              variant === VARIANTS.WARNING ? `${text} ${elapsedTime}` : text
            }
            isClosable={variant !== VARIANTS.WARNING}
            onClose={() => handleRemoveItem(id as string)}
          />
        ))}
    </div>
  );
};

export default NotificationsList;
