import moment from 'moment';

export const processEarly = ({ start, end, delayStart, delayEnd }, minutePixels) => {
  const timeObject = [0, 0, false];
  const startX = moment(start, 'DD-MM-YYYY HH:mm').unix();
  const endX = moment(end, 'DD-MM-YYYY HH:mm').unix();
  const delayStartX = delayStart ? moment(delayStart, 'DD-MM-YYYY HH:mm').unix() : null;
  const delayEndX = delayEnd ? moment(delayEnd, 'DD-MM-YYYY HH:mm').unix() : null;

  if (delayEndX !== null && delayEndX <= endX) {
    if (delayStartX !== null && delayStartX > startX) {
      timeObject[0] = ((delayStartX - startX) / 60) * minutePixels;
      timeObject[2] = true;
    }

    if (delayEndX < endX) {
      timeObject[1] = ((endX - delayEndX) / 60) * minutePixels;
      timeObject[2] = true;
    }

    if (delayStartX === null) {
      timeObject[0] = 0;
      timeObject[1] = 0;
      timeObject[2] = false;
    }
  }

  return timeObject;
};
