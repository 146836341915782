import { Dispatch } from '@reduxjs/toolkit';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import {
  deleteCheckinUser,
  setCheckedoutHighlander,
} from '../../redux/reducers/checkinReducer';
import { setIsCheckIn } from '../../redux/reducers/headerReducer';
import { setCountdownToZero } from '../../redux/reducers/reloadReducer';

const messageHandler = UtilFactory.newMessageHandler();

export const subscribeToHighlander = (
  flightId: string,
  userId: string,
  dispatch: Dispatch
) => {
  const topic = `${MQTT_TOPIC.HIGHLANDER}${flightId}/${userId}`;
  messageHandler.subscribe({
    topic,
    onMessage: async (topic: string, message: string) => {
      const checkedoutUserData = JSON.parse(message);
      dispatch(setCountdownToZero());
      dispatch(
        setCheckedoutHighlander({
          isCheckedOut: true,
          checkedoutFlight: checkedoutUserData.flightId,
          checkedoutRole: checkedoutUserData.role,
        })
      );
      dispatch(deleteCheckinUser(checkedoutUserData.flightId));
      dispatch(setIsCheckIn(false));
      unsubscribeToHighlander(checkedoutUserData.flightId, userId);
    },
  });
};

export const unsubscribeToHighlander = (flightId: string, userId: string) => {
  const topic = `${MQTT_TOPIC.HIGHLANDER}${flightId}/${userId}`;
  messageHandler.unsubscribe(topic);
};
