import React, { useRef } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';
import { STICKY_SUB_NAV_LINKS } from '../../../utils/constants';
import Icon from '../../Icon/Icon';
import { useToggle } from '../../../utils/hooks/useToggle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import {
  selectAllNotifications,
  setActiveFlightIdScreen,
  setShowSpecificNotificationsOnly,
} from '../../../redux/reducers/newsfeedReducer';
import {
  OverlayModule,
  setOpenOverlay,
} from '../../../redux/reducers/overlayReducer';
import Button from '../../Button/Button';
import { selectChatMessages } from '../../../redux/reducers/chatReducer';
import { getUnreadMessagesCountByFlightId } from '../../../utils/helpers';
import { selectCheckedInUsers } from '../../../redux/reducers/checkinReducer';
import useCheckPermissions, {
  CheckType,
} from '../../../utils/hooks/useCheckPermissions';
import { Permission } from '../../../utils/generated/graphql';

const containerTabletClassNames =
  'w-fit h-44 flex items-center bg-primary rounded-4 px-32 mobile:hidden dark:bg-black dark:border-1 dark:border-grey-80';
const navItemClassNames =
  'relative list-none flex tablet:justify-center laptop:justify-center';
const linkClassNames =
  'py-13 px-[17px] leading-18 h-44 text-14 text-white font-head-bold dark:text-white mobile:w-full';
const activeClassNames = 'absolute bottom-0 w-32 h-4 bg-secondary';

const activeLinkMobileClassNames =
  'leading-18 text-14 text-white font-head-bold dark:text-white';
const actionButtonsClassNames =
  'bg-secondary flex items-center justify-center mobile:h-[47px] mobile:self-end';

interface IStickySubNavLink {
  name: string;
  link: string;
  isActive?: boolean;
  toggleCollapse?: () => void;
}

interface IStickySubNav {
  page: string;
}

const StickySubNavLink = ({
  name,
  link,
  isActive = false,
  toggleCollapse,
}: IStickySubNavLink) => {
  return (
    <li
      className={classNames(navItemClassNames, {
        'min-w-[115px]': name === 'Above Wing',
        'min-w-[112px]': name === 'Below Wing',
      })}>
      <Link
        onClick={() => toggleCollapse?.()}
        to={link}
        className={linkClassNames}>
        {name}
      </Link>
      {isActive && <div className={activeClassNames}></div>}
    </li>
  );
};

const StickySubNav = ({ page, ...others }: IStickySubNav) => {
  const isCheckedIn = useSelector(
    (state: RootState) => state.headerReducer.isCheckedIn
  );
  const { pathname } = useLocation();
  const pageIndex = pathname.indexOf(`/${page}`);
  const parentUrl = pathname.substring(0, pageIndex);
  const dispatch = useDispatch();
  const { flight } = useParams();
  const links = STICKY_SUB_NAV_LINKS(page);
  const [isCollapsed, toggle] = useToggle(true);
  const chatMessages = useSelector(selectChatMessages);
  const checkedInRoles = useSelector(selectCheckedInUsers);
  const currentRole = checkedInRoles[flight ?? '']?.myRole;
  const unreadMessages = getUnreadMessagesCountByFlightId(
    chatMessages,
    flight,
    currentRole?.roleGroup
  );
  const notifications = useSelector(selectAllNotifications);

  const unreadFlightNotifications =
    notifications?.[flight]?.filter((n) => !n.read).length ?? 0;

  const hasNotifications = !!notifications?.[flight]?.length;
  const clickOutsideRef = useRef(null);

  useClickOutside(
    clickOutsideRef,
    () => {
      if (!isCollapsed) {
        toggle();
      }
    },
    isCollapsed
  );

  const containerMobileClassNames = classNames(
    'w-[294px] tablet:hidden laptop:hidden desktop:hidden bg-secondary rounded-l-4',
    { 'rounded-t-4': !isCollapsed },
    { 'rounded-r-4': hasNotifications }
  );

  const buttonClassNames = classNames(
    'bg-primary opacity-100 w-full px-16 py-13 flex justify-between items-center dark:bg-black',
    {
      'rounded-4': isCollapsed,
      'rounded-t-0 rounded-b-4': !isCollapsed,
    }
  );
  const collapsedLinksClassNames = classNames(
    'h-[131px] bg-primary opacity-95 rounded-t-4 dark:bg-black',
    {
      hidden: isCollapsed,
    }
  );

  const mobileActiveLink = links.find((item) => item.isActive)?.name;
  const collapsedLinks = links.filter((item) => !item.isActive);

  const handleExpand = () => toggle();

  const handleOpenFlightChat = () => {
    dispatch(setActiveFlightIdScreen(flight));
    dispatch(setOpenOverlay(OverlayModule.CHAT));
  };
  const hasChatPermissions = useCheckPermissions(
    [Permission.CHAT_READ, Permission.CHAT_WRITE],
    CheckType.PARTIAL
  );

  const hasNotificationAccess = useCheckPermissions([
    Permission.DISPLAY_NOTIFICATIONS,
  ]);

  const showChatButton =
    hasChatPermissions &&
    isCheckedIn &&
    process.env.REACT_APP_TAC_FEATURE_DISABLE_CHAT?.toLowerCase() !== 'true';

  const openFlightSpecificNotifications = () => {
    dispatch(setShowSpecificNotificationsOnly(true));
    dispatch(setOpenOverlay(OverlayModule.NOTIFICATIONS));
  };
  return (
    <div className="fixed z-30 flex left-1/2 bottom-16 -translate-x-1/2 tablet:bg-secondary laptop:bg-secondary desktop:bg-secondary rounded-[6px] mobile:px-24 mobile:w-screen mobile:flex mobile:justify-center">
      <nav className={containerTabletClassNames}>
        {links.map((item) => (
          <StickySubNavLink
            key={item.name}
            name={item.name}
            link={`${parentUrl}${item.link}`}
            isActive={item.isActive}
          />
        ))}
      </nav>
      <nav className={containerMobileClassNames}>
        <div className={collapsedLinksClassNames} {...others}>
          {collapsedLinks.map((item) => (
            <StickySubNavLink
              key={item.name}
              name={item.name}
              link={`${parentUrl}${item.link}`}
              toggleCollapse={() => toggle()}
            />
          ))}
        </div>
        <button
          ref={clickOutsideRef}
          className={buttonClassNames}
          onClick={handleExpand}>
          <span className={activeLinkMobileClassNames}>{mobileActiveLink}</span>
          <Icon
            width={12}
            height={8}
            variant={isCollapsed ? 'arrowCollapse' : 'arrowExpand'}
            className="fill-white dark:fill-white"
          />
        </button>
      </nav>
      <div className="relative flex">
        <span
          className={classNames(
            'w-10 h-44 mobile:h-[47px] bg-secondary absolute bottom-0 -left-4 -z-10 tablet:hidden laptop:hidden desktop:hidden',
            {
              hidden: !showChatButton && !hasNotifications,
            }
          )}></span>
        {showChatButton && (
          <Button
            className={classNames('pl-24', actionButtonsClassNames, {
              'rounded-r-4 pr-24': !hasNotifications,
            })}
            Icon={
              <Icon
                width={20}
                height={20}
                variant="chat"
                className="fill-white"
              />
            }
            onClick={handleOpenFlightChat}
            textClassName="text-white font-head-bold text-14 leading-[18px] ml-[6px]"
            text={!!unreadMessages ? unreadMessages : ''}
          />
        )}
        {hasNotificationAccess && hasNotifications && (
          <button
            onClick={openFlightSpecificNotifications}
            className={classNames(
              'pr-24 rounded-r-4',
              actionButtonsClassNames,
              {
                'pl-24': !showChatButton,
                'pl-[18px]': showChatButton,
              }
            )}>
            <Icon variant="mute" className="fill-white" />
            {!!unreadFlightNotifications && (
              <span className="text-white font-head-bold text-14 leading-[18px] ml-[6px]">
                {unreadFlightNotifications}
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default StickySubNav;
