import React, { useState, useEffect, useRef, useId } from 'react';
import moment from 'moment';
import 'moment-rcf';
import 'moment-duration-format';
import {array, bool} from 'prop-types';
import { GANTT_SETTINGS } from '../../utils/constants';
import { selectUTC } from '../../redux/reducers/settingsReducer';
import { useSelector } from 'react-redux';
import styles from './Gantt.module.scss';
import Icon from '../Icon/Icon';
import {
  barWidthNoDelay,
  barWidthDelay,
  processTimeLeft,
  processDelayTime,
  delayOffsetted,
  checkIfTimesAreEqual,
  processEarly,
} from './utils';
import { getDateInMilliseconds } from '../../utils/helpers';

const Gantt = (props) => {
  const { times } = props
  const UID = useId();

  //Set Screen Variables
  const hourPixels = 811;
  const minutePixels = hourPixels / 60;
  const delayOffset = moment('0:00', 'h:mm');

  //Replace sample time with current time applied directly to the function from
  //timeReducer to use the current time and correct format.
  const sampleTime = moment('13:35', 'HH:mm').format('HH:mm');

  const isUTC = useSelector(selectUTC);

  //Set Current Time State
  const [currentTime, setCurrentTime] = useState(
    // sampleTime
    isUTC
    ? moment().utc().format('HH:mm')
    : moment().format('HH:mm')
  );
  //Set Process Variable State
  const [processVariable, setProcessVariable] = useState(0);

  // Update current time every minute
  setInterval(function () {
    setCurrentTime(
      // sampleTime
      isUTC
        ? moment().utc().format('HH:mm')
        : moment().format('HH:mm')
    );
  }, 60 * 1000);

  //Set Vertical Line to current time
  const curRef = useRef();

  //Scroll Wrapper Ref
  const scrollRef = useRef();

  //Create a Timeline
  const moveBack = moment(currentTime, 'HH:mm').subtract(2.5, 'hours');

  //Create Timeline (31 Times)
  const timelineTimesArray = [...Array(30)].map((_, i) => {
    return moment(moveBack)
      .add(i * 10, 'minutes')
      .format('HH:mm');
  });

  //Timeline Times and Vertical Lines
  const timelineTimes = timelineTimesArray.map((item) => {
    const m = moment(item, 'HH:mm');
    const closest5Minutes = m.round(10, 'minutes').format('HH:mm');
    return (
      <span
        key={item}
        data-timelinetimesitem
        className={styles[`timelineTimesItem`]}>
        {closest5Minutes}
        <strong></strong>
        <strong></strong>
        <strong></strong>
        <strong></strong>
      </span>
    );
  });

  //Set Current Time
  const centerRef = useRef();
  const timelineMargin = moment(currentTime, 'HH:mm').format('mm')[1];

  //Used to trigger the use effect, so the scroll can be centered
  const [updateScroll, setUpdateScroll] = useState(false);
  //Set Each Process Width and Placement
  //The hour width is 811px, and manual adjustments are needed to make the design pixel perfect
  useEffect(() => {
    const timelineVariable = [50, 35, 20, 5, -10, 115, 105, 90, 77, 64][timelineMargin];
    setProcessVariable([57, 71, 83, 97, 110, -13, 2, 15, 29, 43][timelineMargin]);

    //Scroll to current time
    //2175 represents half the width of the timelineTimesWrapper
    //135 represents the width of a single timelineTimesItem
    scrollRef?.current?.scrollTo(
      2130 - timelineVariable - centerRef?.current?.offsetLeft,
      0
    );
  }, [timelineMargin, currentTime, updateScroll]);

  //Set Each Process Placement
  const barPlacement = () => {
    return {
      // 2175 represents the width of the timelineTimesWrapper
      // 91 represents 3/4 the width of a single timelineTimesItem
      left: 2175 - 229 + 'px',
      //13.5 represents the width of 1 minute
      marginLeft:
        timelineMargin * 13.5 + (timelineMargin < 5 ? 137.5 : 0) + 'px',
    };
  };

  //Gantt Output
  const ganttChartOutput = times.map((ganttTimes) => {
    return (
      <div key={UID + ganttTimes.id}>
        <div
          data-timelineitem
          className={`${styles[`processBar`]} ${
            checkIfTimesAreEqual(ganttTimes, delayOffset) &&
            !ganttTimes.delayStart
              ? styles[`hasProcess`]
              : ''
          }`}
          style={barWidthNoDelay(
            ganttTimes,
            currentTime,
            minutePixels,
            processVariable
          )}>
          <span data-ganttlabel>{ganttTimes.name}</span>
          <strong
            className={`${
              ganttTimes.delayStart && ganttTimes.delayEnd
                ? styles[`hasDelay`]
                : ''
            }`}>
            {GANTT_SETTINGS.DEMO === true ? (
              'Demo'
            ) : processEarly(ganttTimes, minutePixels)[1] > 30 &&
            processEarly(ganttTimes, minutePixels)[2] === true ? (
              <strong data-isearly>
                {processTimeLeft(ganttTimes, currentTime)}
              </strong>
            ) : (
              processTimeLeft(ganttTimes, currentTime)
            )}
          </strong>
          <em
            style={{
              left: processEarly(ganttTimes, minutePixels)[0],
              right: processEarly(ganttTimes, minutePixels)[1],
            }}
            className={
              checkIfTimesAreEqual(ganttTimes, delayOffset) &&
              (!ganttTimes.delayStart || processDelayTime(ganttTimes) || (getDateInMilliseconds(ganttTimes.delayStart) < getDateInMilliseconds(ganttTimes.start)))
                ? styles[`isAction`]
                : ''
            }></em>
        </div>
        {ganttTimes.delayStart &&
          delayOffsetted(ganttTimes, currentTime, delayOffset) !== null && (
            <div
              className={`${styles[`processBar`]} ${styles[`delayBar`]} ${((getDateInMilliseconds(ganttTimes.delayStart) < getDateInMilliseconds(ganttTimes.start)) && checkIfTimesAreEqual(ganttTimes, delayOffset)) ? styles[`isAction`] : ''}` }
              style={barWidthDelay(
                ganttTimes,
                currentTime,
                minutePixels,
                processVariable
              )}>
              <strong>
                {processEarly(ganttTimes, minutePixels)[0] === 0 &&
                  processEarly(ganttTimes, minutePixels)[1] === 0 &&
                  delayOffsetted(ganttTimes, currentTime, delayOffset)}
              </strong>
            </div>
          )}
      </div>
    );
  });

  return (
    <div className={styles[`scrollFix`]}>
      <span ref={centerRef} className={styles[`onePixelCenterFinder`]} />
      {GANTT_SETTINGS.DEMO && (
        <div className={styles[`alertText`]}>
          <div className="flex items-center">
            <div className="flex-none p-3 pr-5">
              <Icon
                variant="cancel"
                width={30}
                height={30}
                className="fill-red"
              />
            </div>
            <div className="flex-grow">
              <h2 className="head-bold">{GANTT_SETTINGS.DEMO_TITLE}</h2>
              <p className="block text-14 body-text">
                {GANTT_SETTINGS.DEMO_TEXT}
              </p>
            </div>
            <div className="flex-2">
              <p className="body-text">
                {/* <a
                  href="https://www.google.com"
                  className="block text-14 body-text">
                  View Schedule
                </a> */}
              </p>
            </div>
          </div>
        </div>
      )}
      <div
        ref={scrollRef}
        style={{ height: times.length * 110 + 'px' }}
        className={styles[`scrollWrapper`]}>
        {ganttChartOutput}
        <div className={styles[`timelineTimesWrapper`]}>{timelineTimes}</div>
        <div
          ref={curRef}
          style={barPlacement()}
          data-currenttime
          className={styles[`currentTime`]}>
          <button onClick={() => setUpdateScroll(!updateScroll)}>
            {currentTime}
          </button>
        </div>
      </div>
    </div>
  );
};

Gantt.propTypes = {
  times: array,
};

export default Gantt;
