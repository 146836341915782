import React, { useLayoutEffect } from 'react';
import SearchFlights from '../../components/FlightSearch/FlightSearch/FlightSearch';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuery, setQuery } from '../../redux/reducers/searchReducer';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import { FLIGHT_SEARCH } from '../../utils/constants';
import { useNotifications } from '../../utils/hooks/useNotifications';
import { invalidateSearch } from '../../redux/api/base/base-api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAddMyFlight } from '../../utils/hooks/useAddMyFlight';
import { useRemoveMyFlight } from '../../utils/hooks/useRemoveMyFlights';
import { useSearchFlights } from '../../utils/hooks/useSearchFlights';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';

const flightsHeaderClassNames =
  'font-head-light text-18 text-grey-60 leading-[19px] dark:text-grey-40 pt-[34px] pb-[31px] mobile:hidden px-24';
const searchFlightsClassNames = 'h-full flex flex-col mobile:pt-80';

const headerConfig = { headerTypeMobile: 'simple', title: 'Flightsearch' } as {
  headerTypeMobile: 'simple' | 'flight';
  title: string;
};
const FlightSearch = () => {
  const query = useSelector(selectQuery);

  const dispatch = useDispatch();
  useCustomHeader(headerConfig);

  const { state } = useLocation();
  const shouldKeepQuery = state?.shouldKeepQuery;
  let [searchParams, setSearchParams] = useSearchParams();
  const doesQueryExist = searchParams.get('query');
  const isOnline = useNetworkStatus();

  const { data, loading, error } = useSearchFlights(query?.toUpperCase() ?? '');

  const { clearNotifications } = useNotifications(error, 'No flights found');

  const { onAddToFavourites } = useAddMyFlight();
  const { onRemoveFromFavourites } = useRemoveMyFlight();

  useLayoutEffect(() => {
    const handleQueryUpdate = () => {
      if (doesQueryExist) {
        return;
      }
      if (!shouldKeepQuery) {
        dispatch(setQuery(null));
      }
    };
    handleQueryUpdate();
  }, [dispatch, doesQueryExist, shouldKeepQuery]);

  return (
    <div className={searchFlightsClassNames}>
      <div className={flightsHeaderClassNames}>{FLIGHT_SEARCH.HEADER}</div>
      <SearchFlights
        flightsList={data ?? []}
        addToFavourites={onAddToFavourites}
        removeFromFavourites={onRemoveFromFavourites}
        onSearch={(value: string | null) => {
          if (error) {
            dispatch(invalidateSearch());
          }
          dispatch(clearNotifications());
          dispatch(setQuery(value));
          let params = {
            query: value,
          };
          setSearchParams(params);
        }}
        searchQuery={shouldKeepQuery || doesQueryExist ? query : null}
        isLoading={isOnline && loading}
      />
    </div>
  );
};

export default FlightSearch;
