import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  DEPARTED_OR_CANCELLED,
  FLIGHT_STATUS,
  NO_ACTIVE_MY_FLIGHTS,
} from '../../utils/constants';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import FlightBlock from './FlightBlock/FlightBlock';
import { MyFlight } from '../../utils/generated/graphql';
import { IMyFlightEdit } from '../../pages/Favourites/Favourites';

interface IMyFlightsProps {
  flightDataList: MyFlight[] | [];
  isEditable?: boolean;
  flightListEdit: IMyFlightEdit[];
  setFlightListEdit: Dispatch<SetStateAction<IMyFlightEdit[]>>;
  pendingMutedFlights: string[];
  pendingRemovedFlights: string[];
}

const FlightGroupingArea = ({
  flights = [],
  isEditable,
  flightListEdit,
  setFlightListEdit,
  addFlightOnMuteList,
  addFlightOnRemoveList,
}: {
  flights: MyFlight[] | [];
  isEditable: boolean;
  flightListEdit: IMyFlightEdit[];
  setFlightListEdit: Dispatch<SetStateAction<IMyFlightEdit[]>>;
  addFlightOnMuteList: (flightId: string) => void;
  addFlightOnRemoveList: (flightId: string) => void;
}) => {
  return (
    <div className="mb-40 mobile:ml-0 ml-24 flex mobile:justify-center flex-wrap gap-x-16 gap-y-32">
      {flights.map((flightData: MyFlight) => {
        return (
          <FlightBlock
            key={flightData.flightId}
            flightData={{
              ...flightData,
              flightStatus: flightData?.flightStatus
                ? flightData.flightStatus
                : FLIGHT_STATUS.DEFAULT,
            }}
            isEditable={isEditable}
            error={flightData.errorMessage}
            flightListEdit={flightListEdit}
            setFlightListEdit={setFlightListEdit}
            addFlightOnMuteList={addFlightOnMuteList}
            addFlightOnRemoveList={addFlightOnRemoveList}
          />
        );
      })}
    </div>
  );
};

const MyFlights = ({
  flightDataList = [],
  isEditable = false,
  flightListEdit,
  setFlightListEdit,
  pendingMutedFlights,
  pendingRemovedFlights,
}: IMyFlightsProps) => {
  const [isCollapsedInactive, setCollapsedInactive] = useState(false);

  const addFlightOnMuteList = (flight: string) => {
    const flightIndex = pendingMutedFlights.indexOf(flight);

    flightIndex !== -1
      ? pendingMutedFlights.splice(flightIndex, 1)
      : pendingMutedFlights.push(flight);
  };

  const addFlightOnRemoveList = (flight: string) => {
    const flightIndex = pendingRemovedFlights.indexOf(flight);
    flightIndex !== -1
      ? pendingRemovedFlights.splice(flightIndex, 1)
      : pendingRemovedFlights.push(flight);
  };

  const activeFlights = flightDataList.filter(
    (elem) => elem.flightStatus === FLIGHT_STATUS.DEFAULT
  );
  const inactiveFlights = flightDataList.filter(
    (elem) => elem.flightStatus !== FLIGHT_STATUS.DEFAULT
  );

  return (
    <>
      {activeFlights.length > 0 ? (
        <FlightGroupingArea
          flights={activeFlights}
          isEditable={isEditable}
          flightListEdit={flightListEdit}
          setFlightListEdit={setFlightListEdit}
          addFlightOnMuteList={addFlightOnMuteList}
          addFlightOnRemoveList={addFlightOnRemoveList}
        />
      ) : (
        <div className="flex justify-center">
          <p className="flex items-center h-[377px] self-center text-grey-40 font-body-text text-12 font-[400] leading-[12px] tracking-[0.5px]">
            {NO_ACTIVE_MY_FLIGHTS}
          </p>
        </div>
      )}
      {!!inactiveFlights.length && (
        <>
          <div className="mb-16 ml-24 flex items-center">
            <p className="font-head-light text-18 text-grey-60 leading-[23px]">
              {DEPARTED_OR_CANCELLED}
            </p>
            <Button
              onClick={() => setCollapsedInactive((prev) => !prev)}
              Icon={
                <Icon
                  width="15"
                  variant={
                    isCollapsedInactive ? 'arrowCollapse' : 'arrowExpand'
                  }
                  className="ml-8 fill-grey-60"
                />
              }
            />
          </div>
          <>
            {!isCollapsedInactive && (
              <FlightGroupingArea
                flights={inactiveFlights}
                isEditable={isEditable}
                flightListEdit={flightListEdit}
                setFlightListEdit={setFlightListEdit}
                addFlightOnMuteList={addFlightOnMuteList}
                addFlightOnRemoveList={addFlightOnRemoveList}
              />
            )}
          </>
        </>
      )}
    </>
  );
};

export default MyFlights;
