import { gql } from '@apollo/client';

export const UPDATE_ETD_FOR_FLIGHT = gql`
  mutation UpdateEtdForFlight($input: EtdFlightUpdateInput!) {
    updateEtdForFlight(input: $input) {
      status
      errors {
        code
        message
        description
      }
    }
  }
`;
