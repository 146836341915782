import React, { forwardRef } from 'react';
import Button from '../../../Button/Button';
import Icon from '../../../Icon/Icon';
import { ForwardedRef } from 'react';
import useNetworkStatus from '../../../../utils/hooks/useNetworkStatus';

interface IStationTag {
  station: string;
  onClose: () => void;
  onSort: () => void;
}

const StationTag = forwardRef(
  (
    { station, onClose, onSort, ...others }: IStationTag,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const disabled = !useNetworkStatus();
    return (
      <div
        tabIndex={0}
        ref={ref}
        className="flex bg-grey-25 rounded-8 items-center py-4"
        {...others}>
        <Button
          className="pl-10 pr-4"
          textClassName="font-body-bold text-primary dark:text-black"
          text={station}
          onClick={onSort}
          disabled={disabled}
        />
        <Button
          className="pr-10 pl-4"
          Icon={<Icon variant="close" width={8} height={8} />}
          onClick={onClose}
          disabled={disabled}
        />
      </div>
    );
  }
);

StationTag.displayName = 'StationTag';

export default StationTag;
