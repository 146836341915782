import { useQuery } from '@apollo/client';
import { GET_NOTIFICATION_META } from '../../graphql/notifications/notifications.quries';
import { Query } from '../generated/graphql';

export const useGetNotificationMeta = (skipCondition: boolean) => {
  const { data, loading, error } = useQuery<Pick<Query, 'notificationTypes'>>(
    GET_NOTIFICATION_META,
    {
      skip: skipCondition,
    }
  );

  return {
    data: data?.notificationTypes ?? null,
    loading,
    error,
  };
};
