import { useQuery } from '@apollo/client';
import { Query, DetailsHeaderData, DataError } from '../generated/graphql';
import { IDataResponse } from '../types';
import { DETAILS_HEADER } from '../../graphql/flightDetails/flight-details.queries';
import useNetworkStatus from './useNetworkStatus';

interface IGetDetailsHeader extends IDataResponse<DetailsHeaderData> {
  refetchDetailsHeader: () => Promise<DetailsHeaderData | null>;
  loadingFirstTime: boolean;
  responseErrors: DataError[] | null;
}

export const useGetDetailsHeader = (
  flightId?: string,
  scope?: string
): IGetDetailsHeader => {
  const isOffline = !useNetworkStatus();
  const { data, loading, error, networkStatus, refetch } = useQuery<
    Pick<Query, 'detailsHeader'>
  >(DETAILS_HEADER, {
    variables: {
      flightInfo: {
        flightId,
        scope,
      },
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    skip: isOffline,
  });

  const refetchDetailsHeader = async () => {
    const result = await refetch();

    return result?.data?.detailsHeader?.data ?? null;
  };

  const convertedErrors =
    data?.detailsHeader?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ?? null;

  return {
    data: data?.detailsHeader?.data ?? null,
    loading,
    error,
    responseErrors: convertedErrors,
    refetchDetailsHeader,
    loadingFirstTime: networkStatus === 1,
  };
};
