import React, { useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import { THEME, AUTOMATIC, LIGHT, DARK } from '../../../utils/constants';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import Icon from '../../Icon/Icon';
import { Theme } from 'src/utils/types';

interface ITheming {
  theme: Theme;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const optionClassNames = 'appearance-none hidden';
const defaultClassNames =
  'flex flex-col items-center gap-[6px] font-head-bold text-primary text-14 leading-[18px] border-1 border-primary w-full pb-13 pt-15 flex justify-center dark:border-grey-12 dark:text-grey-12';
const selectedClassNames =
  'bg-primary text-white1 dark:bg-grey-12 dark:text-grey-90';
const notSelectedClassNames = 'bg-white dark:bg-grey-90';

const getIconClassNames = (option: Theme, selectedOption: Theme) => {
  return classNames({
    'fill-primary dark:fill-grey-12': option !== selectedOption,
    'fill-white dark:fill-grey-90': option === selectedOption,
  });
};
const Theming = ({
  theme,
  handleChange,
  disabled = false,
  ...others
}: ITheming) => {
  const [setSelectedOption] = useDarkMode(theme);

  const getLabelClassNames = (option: Theme, selectedOption: Theme) => {
    return classNames(defaultClassNames, {
      [selectedClassNames]: option === selectedOption,
      [notSelectedClassNames]: option !== selectedOption,
      'opacity-50' : disabled,
      'rounded-l-4 border-r-0': option === AUTOMATIC,
      'border-x-0': option === LIGHT,
      'rounded-r-4 border-l-0': option === DARK,
    });
  };

  useEffect(() => {
    setSelectedOption(theme);
  }, [theme, setSelectedOption]);

  return (
    <div className="flex" {...others}>
      <input
        type="radio"
        name="theme"
        id={AUTOMATIC}
        value={AUTOMATIC}
        className={optionClassNames}
        checked={theme === AUTOMATIC}
        onChange={(e) => handleChange?.(e)}
        disabled={disabled}
      />
      <label
        htmlFor={AUTOMATIC}
        className={getLabelClassNames(AUTOMATIC, theme)}>
        <Icon
          width={15}
          height={15}
          variant="sunRising"
          className={getIconClassNames(AUTOMATIC, theme)}
        />
        {THEME.AUTOMATIC}
      </label>
      <input
        type="radio"
        name="theme"
        id={LIGHT}
        value={LIGHT}
        className={optionClassNames}
        checked={theme === LIGHT}
        onChange={(e) => handleChange?.(e)}
        disabled={disabled}
      />
      <label htmlFor={LIGHT} className={getLabelClassNames(LIGHT, theme)}>
        <Icon variant="sun" className={getIconClassNames(LIGHT, theme)} />
        {THEME.LIGHT}
      </label>
      <input
        type="radio"
        name="theme"
        value={DARK}
        id={DARK}
        className={optionClassNames}
        checked={theme === DARK}
        onChange={(e) => handleChange?.(e)}
        disabled={disabled}
      />
      <label htmlFor={DARK} className={getLabelClassNames(DARK, theme)}>
        <Icon variant="moon" className={getIconClassNames(DARK, theme)} />
        {THEME.DARK}
      </label>
    </div>
  );
};

export default Theming;
