import { useCallback, useEffect } from 'react';

export const usePreventHistoryChange = (
  condition: boolean,
  callback: () => void
) => {
  const stable = useCallback(callback, []);

  useEffect(() => {
    const handlePopState = (_) => {
      if (condition) {
        window.history.pushState(null, '', window.location.href);
        stable();
        return;
      }
    };
    if (condition) {
      window.history.pushState(null, '', window.location.href);
    }
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [condition, stable]);
};
