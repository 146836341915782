import React from 'react';

interface IPopover {
  children: React.ReactNode;
  buttonTitle: string;
  buttonClass: string;
  isOpen: boolean;
  setIsOpen: () => void;
}
const Popover = ({
  children,
  buttonClass,
  buttonTitle,
  isOpen,
  setIsOpen,
}: IPopover) => {
  return (
    <div>
      <button className={buttonClass} onClick={setIsOpen}>
        {buttonTitle}
      </button>
      {isOpen && children}
    </div>
  );
};

export default Popover;
