import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import {
  selectAuthReducer,
  selectUserId,
  selectIsFirstLogin,
  loginFailure,
} from '../../redux/reducers/authReducer';
import { useGetUserSettings } from '../../utils/hooks/useGetUserSettings';
import { useUpdateUserSettings } from '../../utils/hooks/useUpdateUserSettings';
import {
  getUserSettingsFailure,
  setUserSettingsFailure,
  setUserSettingsStart,
  updateUserSettingsSuccess,
} from '../../redux/reducers/settingsReducer';
import { AUTOMATIC, ERRORS, TIMEZONE_SETTINGS } from '../../utils/constants';
import {
  Permission,
  UpdateSettingsLocations,
  UserSettingsInput,
} from '../../utils/generated/graphql';
import { addError } from '../../redux/reducers/notificationReducer';
import { useNotifications } from '../../utils/hooks/useNotifications';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';

interface IUserSettingsProps {
  loggedIn: boolean;
  children: ReactElement;
}

const UserSettings: React.FC<IUserSettingsProps> = (
  props: IUserSettingsProps
) => {
  const { loggedIn, children } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector(selectUserId);
  const tokenData = useSelector(selectAuthReducer);
  const { data, loading, error } = useGetUserSettings({
    skip: !loggedIn,
  });

  const { onUpdateUserSettings, responseErrors } = useUpdateUserSettings();
  useNotifications(responseErrors?.[0], responseErrors?.[0]?.message);

  const userPhoneNumber =
    data?.phoneNumber ?? tokenData.tokenPhone?.replace(/\s/g, '') ?? '';
  const isFirstLogin = useSelector(selectIsFirstLogin);
  const hasChatReadPermission = useCheckPermissions([Permission.CHAT_READ]);

  const updateUserSettings =
    (data: UserSettingsInput): ((dispatch: AppDispatch) => Promise<void>) =>
    async (dispatch: AppDispatch) => {
      dispatch(setUserSettingsStart());
      try {
        const result = await onUpdateUserSettings(
          !hasChatReadPermission && !data.chatMuted
            ? { ...data, chatMuted: true }
            : data,
          isFirstLogin
            ? UpdateSettingsLocations.LOGIN
            : UpdateSettingsLocations.SETTINGS
        );

        dispatch(updateUserSettingsSuccess(result));
        window.location.href = '/';
      } catch (error) {
        dispatch(setUserSettingsFailure(ERRORS.GENERAL_ERROR));
        dispatch(addError(ERRORS.GENERAL_ERROR));
        dispatch(loginFailure(ERRORS.GENERAL_ERROR));
        window.location.href = '/';
      }
    };

  useEffect(() => {
    if (loggedIn && !loading && error) {
      dispatch(addError(ERRORS.ERROR_GET_SETTINGS));
    } else if (loggedIn && !loading && !error) {
      try {
        const stations: string[] = data
          ? data.stationsList?.length !== 0
            ? data?.stationsList
            : data?.station !== '' && data?.station !== null
            ? [data?.station ?? '']
            : []
          : [];

        const settings = {
          userName: userId,
          firstName: data?.firstName ?? tokenData.tokenFirstName ?? '',
          name: tokenData.tokenName ?? '',
          phoneNumber: userPhoneNumber,
          email: tokenData.tokenEmail ?? '',
          chatMuted: data?.chatMuted ?? false,
          stationsList: stations,
          station: data?.station ?? '',
          theme: data?.theme ?? AUTOMATIC,
          useUTC: data?.useUTC ?? TIMEZONE_SETTINGS.SET_UTC,
          use24: data?.use24 ?? TIMEZONE_SETTINGS.SET_24,
          stationMuted: data?.stationMuted ?? false,
          favoriteFlightsMuted: data?.favoriteFlightsMuted ?? false,
          nativeNotificationsMuted: data?.nativeNotificationsMuted ?? false,
          isPhoneReminderActive: !userPhoneNumber,
        };

        dispatch(updateUserSettings(settings));
      } catch (e) {
        dispatch(getUserSettingsFailure(e.message));
      }
    }
  }, [loading]);

  return children;
};

export default UserSettings;
