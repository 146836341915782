import MessagesHandler from '../utils/notifications/MessageHandler';

class UtilFactory {
  static messageHandler: MessagesHandler;

  static init() {
    UtilFactory.messageHandler = UtilFactory.newMessageHandler();
  }

  static newMessageHandler() {
    if (!UtilFactory.messageHandler) {
      UtilFactory.messageHandler = new MessagesHandler();
    }

    return UtilFactory.messageHandler;
  }
}

export default UtilFactory;
