import React from 'react';
import classNames from 'classnames';
import Title from '../../Title/Title';
import Icon from '../../Icon/Icon';
import { FLIGHT_CREW, ASSINGNMENT_TYPE } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import { calculateTime } from '../../../utils/calculateTime';
import { ITimesObject } from '../../../utils/types';

const dataClass = 'text-18 font-head-light text-primary dark:text-white';
const rankContainerClass = 'flex items-center gap-[16px]';
const rankClass = 'text-30 font-head-bold text-primary dark:text-white';
const inboundClass = 'mobile:col-start-3 mobile:col-end-5 mobile:ml-[17px]';

export interface IFlightCrewItem {
  id?: string;
  className?: string;
  employeeRole: { code: string };
  origin: string;
  inbound: string;
  gate: string;
  pos: string;
  arrivalTime: string;
  totalCrewMembers: number;
  assignmentType: string;
  arrivalTimes?: ITimesObject;
}

const FlightCrewItem = ({
  className,
  employeeRole,
  origin = '-',
  inbound,
  gate = '-',
  pos = '-',
  arrivalTime,
  totalCrewMembers,
  assignmentType,
  arrivalTimes,
  ...others
}: IFlightCrewItem) => {
  const containerClass = classNames(
    'grid grid-cols-[2fr_1fr_1fr_1fr_1fr_0.5fr] pt-[19px] pb-[11px] pl-24 pr-[28px] items-center rounded-8 bg-white dark:text-white dark:bg-grey-90',
    'mobile:grid-cols-[auto_auto_auto_40px] mobile:grid-rows-2 mobile:pt-[23px] mobile:pb-[17px] mobile:gap-y-[7px]',
    className
  );

  const isFocOrHotel =
    assignmentType === ASSINGNMENT_TYPE.FOC ||
    assignmentType === ASSINGNMENT_TYPE.HTL;

  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const time = calculateTime(isUTC, is24Format, arrivalTimes);

  return (
    <div className={containerClass} {...others}>
      <div className={rankContainerClass}>
        <div className={rankClass}>{employeeRole?.code}</div>
        <div
          className={classNames(
            'ml-[3px] mobile:m-0',
            {
              invisible: isFocOrHotel,
            },
            dataClass
          )}>
          {origin}
        </div>
      </div>
      {isFocOrHotel ? (
        <div className="col-span-4 mobile:col-span-1 mobile:justify-self-end">
          {assignmentType}
        </div>
      ) : (
        <>
          <Title
            title={FLIGHT_CREW.INBOUND}
            className={`${inboundClass} ${dataClass}`}>
            {inbound}
          </Title>
          <Title
            title={FLIGHT_CREW.GATE}
            className={`ml-[15px] mobile:ml-0 ${dataClass}`}>
            {gate}
          </Title>
          <Title
            title={FLIGHT_CREW.POSITION}
            className={`mobile:-ml-[34px] ${dataClass}`}>
            {pos}
          </Title>
          <Title
            title={FLIGHT_CREW.ARRIVAL}
            className={`-ml-[20px] mobile:ml-[17px] ${dataClass}`}>
            {time}
          </Title>
        </>
      )}
      <div className="flex justify-end mobile:col-span-4 mobile:justify-self-end">
        <Icon
          variant="people"
          width={24}
          height={24}
          className="fill-grey-25 dark:fill-white"
        />
        <span className={`ml-8 mobile:-mr-[4px] ${dataClass}`}>
          {totalCrewMembers}
        </span>
      </div>
    </div>
  );
};

export default FlightCrewItem;
