import React from 'react';
import SVG from 'react-inlinesvg';
import icons from './icons';
import classNames from 'classnames';

export type IconVariant =
  | 'star'
  | 'logout'
  | 'plane'
  | 'cancel'
  | 'close'
  | 'alert'
  | 'search'
  | 'upsidePlane'
  | 'arrow'
  | 'mute'
  | 'arrowUp'
  | 'info'
  | 'sunRising'
  | 'sun'
  | 'moon'
  | 'arrowExpand'
  | 'call'
  | 'lightning'
  | 'arrowCollapse'
  | 'gear'
  | 'home'
  | 'baggage'
  | 'person'
  | 'triangleUp'
  | 'triangleDown'
  | 'circle'
  | 'seat'
  | 'people'
  | 'profile'
  | 'arrowBack'
  | 'menu'
  | 'backArrow'
  | 'simpleMessage'
  | 'reload'
  | 'listMenu'
  | 'message'
  | 'feedback'
  | 'arrowPrevious'
  | 'arrowNext'
  | 'pen'
  | 'checkmark'
  | 'unmute'
  | 'checked'
  | 'unchecked'
  | 'filter'
  | 'admin'
  | 'deleteIcon'
  | 'chat'
  | 'takeoff'
  | 'QR'
  | 'messageSending'
  | 'messageSent'
  | 'messageDelivered'
  | 'messageRead'
  | 'messageFailed'
  | 'plus'
  | 'minus'
  | 'more'
  | 'default';

interface IIcon {
  variant: IconVariant;
  className?: string;
  height?: number | string;
  width?: number | string;
  text?: number;
  textClassName?: string;
}

const Icon = ({
  variant = 'default',
  className,
  height = 16,
  width = 16,
  text,
  textClassName,
  ...others
}: IIcon) => {
  const textClass = classNames(
    'absolute inset-0 flex items-center justify-center pb-1',
    textClassName
  );
  const componentClass = classNames('relative', {
    contents: !text,
  });

  const src = icons[variant];

  return (
    <>
      <div className={componentClass}>
        <SVG
          src={src}
          className={className}
          height={height}
          width={width}
          {...others}
        />
        {text && <div className={textClass}>{text}</div>}
      </div>
    </>
  );
};

export default Icon;
