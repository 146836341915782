import React from 'react';
import Title from '../../Title/Title';
import {
  EMPTY_STATE,
  INITIAL_STATE,
  PASSENGER_SPECIALS,
} from '../../../utils/constants';
import Grid from '../../Grid/Grid';

const containerClassNames =
  'bg-white rounded-8 mobile:mx-24 py-32 pl-32 dark:bg-grey-90 tablet:col-span-1 laptop:col-span-1 desktop:col-span-1 mobile:order-4 order-3 max-h-[287px]';
const specialLoadsClassNames =
  'font-body-text text-primary text-12 mb-24 dark:text-grey-12';
const subtitleClassNames =
  'font-head-light text-primary text-18 dark:text-grey-12 leading-[19px] h-[19px]';
const wheelchairClassNames = 'text-12 font-body-text';

interface IPassengerSpecialsProps {
  WCHR: string | number;
  WCHC: string | number;
  WCHS: string | number;
  StandBy: string | number;
  DEPA: string | number;
  DEPU: string | number;
  MEDA_MEQT: string | number;
  STRC: string | number;
  UMNR: string | number;
}
const PassengerSpecials = ({
  WCHR,
  WCHC,
  WCHS,
  StandBy,
  DEPA,
  DEPU,
  MEDA_MEQT,
  STRC,
  UMNR,
  ...others
}: IPassengerSpecialsProps) => {
  const wheelchairs = [WCHR, WCHC, WCHS].some(
    (flag) => flag !== INITIAL_STATE
  ) && (
    <>
      <span>{WCHR ?? EMPTY_STATE}&nbsp;</span>
      <span className={wheelchairClassNames}>
        {PASSENGER_SPECIALS.WCHR}&nbsp;
      </span>
      <span>/&nbsp;{WCHC ?? EMPTY_STATE}&nbsp;</span>
      <span className={wheelchairClassNames}>
        {PASSENGER_SPECIALS.WCHC}&nbsp;
      </span>
      <span>/&nbsp;{WCHS ?? EMPTY_STATE}&nbsp;</span>
      <span className={wheelchairClassNames}>
        {PASSENGER_SPECIALS.WCHS}&nbsp;
      </span>
    </>
  );

  return (
    <div className={containerClassNames} {...others}>
      <div className={specialLoadsClassNames}>{PASSENGER_SPECIALS.TITLE}</div>
      <Title className="mb-[22px]" title={PASSENGER_SPECIALS.WHEELCHAIRS}>
        <div className={subtitleClassNames}>{wheelchairs}</div>
      </Title>
      <Grid col={[2, 0, 24]}>
        <Title title={PASSENGER_SPECIALS.STANDBY}>
          <div className={subtitleClassNames}>{StandBy ?? EMPTY_STATE}</div>
        </Title>
        <Title title={PASSENGER_SPECIALS.DEPA}>
          <div className={subtitleClassNames}>{DEPA ?? EMPTY_STATE}</div>
        </Title>
        <Title title={PASSENGER_SPECIALS.DEPU}>
          <div className={subtitleClassNames}>{DEPU ?? EMPTY_STATE}</div>
        </Title>
        <Title title={PASSENGER_SPECIALS.MEDA_MEQT}>
          <div className={subtitleClassNames}>{MEDA_MEQT ?? EMPTY_STATE}</div>
        </Title>
        <Title title={PASSENGER_SPECIALS.STRC}>
          <div className={subtitleClassNames}>{STRC ?? EMPTY_STATE}</div>
        </Title>
        <Title title={PASSENGER_SPECIALS.UMNR}>
          <div className={subtitleClassNames}>{UMNR ?? EMPTY_STATE}</div>
        </Title>
      </Grid>
    </div>
  );
};

export default PassengerSpecials;
