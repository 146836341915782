import { gql } from '@apollo/client';

export const ADD_MY_FLIGHTS = gql`
  mutation AddMyFlight($input: MyFlightsAddRemoveInput!) {
    addMyFlight(myFlights: $input) {
      flights
      errors {
        code
        message
      }
    }
  }
`;

export const UPDATE_MY_FLIGHTS = gql`
  mutation UpdateMyFlight($myFlights: MyFlightsUpdateInput!) {
    updateMyFlight(myFlights: $myFlights) {
      errors {
        code
        message
        description
      }
      updatedFlights {
        flightId
        notificationMuted
        isCheckedIn
        isFavourite
        checkedInRole
        checkedInRoles {
          name
          checkedInUsers {
            userId
            phone
            technicalRole
          }
        }
        role {
          roleGroup
          name
          technicalRole
          carrier
          permissions
          _id
        }
      }
    }
  }
`;

export const REMOVE_MY_FLIGHTS = gql`
  mutation RemoveMyFlight($input: MyFlightsAddRemoveInput!) {
    removeMyFlight(myFlights: $input) {
      removedFlights
      success
    }
  }
`;
