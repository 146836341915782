import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { headerReducer } from './reducers/headerReducer';
import { baseApi } from './api/base/base-api';
import authReducer from './reducers/authReducer';
import reloadReducer from './reducers/reloadReducer';
import notificationReducer from './reducers/notificationReducer';
import settingsReducer from './reducers/settingsReducer';
import searchReducer from './reducers/searchReducer';
import mqttReducer from './reducers/mqttReducer';
import newsfeedReducer from './reducers/newsfeedReducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import checkinReducer from './reducers/checkinReducer';
import chatReducer from './reducers/chatReducer';
import boardingTimeReducer from './reducers/boardingTimeReducer';
import telephonyReducer from './reducers/telephoneBookReducer';
import etdSettingsReducer from './reducers/etdSettingsReducer';
import overlayReducer from './reducers/overlayReducer';
import briefingPackageReducer from './reducers/briefingPackageReducer';

const rootReducer = combineReducers({
  //static data reducer
  headerReducer: headerReducer.reducer,
  settingsReducer: settingsReducer,
  authReducer: authReducer,
  reloadReducer: reloadReducer,
  notificationReducer: notificationReducer,
  searchReducer: searchReducer,
  mqttReducer: mqttReducer,
  newsfeedReducer: newsfeedReducer,
  checkinReducer: checkinReducer,
  chatReducer: chatReducer,
  boardingTimeReducer: boardingTimeReducer,
  telephonyReducer: telephonyReducer,
  etdSettingsReducer: etdSettingsReducer,
  briefingPackageReducer: briefingPackageReducer,
  overlayReducer: overlayReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'authReducer',
    'settingsReducer',
    'searchReducer',
    'mqttReducer',
    'checkinReducer',
    'chatReducer',
    'telephony',
    'briefingReducer',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    //combine middleware from API and actions, if needed, add ,newAPI.middleware
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
