import React from 'react';
import { useSelector } from 'react-redux';
import MenuHeader from '../MenuHeader/MenuHeader';
import MenuHeaderFlight from '../MenuHeaderFlight/MenuHeaderFlight';
import MainNavMobile from '../../MainNav/MainNavMobile';
import useCountdown from '../../../../utils/hooks/useCountdown';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setIsMenuOpen } from '../../../../redux/reducers/headerReducer';
import { RootState } from '../../../../redux/store';
import {
  IUserNotification,
  selectAllNotifications,
} from '../../../../redux/reducers/newsfeedReducer';
import {
  OverlayModule,
  setOpenOverlay,
} from '../../../../redux/reducers/overlayReducer';
import { usePreventHistoryChange } from '../../../../utils/hooks/usePreventHistoryChange';

const HeaderContainer = () => {
  const dispatch = useDispatch();
  const {
    headerTypeMobile,
    hasBackArrow,
    themeColor,
    title,
    flightName,
    bestTimeLogic,
    departureAirport,
    arrivalAirport,
    type,
    registration,
    isMenuOpen,
    isCheckedIn,
  } = useSelector((state: RootState) => state.headerReducer);

  usePreventHistoryChange(isMenuOpen, () => dispatch(setIsMenuOpen(false)));

  const timeLeft = useCountdown(bestTimeLogic?.timeLeft);

  const navigationClassNames = classNames({
    hidden: !isMenuOpen,
    block: isMenuOpen,
  });
  const headerClassNames = classNames({
    hidden: isMenuOpen,
    block: !isMenuOpen,
  });

  const setMenuOpen = (value: boolean) => () => {
    dispatch(setIsMenuOpen(value));
  };
  const handleNotificationClick = () =>
    dispatch(setOpenOverlay(OverlayModule.NOTIFICATIONS));

  const handleChatClick = () => dispatch(setOpenOverlay(OverlayModule.CHAT));

  const handleTelephonyClick = () => {
    dispatch(setIsMenuOpen(false));
    dispatch(setOpenOverlay(OverlayModule.TELEPHONY));
  };
  const allNotifications: IUserNotification[] = useSelector(
    selectAllNotifications
  );

  const notificationCount = Object.values(allNotifications || [])
    .flat()
    .filter((arr) => !arr.read).length;

  return (
    <div className="tablet:hidden laptop:hidden desktop:hidden">
      <div className={navigationClassNames}>
        <MainNavMobile
          onClose={setMenuOpen(false)}
          handleNotificationClick={handleNotificationClick}
          notificationCount={notificationCount}
          handleChatClick={handleChatClick}
          handleTelephonyClick={handleTelephonyClick}
        />
      </div>
      <div className={headerClassNames}>
        {headerTypeMobile === 'simple' ? (
          <MenuHeader
            themeColor={themeColor}
            hasBackArrow={hasBackArrow}
            title={title}
            onMenuClick={setMenuOpen(true)}
          />
        ) : (
          <MenuHeaderFlight
            flightName={flightName}
            time={timeLeft}
            departureAirport={departureAirport}
            arrivalAirport={arrivalAirport}
            type={type}
            registration={registration}
            onMenuClick={setMenuOpen(true)}
            themeColor={themeColor}
            isCheckedIn={isCheckedIn}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderContainer;
