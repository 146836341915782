import mqtt from 'mqtt';
import { encodeUriComponent, fetchApi } from '../helpers';

enum HandlerStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  PENDING = 'pending',
}

export enum ParentTopic {
  LUFTHANSA = 'LUFTHANSA',
  TAC = 'TAC',
}

export type IOnMessage = (topic: string, message: string) => void;

interface ISubscription {
  topic: string;
  onMessage: IOnMessage;
}

interface IPublishMessage {
  topic: string;
  message: string;
}

class MessagesHandler {
  private mqttClient: mqtt.MqttClient | null = null;
  private host = process.env.REACT_APP_TAC_NOTIFICATIONS_HOST ?? '';
  private port = process.env.REACT_APP_TAC_NOTIFICATIONS_PORT ?? '';
  private username = process.env.REACT_APP_TAC_NOTIFICATIONS_USER ?? '';
  private status = HandlerStatus.DISCONNECTED;
  private pendingSubscriptions: ISubscription[] = [];
  private activeSubscriptions: ISubscription[] = [];
  private pendingPublishes: IPublishMessage[] = [];

  async authenticate(clientId: string): Promise<string | null> {
    const authUrl = process.env.REACT_APP_TAC_NOTIFICATIONS_AUTH_URL ?? '';
    const details = {
      grant_type: process.env.REACT_APP_TAC_NOTIFICATIONS_GRANT ?? '',
      client_id: process.env.REACT_APP_TAC_NOTIFICATIONS_TOKEN_CLIENT_ID ?? '',
      client_secret:
        process.env.REACT_APP_TAC_NOTIFICATIONS_CLIENT_SECRET ?? '',
    };
    const formBody = encodeUriComponent(details);
    try {
      const result = await fetchApi(
        `${authUrl}internal/notifications/oauth/token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formBody.toString(),
        }
      );

      if (result && result?.access_token) {
        const jwtUrl = `${
          authUrl ?? ''
        }notifications/credentials/JWT/${clientId}/${
          process.env.REACT_APP_TAC_NOTIFICATIONS_PERMISSIONS ?? ''
        }`;
        const headers = {
          Authorization: `Bearer ${result.access_token}`,
        };

        const jwtResult = await fetchApi(jwtUrl, {
          method: 'POST',
          headers,
        });

        return (
          jwtResult?.CertificateManagementResource?.CertificateManagement
            ?.javaWebToken ?? null
        );
      }

      return null;
    } catch (err) {
      console.error(`Could not authenticate for notifications JWT:`, err);

      return null;
    }
  }

  getActiveSubscriptions() {
    return this.activeSubscriptions;
  }

  subscribe(subscription: ISubscription) {
    const parent =
      process.env.REACT_APP_TAC_NOTIFICATIONS_TAC_PARENT_TOPIC ?? '';
    const alreadySubscribed = this.activeSubscriptions.find(
      (existingSubscription) =>
        existingSubscription.topic === subscription.topic
    );
    // mqtt subscriptions
    if (
      this.mqttClient &&
      this.status === HandlerStatus.CONNECTED &&
      !alreadySubscribed
    ) {
      this.mqttClient.subscribe(parent + subscription.topic, {
        qos: Number(process.env.REACT_APP_TAC_NOTIFICATIONS_QOS ?? 1) as
          | 0
          | 1
          | 2,
      });
      if (
        process?.env?.REACT_APP_TAC_NOTIFICATIONS_DEBUG_ENABLED?.toUpperCase() ===
        'TRUE'
      ) {
        console.log(`Subscribed to topic ${parent + subscription.topic}`);
      }

      this.activeSubscriptions.push(subscription);
    } else {
      this.pendingSubscriptions.push(subscription);
    }
  }

  unsubscribe(topic: string) {
    const parent =
      process.env.REACT_APP_TAC_NOTIFICATIONS_TAC_PARENT_TOPIC ?? '';
    if (this.mqttClient) {
      this.mqttClient.unsubscribe(parent + topic);
      if (
        process?.env?.REACT_APP_TAC_NOTIFICATIONS_DEBUG_ENABLED?.toUpperCase() ===
        'TRUE'
      ) {
        console.log(`Unsubscribed from topic ${parent + topic}`);
      }

      const activeSubscriptionIndex = this.activeSubscriptions.findIndex(
        (subscription) => subscription.topic === `${topic}`
      );
      if (activeSubscriptionIndex) {
        this.activeSubscriptions.splice(activeSubscriptionIndex, 1);
      }
    }
  }

  async connect(password: string, clientId: string, onReconnect?: () => void) {
    try {
      this.mqttClient = mqtt.connect(
        `wss:${this.host}:${this.port}/mqtt?jwt=${password}`,
        {
          keepalive: 60,
          clean: true,
          reconnectPeriod: 3000,
          connectTimeout: 30 * 1000,
          username: this.username,
          clientId: clientId,
          rejectUnauthorized: false,
          password: password,
        }
      );

      this.status = HandlerStatus.PENDING;

      if (this.mqttClient) {
        // Mqtt error calback
        this.mqttClient.on('error', (err: Error) => {
          console.error(err);

          this.mqttClient && this.mqttClient.end();
        });

        // Connection callback
        this.mqttClient.on('connect', () => {
          if (
            process?.env?.REACT_APP_TAC_NOTIFICATIONS_DEBUG_ENABLED?.toUpperCase() ===
            'TRUE'
          ) {
            console.log(
              `mqtt client connected to ${this.host} on port ${this.port}`
            );
          } else {
            console.log('mqtt client connected');
          }
          this.status = HandlerStatus.CONNECTED;

          if (this.pendingSubscriptions) {
            this.pendingSubscriptions.forEach((subscription) => {
              this.subscribe(subscription);
            });
            this.pendingSubscriptions = [];
          }

          if (this.pendingPublishes) {
            this.pendingPublishes.forEach((publishMessage) => {
              this.publishMessage(publishMessage);
            });
          }
        });

        // When a message arrives, console.log it
        this.mqttClient.on(
          'message',
          async (topic: string, message: Buffer) => {
            if (
              process?.env?.REACT_APP_TAC_NOTIFICATIONS_DEBUG_ENABLED === 'TRUE'
            ) {
              console.log(`[${topic}] ${message.toString()}`);
            }
            const subscription = this.activeSubscriptions.find((subscription) =>
              topic.includes(subscription.topic)
            );

            if (subscription) {
              subscription.onMessage(topic, message.toString());
            }
          }
        );

        this.mqttClient.on('reconnect', () => {
          console.log('mqtt client reconnected');
          onReconnect && onReconnect();
        });

        this.mqttClient.on('close', () => {
          this.pendingSubscriptions = this.pendingSubscriptions.concat(
            this.activeSubscriptions
          );
          this.activeSubscriptions = [];
          console.log(`mqtt client disconnected`);
          this.status = HandlerStatus.DISCONNECTED;
        });
      }
    } catch (err) {
      console.error('Could not connect to mqtt!');
      console.error(err);
    }
  }

  publishMessage(publishMessage: IPublishMessage) {
    const parent =
      process.env.REACT_APP_TAC_NOTIFICATIONS_TAC_PARENT_TOPIC ?? '';

    if (this.mqttClient && this.status === HandlerStatus.CONNECTED) {
      this.mqttClient.publish(
        parent + publishMessage.topic,
        publishMessage.message,
        {
          qos: Number(process.env.REACT_APP_TAC_NOTIFICATIONS_QOS ?? 1) as
            | 0
            | 1
            | 2,
        }
      );
    } else {
      this.pendingPublishes.push(publishMessage);
    }
  }

  close() {
    if (this.mqttClient) {
      this.mqttClient.end();
    }
  }
}

export default MessagesHandler;
