import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveChatScreen,
  setActiveFlightIdScreen,
} from '../../redux/reducers/newsfeedReducer';
import AllChats from './AllChats/AllChats';
import FlightChatContainer from './FlightChat/FlightChatContainer';

interface IChatsContainer {
  handleCloseChat: () => void;
}

const ChatsContainer = ({ handleCloseChat }: IChatsContainer) => {
  const activeChatScreen = useSelector(selectActiveChatScreen);
  const dispatch = useDispatch();

  const handleChatSelect = (flight: string | null) =>
    dispatch(setActiveFlightIdScreen(flight));

  return (
    <>
      {activeChatScreen ? (
        <FlightChatContainer
          handleCloseChat={handleCloseChat}
          handleBack={() => handleChatSelect(null)}
          flightId={activeChatScreen}
        />
      ) : (
        <AllChats
          handleCloseChat={handleCloseChat}
          onChatSelect={handleChatSelect}
        />
      )}
    </>
  );
};

export default ChatsContainer;
