import { EMPTY_STATE } from './constants';
import { ITimesObject } from './types';

export const calculateTime = (
  isUTC: boolean,
  is24Format: boolean,
  timesObject?: ITimesObject | null,
  emptyState?: string
) => {
  const customEmptyState = emptyState ?? EMPTY_STATE;
  if (isUTC) {
    return is24Format
      ? timesObject?.bestTimeUTC ?? customEmptyState
      : timesObject?.bestTimeUTC12format ?? customEmptyState;
  } else {
    return is24Format
      ? timesObject?.bestTimeLocal ?? customEmptyState
      : timesObject?.bestTimeLocal12format ?? customEmptyState;
  }
};
