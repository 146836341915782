import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { OFF, ON } from '../../../utils/constants';
import { capitalizeFirstLetter } from '../../../utils/helpers';

interface INotifications {
  notificationState: string;
  notificationType: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Notifications = ({
  notificationState,
  notificationType,
  handleChange,
  disabled,
}: INotifications) => {
  const defaultClassNames = classNames(
    'font-head-bold text-primary text-14 leading-[18px] border-1 border-primary w-full py-13 flex justify-center dark:text-grey-12 dark:border-grey-12',
    { 'opacity-50': disabled }
  );
  const selectedClassNames = classNames(
    'bg-primary text-white1 dark:bg-grey-12 dark:text-grey-90',
    { 'opacity-50': disabled }
  );
  const notSelectedClassNames = classNames('bg-white dark:bg-grey-90', {
    'opacity-50': disabled,
  });

  const getLabelClassNames = (option: string, extraClassNames: string) => {
    return classNames(defaultClassNames, extraClassNames, {
      [selectedClassNames]: option === notificationState,
      [notSelectedClassNames]: option !== notificationState,
    });
  };

  return (
    <div className="flex">
      <input
        type="radio"
        id={`${notificationType}-${ON}`}
        name={notificationType}
        className="appearance-none hidden"
        checked={notificationState === ON}
        onChange={(e) => handleChange(e)}
        value={(notificationState === ON).toString()}
        disabled={disabled}
      />
      <label
        htmlFor={`${notificationType}-${ON}`}
        className={getLabelClassNames(ON, 'rounded-l-4')}>
        {capitalizeFirstLetter(ON)}
      </label>
      <input
        type="radio"
        id={`${notificationType}-${OFF}`}
        name={notificationType}
        className="appearance-none hidden"
        checked={notificationState === OFF}
        onChange={(e) => handleChange(e)}
        value={(notificationState === OFF).toString()}
        disabled={disabled}
      />
      <label
        htmlFor={`${notificationType}-${OFF}`}
        className={getLabelClassNames(OFF, 'rounded-r-4')}>
        {capitalizeFirstLetter(OFF)}
      </label>
    </div>
  );
};

export default Notifications;
