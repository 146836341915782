import React from 'react';
import Badge from '../../Badge/Badge';
import Gauge from '../../Gauge/Gauge';
import Icon, { IconVariant } from '../../Icon/Icon';
import classNames from 'classnames';
import { INITIAL_STATE } from '../../../utils/constants';

const titleClass =
  'font-body-text text-12 text-grey-40 self-start mt-32 ml-32 mb-[26px]';
const tagListClass =
  'm-auto flex flex-row flex-wrap max-w-[292px] justify-center gap-8 p-5 ';
const tagTextClass = 'font-body-text dark:text-grey-12 text-primary';
const tagClass = 'dark:bg-grey-100 bg-grey-6 max-w-[200px]';
const iconClass = 'dark:fill-grey-12 fill-primary';
const additionalBadgeClass = 'dark:text-grey-12 text-primary';

interface IGaugeContainer {
  value?: number | string;
  min?: number;
  max?: number | string;
  status?: string;
  label: string;
  tagArray?: {
    isWarning: boolean;
    title: string;
  }[];
  title: string;
  tagsShown?: number;
  className?: string;
  gaugeClassName?: string;
  titleClassName?: string;
  handleOnClick?: () => void;
  isLoading: boolean;
  iconVariant?: IconVariant;
}

const GaugeContainer = ({
  value = INITIAL_STATE,
  min = 0,
  max = INITIAL_STATE,
  status = 'DEFAULT',
  label,
  tagArray = [],
  title,
  tagsShown = 5,
  className,
  gaugeClassName,
  titleClassName = titleClass,
  handleOnClick,
  isLoading,
  iconVariant,
}: IGaugeContainer) => {
  const warningIcon = (
    <Icon width={11} height={9} variant="alert" className={iconClass} />
  );
  const infoIcon = (
    <Icon width={12} height={12} variant="info" className={iconClass} />
  );
  const gaugeContainerClass = classNames(
    'z-[1] flex flex-col items-center dark:bg-grey-90 bg-white rounded-8  w-[350px] h-[450px] -z-[1]',
    className,
    {
      'cursor-pointer mobile:w-full': handleOnClick,
    }
  );
  return (
    <div onClick={handleOnClick} className={gaugeContainerClass}>
      <div className="flex w-full relative">
        <p className={titleClassName}>{title}</p>
        {iconVariant && (
          <Icon
            variant={iconVariant}
            className="absolute top-0 right-24 fill-primary dark:fill-grey-12"
            width={16}
            height={16}
          />
        )}
      </div>
      <Gauge
        value={value}
        max={max}
        min={min}
        label={label}
        status={status}
        className={gaugeClassName}
        isLoading={isLoading}
        iconVariant={iconVariant}
      />
      <div className={tagListClass}>
        {tagArray.map((item, index) =>
          index >= tagsShown ? null : (
            <Badge
              key={item.title}
              Icon={item.isWarning ? warningIcon : infoIcon}
              text={item.title}
              textClassName={tagTextClass}
              className={tagClass}
            />
          )
        )}
        {tagArray.length > tagsShown && (
          <Badge
            text={`+${tagArray.length - tagsShown}`}
            textClassName={additionalBadgeClass}
            className={tagClass}
          />
        )}
      </div>
    </div>
  );
};

export default GaugeContainer;
