import { useQuery } from '@apollo/client';
import {
  Query,
  InboundConnections,
  DataError,
  OutboundConnections,
} from '../generated/graphql';
import { IDataResponse } from '../types';
import {
  INBOUND_CONNECTIONS,
  OUTBOUND_CONNECTIONS,
} from '../../graphql/flightDetails/flight-details.queries';
import { CONNECTION_FLIGHT_TYPE } from '../constants';

interface IGetInOutboundConnections
  extends IDataResponse<InboundConnections | OutboundConnections | null> {
  refetchInOutboundConnections: () => Promise<
    InboundConnections | OutboundConnections | null
  >;
  loadingFirstTime: boolean;
  responseErrors: DataError[] | null;
}

export const useGetInOutboundConnections = (
  flightId: string,
  connectionType: string
): IGetInOutboundConnections => {
  const isInbound = connectionType === CONNECTION_FLIGHT_TYPE.INBOUND;
  const queryType = isInbound ? 'inboundConnections' : 'outboundConnections';
  const graphqlRequest = isInbound ? INBOUND_CONNECTIONS : OUTBOUND_CONNECTIONS;

  const { data, loading, error, networkStatus, refetch } = useQuery<
    Pick<Query, typeof queryType>
  >(graphqlRequest, {
    fetchPolicy: 'cache-and-network',
    variables: {
      flightId,
    },
  });

  const refetchInOutboundConnections = async () => {
    const result = await refetch();

    return (
      result?.data?.inboundConnections?.data ??
      result?.data?.outboundConnections?.data ??
      null
    );
  };

  const convertedErrors =
    data?.inboundConnections?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ??
    data?.outboundConnections?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ??
    null;

  return {
    data:
      data?.inboundConnections?.data ?? data?.outboundConnections?.data ?? null,
    refetchInOutboundConnections,
    loading,
    error,
    responseErrors: convertedErrors,
    loadingFirstTime: networkStatus === 1,
  };
};
