import React, { Dispatch, SetStateAction } from 'react';
import {
  DIRECT_CALL,
  GET_CONTACTS,
  SEARCH_TELEPHONY_PLACEHOLDER,
} from '../../utils/constants';
import Button from '../Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import useCheckTechnicalRole from '../../utils/hooks/useCheckRolePermissions';

interface ITelephonySearch {
  searchValue: string;
  loading: boolean;
  onInputChange: Dispatch<SetStateAction<string>>;
  onSearchButtonClick: Dispatch<SetStateAction<string>>;
}
const inputClassName =
  'w-2/4 p-4 py-12 pl-14 mobile:ml-0 mx-8 pr-32 w-full leading-[14px] bg-white rounded-4 text-14 focus:outline-none font-body-text border-1 border-grey-25 focus:border-1 focus:border-primary dark:bg-grey-90 dark:border-grey-40 dark:text-grey-12 uppercase placeholder:normal-case';

const searchButtonText = 'font-head-bold font-[700] text-white';

const directClassHeader =
  'pl-24 text-grey-40 text-12 font-body-text dark:text-grey-12 ml-8 mobile:ml-0';

const searchContainerClassName =
  'flex-2 inset-x-0 bottom-0 bg-white pb-24 dark:bg-grey-90';
const borderClassName = 'flex-1 border-t-1 border-grey-12';
export const TelephonySearch = ({
  searchValue,
  loading,
  onInputChange,
  onSearchButtonClick,
}: ITelephonySearch) => {
  const searchButton = classNames(
    'flex w-2/4 p-4 justify-center items-center h-[45px]  rounded-4 ',
    { 'bg-grey-12': !searchValue.length },
    { 'bg-primary': searchValue.length }
  );
  const isOffline = !useNetworkStatus();
  const isCockpit = useCheckTechnicalRole('TAC_LH_COCKPIT');

  return (
    <div className={searchContainerClassName}>
      <div className={directClassHeader}>{DIRECT_CALL}</div>
      <div className={borderClassName}></div>
      <div className="flex px-24 pt-10">
        <input
          className={inputClassName}
          value={searchValue}
          placeholder={SEARCH_TELEPHONY_PLACEHOLDER}
          onChange={(e) => onInputChange(e.target.value)}
          disabled={isOffline && !isCockpit}
        />
        <Button
          onClick={() => onSearchButtonClick(searchValue)}
          className={searchButton}
          textClassName={searchButtonText}
          text={
            loading ? <LoadingSpinner width={30} height={30} /> : GET_CONTACTS
          }
          disabled={!searchValue.length}
        />
      </div>
    </div>
  );
};
