import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '../fragments';

export const CHAT_MARK_AS_READ = gql`
  mutation MarkChatAsRead($input: MarkAsReadInput!) {
    markChatAsRead(input: $input) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
