import { jwtDecode } from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import {
  capitalizeFirstLetter,
  encodeUriComponent,
  generateVerifier,
  getIdToken,
  hasBaseRole,
} from '../../utils/helpers';
import StorageUtil from '../../utils/StorageUtil';
import { addError } from './notificationReducer';
import { ERRORS, TOKEN_SETTINGS_FIELDS } from '../../utils/constants';
import { AppDispatch, RootState } from '../store';
import { generateChallenge } from '../../utils/helpers';
import UtilFactory from '../../factory/UtilFactory';

interface RefreshDetails {
  grant_type: string;
  refresh_token: string;
}

interface AuthorizationDetails {
  grant_type: string;
  client_id: string;
  code: string;
  code_verifier: string;
  redirect_uri: string;
}

type IDetails = RefreshDetails | AuthorizationDetails;

interface JwtPayload {
  sub: string;
  'dlh.upn': string;
  phone_number: string;
  given_name: string;
  family_name: string;
  'dlh.ad_groups': string;
}

const initialState = {
  userId: 'U000000',
  tokenEmail: '',
  tokenName: '',
  tokenFirstName: '',
  tokenPhone: '',
  tokenCreatedDate: null,
  expiresIn: null,
  isLoading: false,
  error: null,
  tokenRoles: [],
  isFirstLogin: true,
  clearCache: () => {},
  isLoggedIn: false,
};

const requestAccessToken = async (details: IDetails) => {
  let formBody = encodeUriComponent(details);

  return await fetch(
    process.env.REACT_APP_TAC_AUTHORIZATION_ID_TOKEN_URL ?? '',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization:
          'Basic ' +
          btoa(
            process.env.REACT_APP_TAC_AUTHORIZATION_CLIENT_ID +
              ':' +
              process.env.REACT_APP_TAC_AUTHORIZATION_CLIENT_SECRET
          ),
      },
      body: formBody.toString(),
    }
  );
};

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
        isLoggedIn: false,
      };
    },
    loginSuccess(state, action) {
      const payload = action.payload;
      const decodedPayload = payload['id_token']
        ? (jwtDecode(payload['id_token']) as JwtPayload)
        : null;
      StorageUtil.set('codeVerifier', generateVerifier());

      return {
        ...state,
        isLoading: false,
        tokenCreatedDate: Date.now(),
        expiresIn: payload['expires_in'],
        userId:
          payload['id_token'] && decodedPayload
            ? TOKEN_SETTINGS_FIELDS.USERID in decodedPayload
              ? decodedPayload[TOKEN_SETTINGS_FIELDS.USERID]
              : ''
            : initialState.userId,
        tokenName:
          payload['id_token'] && decodedPayload
            ? TOKEN_SETTINGS_FIELDS.NAME in decodedPayload
              ? capitalizeFirstLetter(
                  decodedPayload[TOKEN_SETTINGS_FIELDS.NAME]
                )
              : ''
            : initialState.tokenName,
        tokenPhone:
          payload['id_token'] && decodedPayload
            ? TOKEN_SETTINGS_FIELDS.PHONE in decodedPayload
              ? capitalizeFirstLetter(
                  decodedPayload[TOKEN_SETTINGS_FIELDS.PHONE]?.replace(
                    /\s/g,
                    ''
                  )
                )
              : ''
            : initialState.tokenPhone,
        tokenFirstName:
          payload['id_token'] && decodedPayload
            ? TOKEN_SETTINGS_FIELDS.FIRSTNAME in decodedPayload
              ? capitalizeFirstLetter(
                  decodedPayload[TOKEN_SETTINGS_FIELDS.FIRSTNAME]
                )
              : ''
            : initialState.tokenFirstName,
        tokenEmail:
          payload['id_token'] && decodedPayload
            ? TOKEN_SETTINGS_FIELDS.EMAIL in decodedPayload
              ? decodedPayload[TOKEN_SETTINGS_FIELDS.EMAIL]
              : ''
            : initialState.tokenEmail,
        tokenRoles:
          payload['id_token'] &&
          decodedPayload &&
          'dlh.ad_groups' in decodedPayload
            ? decodedPayload['dlh.ad_groups']
            : initialState.tokenRoles,
        isLoggedIn: true,
      };
    },

    loginFailure(state, action) {
      StorageUtil.clear();
      state.clearCache?.();

      setTimeout(() => {
        window.location.href = '/';
      }, 3000);

      StorageUtil.set('codeVerifier', generateVerifier());

      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    logoutStart(state) {
      return {
        ...state,
        error: null,
        isLoggedIn: true,
      };
    },
    logoutSuccess(state) {
      StorageUtil.remove('access_token');
      StorageUtil.remove('user_id');
      StorageUtil.remove('id_token');

      return {
        ...state,
        isLoading: false,
        userId: null,
        tokenCreatedDate: null,
        expiresIn: null,
      };
    },
    logoutFailure(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    setIsFirstLogin: (state, action) => {
      state.isFirstLogin = action.payload;
    },
    setClearCache: (state, action) => {
      state.clearCache = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutStart,
  logoutSuccess,
  logoutFailure,
  setIsFirstLogin,
  setClearCache,
} = authReducer.actions;

export const selectUserId = (state: RootState) => state.authReducer.userId;
export const selectIsLoggedIn = (state: RootState) =>
  state.authReducer.isLoggedIn;
export const selectAuthReducer = (state: RootState) => state.authReducer;

export const selectIsLoginLoading = (state: RootState) =>
  state.authReducer.isLoading;
export const selectTokenCreatedDate = (state: RootState) =>
  state.authReducer.tokenCreatedDate;
export const selectIsFirstLogin = (state: RootState) =>
  state.authReducer.isFirstLogin;
export const selectError = (state: RootState) => state.authReducer.error;

export const login =
  ({ code, codeVerifier }) =>
  async (dispatch: AppDispatch) => {
    dispatch(loginStart());

    const details = {
      grant_type: process.env.REACT_APP_TAC_AUTHORIZATION_GRANT_TYPE ?? '',
      client_id: process.env.REACT_APP_TAC_AUTHORIZATION_CLIENT_ID ?? '',
      code: code,
      code_verifier: codeVerifier,
      redirect_uri:
        window.location.origin ??
        process.env.REACT_APP_TAC_AUTHORIZATION_REDIRECT_URL ??
        '',
    };

    try {
      const response = await requestAccessToken(details);

      if (response.ok) {
        const tokenObject = await response.json();

        if (tokenObject?.access_token) {
          const jwtDecoded = tokenObject?.id_token
            ? jwtDecode(tokenObject.id_token)
            : null;
          const decodedPayloadRoles = jwtDecoded
            ? jwtDecoded['dlh.ad_groups']
            : null;
          const decodedUserId =
            jwtDecoded && TOKEN_SETTINGS_FIELDS.USERID in jwtDecoded
              ? jwtDecoded[TOKEN_SETTINGS_FIELDS.USERID]
              : '';

          if (
            !hasBaseRole(process.env.REACT_APP_TAC_APP_ENV, decodedPayloadRoles)
          ) {
            dispatch(loginFailure('Login not authorized'));
            dispatch(addError(ERRORS.NOT_AUTHORIZED));
            return;
          }

          StorageUtil.set('access_token', tokenObject?.access_token);
          StorageUtil.set('id_token', tokenObject?.id_token);
          StorageUtil.set('is_token_refreshing', 'false');
          StorageUtil.set('refresh_token', tokenObject?.refresh_token);
          StorageUtil.set('user_id', decodedUserId);

          dispatch(loginSuccess(tokenObject));
        } else {
          console.error('No access token!');
          dispatch(loginFailure('Login failed!'));
          dispatch(addError(ERRORS.COULD_NOT_LOGIN));
        }
      } else {
        console.error('Invalid response for token');
        dispatch(loginFailure('Login failed!'));
        dispatch(addError(ERRORS.COULD_NOT_LOGIN));
      }
    } catch (error) {
      console.error(error);
      dispatch(loginFailure(error.message));
      dispatch(addError(ERRORS.COULD_NOT_LOGIN));
    }
  };

export const refreshToken =
  ({ rToken }) =>
  async (dispatch: AppDispatch) => {
    StorageUtil.set('is_token_refreshing', 'true');

    const details = {
      grant_type:
        process.env.REACT_APP_TAC_AUTHORIZATION_REFRESH_GRANT_TYPE ?? '',
      refresh_token: rToken,
    };

    try {
      const response = await requestAccessToken(details);

      if (response.ok) {
        const data = await response.json();
        StorageUtil.set('access_token', data?.access_token);
        StorageUtil.set('id_token', data?.id_token);
        StorageUtil.set('is_token_refreshing', 'false');
        StorageUtil.set('refresh_token', '');
      } else {
        dispatch(logout());
      }
    } catch (error) {
      dispatch(logout());
    }
  };

export const logout =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const newCodeVerifier = generateVerifier();

    const details = {
      idToken: getIdToken() || '',
      responseType: process.env.REACT_APP_TAC_AUTHORIZATION_RESPONSE_TYPE,
      clientId: process.env.REACT_APP_TAC_AUTHORIZATION_CLIENT_ID,
      codeChallenge: await generateChallenge(newCodeVerifier),
      redirectUri:
        window.location.origin ??
        process.env.REACT_APP_TAC_AUTHORIZATION_REDIRECT_URL,
      scope: process.env.REACT_APP_TAC_AUTHORIZATION_SCOPE,
    };

    try {
      UtilFactory.newMessageHandler().close();

      StorageUtil.clear();
      StorageUtil.set('codeVerifier', newCodeVerifier);

      getState().authReducer.clearCache?.();

      window.location.href = `${process.env.REACT_APP_TAC_LOGOUT_URL}?response_type=${details.responseType}&client_id=${details.clientId}&code_challenge=${details.codeChallenge}&code_challenge_method=S256&redirect_uri=${details.redirectUri}&scope=${details.scope}`;
    } catch (error) {
      dispatch(logoutFailure(error.message));
    }
  };

export default authReducer.reducer;
