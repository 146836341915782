import { useQuery } from '@apollo/client';
import { Query, CacheTelephonyEntry } from '../generated/graphql';
import { IDataResponse } from '../types';
import { TELEPHONY_BOOKS } from '../../graphql/telephony/telephony.queries';
import { isUserLoggedIn } from '../helpers';

export const useGetTelephonyBook = (
  stationsList?: String[]
): IDataResponse<CacheTelephonyEntry[] | []> => {
  const isLoggedIn = isUserLoggedIn();
  const { data, loading, error } = useQuery<Pick<Query, 'cachedTelephonyBook'>>(
    TELEPHONY_BOOKS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        stationsList,
      },
      skip: !isLoggedIn,
    }
  );

  return {
    data: data?.cachedTelephonyBook ?? null,
    loading,
    error,
  };
};
