import { useDispatch } from 'react-redux';
import { getApolloLinks } from '../getApolloLinks';
import { useCallback, useEffect, useState } from 'react';
import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

import { AppDispatch } from '../../redux/store';
import { setClearCache } from '../../redux/reducers/authReducer';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

export const useApollo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      const link = getApolloLinks(dispatch, cache);

      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write',
      });

      await newPersistor.restore();
      setPersistor(newPersistor);

      setClient(
        new ApolloClient({
          link,
          cache,
          defaultOptions,
        })
      );
    }

    init().catch(console.error);
  }, [dispatch]);

  const clearCache = useCallback(() => {
    if (!persistor) {
      return;
    }
    persistor.purge();
  }, [persistor]);

  dispatch(setClearCache(clearCache));

  return client;
};
