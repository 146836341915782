import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Notification from './Notification/Notification';
import {
  MARK_AS_READ,
  NEWSFEED_COPY,
  NEWSFEED_NO_NOTIFICATIONS,
  NEWSFEED_SUBTITLE,
  NEWSFEED_TEXT,
  NEWSFEED_TITLE,
  NOTIFICATIONS,
} from '../../utils/constants';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import NotificationAccordion from './NotificationAccordion/NotificationAccordion';
import {
  selectAllNotifications,
  selectSortingCriteria,
  setSortingCriteria,
} from '../../redux/reducers/newsfeedReducer';
import Popover from '../Popover/Popover';
import {
  CachedMyFlight,
  FlightStatus,
  NotificationCategory,
  NotificationType,
  UserNotification,
} from '../../utils/generated/graphql';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import { useReadNotifications } from '../../utils/hooks/useReadNotifications';
import Title from '../Title/Title';

const headerItemClassNames =
  'text-primary font-body-text text-14 leading-[14px]';
const emptyListClassNames =
  'absolute top-1/3 left-0 w-full flex items-center justify-center font-head-light text-18 text-grey-40';

interface IHeaderButtonProps {
  name?: string;
  Icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const HeaderButton = ({
  name,
  Icon,
  className,
  onClick,
  disabled,
}: IHeaderButtonProps) => (
  <Button
    Icon={Icon}
    text={name}
    className={classNames(headerItemClassNames, className)}
    onClick={onClick}
    disabled={disabled}
  />
);

interface INewsfeedProps {
  notificationsList: UserNotification[][];
  isOverlay: boolean;
  handleCloseNotification?: () => void;
  isFlightSpecific?: boolean;
  notificationsDisabled?: boolean;
  myFlights: CachedMyFlight[];
}

interface IEmptyState {
  isOverlay: boolean;
}

const EmptyState = ({ isOverlay }: IEmptyState) => (
  <>
    {isOverlay && <hr className="border-gray-12"></hr>}
    <div className={emptyListClassNames}>{NEWSFEED_NO_NOTIFICATIONS}</div>
  </>
);

interface IHeaderButtons {
  handleMarkAsRead?: () => void;
}

const HeaderButtons = ({ handleMarkAsRead }: IHeaderButtons) => {
  const dispatch = useDispatch();
  const isOnline = useNetworkStatus();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const sortingCriteria = useSelector(selectSortingCriteria);

  const handleSortingTypeChange = (type: 'time' | 'departure') => {
    const newSortingType = `${type}-${type === 'time' ? 'ASC' : 'DSC'}`;
    dispatch(setSortingCriteria(newSortingType));
    setIsPopoverOpen(false);
  };
  return (
    <div className="px-32 pb-[29px] flex justify-between">
      <HeaderButton
        className={classNames('underline', { 'opacity-50': !isOnline })}
        name={MARK_AS_READ}
        onClick={() => handleMarkAsRead?.()}
        disabled={!isOnline}
      />
      <div className="flex underline">
        <HeaderButton
          Icon={
            <Icon
              width={10}
              height={10}
              variant="arrowUp"
              className={classNames(
                'dark:fill-white fill-primary mr-8 cursor-default',
                {
                  'transform rotate-180': sortingCriteria.includes('ASC'),
                }
              )}
            />
          }
        />
        <Popover
          buttonClass="flex flex-row items-center underline"
          buttonTitle={
            sortingCriteria.includes('departure') ? 'Departure' : 'Newest'
          }
          isOpen={isPopoverOpen}
          setIsOpen={() => setIsPopoverOpen((prev) => !prev)}>
          <div className="absolute top-[65px] right-[15px] flex flex-col justify-center items-center bg-white dark:bg-grey-90 h-[86px] w-[94px] border-1 border-primary dark:border-white rounded-4">
            <div className="flex flex-col items-end gap-y-5">
              <HeaderButton
                onClick={() => handleSortingTypeChange('time')}
                name="Newest"
                className={classNames('flex flex-row items-center', {
                  underline: sortingCriteria.includes('time'),
                })}
              />
              <HeaderButton
                onClick={() => handleSortingTypeChange('departure')}
                name="Departure"
                className={classNames('flex flex-row items-center', {
                  underline: !sortingCriteria.includes('time'),
                })}
              />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

const Newsfeed = ({
  notificationsList,
  isOverlay = false,
  handleCloseNotification,
  isFlightSpecific,
  notificationsDisabled,
  myFlights,
  ...others
}: INewsfeedProps) => {
  const newsfeedContainerClass = classNames('relative flex flex-col', {
    'mobile:hidden tablet:max-h-[1462px] laptop:max-h-[895px] laptopL:max-h-[803px] ':
      !isOverlay,
    'dark:bg-grey-90 h-screen': isOverlay,
  });
  const newsfeedTitleClassNames = classNames(
    'font-body-text text-12 text-grey-40',
    {
      'pt-24 pb-16 pl-3 ': !isOverlay,
      'pt-32 pb-32 pl-32 font-body-text flex justify-between': isOverlay,
    }
  );
  const listContainerClassNames = classNames('overflow-auto flex flex-col ', {
    'gap-y-16': !isOverlay,
  });

  const overlayTitleClass =
    'text-grey-40 font-semibold text-14 font-body-text dark:text-grey-12 uppercase';

  const getNotificationTopic = (notificationArray: UserNotification[]) => {
    const notification = notificationArray[0]?.notification;
    if (notification?.details?.flightId) {
      return notification.details.flightId;
    } else if (notification?.type === NotificationType.SYSTEM) {
      return notification.type;
    } else if (notification?.type === NotificationType.ROLE) {
      return notification.title;
    }
    return notification?.details?.station;
  };
  const readNotifications = useReadNotifications();
  const allNotifications = useSelector(selectAllNotifications);

  const handleMarkAllAsRead = () => {
    const notificationsToRead: string[] = notificationsList.map(
      (notificationArray) => getNotificationTopic(notificationArray) ?? ''
    );

    for (const notificationTopic of notificationsToRead) {
      readNotifications(allNotifications, notificationTopic);
    }
  };

  const getNotificationCenterTitle = () => {
    if (isFlightSpecific) {
      return (
        <Title title={NOTIFICATIONS} titleColorClass={overlayTitleClass} />
      );
    }
    const totalUnreadNotifications = notificationsList
      .flat()
      .filter((n) => !n.read).length;

    return totalUnreadNotifications ? (
      <Title
        title={`${totalUnreadNotifications} NEW NOTIFICATIONS`}
        titleColorClass={overlayTitleClass}
      />
    ) : (
      <Title title={NOTIFICATIONS} titleColorClass={overlayTitleClass} />
    );
  };

  const defaultNotification = {
    _id: '',
    title: NEWSFEED_TITLE,
    description: NEWSFEED_TEXT,
    subtitle: NEWSFEED_SUBTITLE,
    type: NotificationType.SYSTEM,
    category: NotificationCategory.UNKNOWN,
    details: {},
    createdAt: new Date().toISOString(),
  };

  return (
    <div className={newsfeedContainerClass}>
      <div className={newsfeedTitleClassNames}>
        {isOverlay ? getNotificationCenterTitle() : NEWSFEED_COPY}
        {isOverlay && (
          <Button
            onClick={handleCloseNotification}
            className="pr-32"
            Icon={
              <Icon
                variant="close"
                width={13}
                height={13}
                className="fill-grey-40"
              />
            }
          />
        )}
      </div>
      {isOverlay && !isFlightSpecific && (
        <HeaderButtons handleMarkAsRead={handleMarkAllAsRead} />
      )}
      {!!notificationsList.length ? (
        <div className={listContainerClassNames} {...others}>
          {notificationsDisabled ? (
            <Notification
              _id="defaultNotif"
              notification={defaultNotification}
              read={false}
              userId={''}
              createdAt=""
            />
          ) : (
            notificationsList.map((notification) => (
              <React.Fragment key={notification?.[0]?.notification.title ?? ''}>
                {isOverlay && <hr className="border-gray-12"></hr>}
                <NotificationAccordion
                  notificationsList={notification}
                  isFlightSpecific={isFlightSpecific ?? false}
                  isOverlay={isOverlay}
                  status={
                    myFlights?.find(
                      (flight) =>
                        flight.flightId ===
                        notification?.[0]?.notification.details.flightId
                    )?.flightStatus || FlightStatus.DEFAULT
                  }
                  isCheckedIn={
                    myFlights?.find(
                      (flight) =>
                        flight.flightId ===
                        notification?.[0]?.notification.details.flightId
                    )?.isCheckedIn || false
                  }
                />
              </React.Fragment>
            ))
          )}
          {isOverlay && !isFlightSpecific && (
            <hr className="border-gray-12"></hr>
          )}
        </div>
      ) : (
        <EmptyState isOverlay={isOverlay} />
      )}
    </div>
  );
};

export default Newsfeed;
