import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '../fragments';

export const CHECK_IN_FLIGHT = gql`
  mutation CheckInFlight($flight: CheckInFlightInput!) {
    checkInFlight(flight: $flight) {
      flight {
        _id
        userId
        flightId
        role {
          roleGroup
          name
          technicalRole
          carrier
          permissions
          _id
        }
      }
      checkedInRoles {
        name
        checkedInUsers {
          userId
          phone
          technicalRole
          userType
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHECK_OUT_FLIGHT = gql`
  mutation CheckOutFlight($flight: CheckOutFlightInput!) {
    checkOutFlight(flight: $flight) {
      success
    }
  }
`;
