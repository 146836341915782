import moment from 'moment';
import { MainNavIconVariant } from '../components/Navigation/MainNav/types';
import { Permission } from './generated/graphql';

export const LOGIN_COPY = "Let's get started.";
export const AUTHENTICATING = 'Authenticating...';
export const REDIRECTING = 'Redirecting to main page...';
export const UNAUTHORIZED = 'Unauthorized';

export const LOGIN_BUTTON_COPY = 'Login';

export const NEWSFEED_COPY = 'NEWSFEED';
export const NEWSFEED_NO_NOTIFICATIONS = 'No notifications found.';

export const TIMEZONE_SETTINGS = {
  SET_UTC: false,
  SET_24: false,
};

export const FLIGHT_BLOCK_COPY = {
  TYPE: 'Type',
  REGISTRATION: 'Registration',
  GATE: 'Gate',
  POS: 'Pos',
  ATD: 'ATD',
};

export const FLIGHT_STATUS = {
  DEFAULT: 'DEFAULT',
  CANCELLED: 'CANCELLED',
  DEPARTED: 'DEPARTED',
};

export const NOTIFICATION_BUTTON = {
  MUTE: 'Mute Notifications',
  UNMUTE: 'Unmute Notifications',
};

export const HERO_COPY = (hours: number) =>
  `Have a great ${IS_DAY_TIME(hours) ? 'day' : 'shift'}.`;

export const GREETING = (time: number) => {
  if (IS_MORNING_HOURS(time)) {
    return 'Good Morning';
  }
  if (IS_NOON_HOURS(time)) {
    return 'Hello';
  }
  return 'Good Evening';
};

export const FLIGHT_SEARCH = {
  TYPE: 'Type',
  GATE: 'Gate',
  POSITION: 'Pos',
  DEPARTURE_TIME: 'ATD',
  EARLIER_FLIGHTS: 'Earlier Flights',
  LATER_FLIGHTS: 'Later Flights',
  SELECT_ALL: 'Select All',
  UNSELECT_ALL: 'Unselect All',
  NO_FLIGHTS: 'No flights found.',
  NO_FLIGHT_STATION: 'No flights or station found.',
  HEADER: 'Flight Search',
};

export const NO_DEFAULT_FLIGHTS = 20;

interface LINKS_TYPE {
  LH: string;
  LX: string;
  DSM: string;
  FLT: string;
  L: string;
  FEEDBACK: string;
}

type LinkKeys = keyof typeof LINKS;

interface LINKS_OBJECT {
  link: string;
  permission: Permission;
}

export const LINKS: { [key: string]: LINKS_OBJECT } = {
  LH: {
    link: 'LH Altea FM Mobile',
    permission: Permission.DISPLAY_MYLINK_LH_ALTEA,
  },
  LX: {
    link: 'LX Altea FM Mobile',
    permission: Permission.DISPLAY_MYLINK_LX_ALTEA,
  },
  DSM: {
    link: 'DocSurf Mobile',
    permission: Permission.DISPLAY_MYLINK_DSM,
  },
  FLT: {
    link: 'FLOAT',
    permission: Permission.DISPLAY_MYLINK_FLOAT,
  },
  L: {
    link: 'Look',
    permission: Permission.DISPLAY_MYLINK_LOOK,
  },
  FEEDBACK: {
    link: 'Feedback',
    permission: Permission.DISPLAY_MYLINK_FEEDBACK,
  },
};

export const LINKS_PERMISSIONS: { [key: string]: string } = {
  LH: 'LH Altea FM Mobile',
  LX: 'LX Altea FM Mobile',
  DSM: 'DocSurf Mobile',
  FLT: 'FLOAT',
  L: 'Look',
  FEEDBACK: 'Feedback',
};

export const MY_FLIGHTS = 'My Flights';

export const LOCAL_TIME = 'Local time';
export const UTC = 'UTC';

export const THEME = {
  AUTOMATIC: 'Automatic',
  LIGHT: 'Light',
  DARK: 'Dark',
};

export const AUTOMATIC = 'automatic';
export const LIGHT = 'light';
export const DARK = 'dark';

export const DAY_HOURS_START = 6;
export const NIGHT_HOURS_START = 18;

export const MY_PROFILE = {
  TITLE: 'My Profile',
  NAME: 'Name',
  FIRST_NAME: 'First Name',
  PHONE: 'Phone Number',
  EMAIL: 'User Principal Name',
  USERNAME: 'Username',
};

export const TOKEN_SETTINGS_FIELDS = {
  USERID: 'sub',
  EMAIL: 'dlh.upn',
  NAME: 'family_name',
  FIRSTNAME: 'given_name',
  PHONE: 'phone_number',
};

export const MY_STATION = 'My station';

export const FLIGHT_DETAILS = {
  ACCEPTANCE: 'Acceptance',
  FUELING: 'Fueling',
  BOARDING: 'Boarding',
  RAMP: 'Ramp',
  LOAD_CONTROL: 'Load control',
};

export const ABOVE_WING = 'Above the wing';
export const BELOW_WING = 'Below the wing';
export const TEAM = 'Turnaround Team';

export const GAUGE_LABEL = {
  PASSENGERS: 'Passengers',
  BAGGAGES: 'Baggage',
  HBAG: 'HBAG',
};

export const STICKY_SUB_NAV_LINKS = (page: string) => [
  {
    name: 'Process',
    link: '/process',
    isActive: page === 'process',
  },
  {
    name: 'Above Wing',
    link: '/above',
    isActive: page === 'above',
  },
  {
    name: 'Below Wing',
    link: '/below',
    isActive: page === 'below',
  },
  { name: 'Team', link: '/team', isActive: page === 'team' },
];

type MainNavMenuItem = {
  icon: MainNavIconVariant;
  name: string;
  link: string;
};

export const MAIN_NAV_LINKS = [
  { icon: 'home', name: 'Home', link: '/home' },
  { icon: 'search', name: 'Search All Flights', link: '/search' },
  {
    icon: 'upsidePlane',
    name: 'My Flights',
    link: '/favourites',
  },
  { icon: 'gear', name: 'Settings', link: '/settings' },
] as MainNavMenuItem[];

export const SPECIAL_LOADS = {
  TITLE: 'Special Loads',
  DGR: 'DGR',
  WCH: 'Wheelchairs',
  DAA: 'DAA',
  AVIH: 'AVIH',
  WEAP: 'WEAP',
  OTHER: 'Other',
};

export const FREIGHT_LOADED = {
  TITLE: 'Freight Loaded',
  CONTAINER: 'Container',
  PALETTS: 'Paletts',
  BULK_ITEMS: 'Bulk items',
};

export const PASSENGER_SPECIALS = {
  TITLE: 'Passenger Specials',
  WCH: 'WCH',
  STANDBY: 'Standby',
  DEPA: 'DEPA',
  DEPU: 'DEPU',
  MEDA_MEQT: 'MEDA/MEQT',
  STRC: 'STRC',
  WCHR: 'WCHR',
  WCHC: 'WCHC',
  WCHS: 'WCHS',
  WHEELCHAIRS: 'Wheelchairs',
  UMNR: 'UMNR',
};

export const CATERING = {
  TITLE: 'Catering',
  TOTAL_LOADED: 'Total Loaded',
  FIRST: 'First',
  BUSINESS: 'Business',
  PREMIUM: 'Premium',
  ECONOMY: 'Economy',
  VEGETARIAN: 'Vegatarian',
  VEGAN: 'Vegan',
  DIABETIC: 'Diabetic',
  STANDARD: 'Standard',
};

export enum CONNECTION_FLIGHT_TYPE {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUD',
}

export const INBOUND = {
  CONNECTIONS: 'Inbound Connections',
  TOTAL_PASSANGERS: 'Total Inbound Passengers',
  TOTAL_BAGGAGES: 'Total Inbound Baggages',
  CONNECTION_DIRECTION: 'Arrival',
  GATE: 'Gate',
  POSITION: 'Position',
  CONNECTION_ACTION: 'From',
  CONNECTION_FLIGHTS: 'Inbound flights',
  NO_CONNECTION_FLIGHTS: 'No inbound flights',
  NO_CONNECTION_FLIGHTS_FOUND: 'No inbound flights found',
};

export const OUTBOUND = {
  CONNECTIONS: 'Outbound Connections',
  TOTAL_PASSANGERS: 'Total Outbound Passengers',
  TOTAL_BAGGAGES: 'Total Outbound Baggages',
  CONNECTION_DIRECTION: 'Departure',
  GATE: 'Gate',
  POSITION: 'Position',
  CONNECTION_ACTION: 'To',
  CONNECTION_FLIGHTS: 'Outbound flights',
  NO_CONNECTION_FLIGHTS: 'No outbound flights',
  NO_CONNECTION_FLIGHTS_FOUND: 'No outbound flights found',
};

export const SHOW_MORE = 'Show more';
export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

interface BaggageMap {
  [key: string]: string;
}

export const BAGGAGE_MAP: BaggageMap = {
  Status: 'status',
  'Tag Number': 'tagNumber',
  ULD: 'ULD',
  POS: 'pos',
  'Sec. Nr.': 'securityNumber',
};

export const BAGGAGE_STATUS_COLOR = {
  Offload: 'red',
  'Not Loaded': 'grey-12',
  Loaded: 'grey',
};

export const PASSANGER_STATUS = {
  PASSANGER_STATUS: 'Passenger Status',
  BOARDED: 'Boarded',
  CHECKED_IN: 'Checked In',
  BOOKED: 'Booked',
  AVAILABLE: 'Available',
  STANDBY: 'Standby',
  MISSING: 'Missing',
  MCD: 'MCD',
  JUMPS: 'Jumps',

  FIRST_CLASS: 'First Class',
  BUSINESS_CLASS: 'Business Class',
  PREMIUM_ECONOMY_CLASS: 'Premium Economy Class',
  ECONOMY_CLASS: 'Economy Class',
  INFANTS: 'Infants',
};

export const BAGGAGE_STATUS = {
  BAGGAGE_STATUS: 'Baggage Status',
  LOADED: 'Loaded',
  CHECKED_IN: 'Checked-in',
  OFFLOAD: 'Offload',
};

export const CREW_STATUS = {
  CREW_STATUS: 'Crew Status',
  COCKPIT: 'Cockpit',
  CABIN: 'Cabin',
  DEADHEAD: 'Deadhead',
};

export const FLIGHT_CREW = {
  INBOUND: 'Inbound',
  GATE: 'Gate',
  POSITION: 'Position',
  ARRIVAL: 'Arrival',
  CREW_ON_DUTY: 'Crew on Duty',
  CREW_DEADHEAD: 'Crew Deadhead',
};

export const RELOAD = 'Reload';

export const FLIGHT_DETAILS_HEADER = {
  MY_FLIGHTS: 'My Flights',
  ROUTE: 'Route',
  GATE: 'Gate',
  POS: 'POS',
  TYPE: 'Type',
  REGISTRATION: 'Registration',
  INBOUND: 'Inbound',
  ATA: 'ATA',
  CHECK_IN: 'Check in',
  CHECK_OUT: 'Check out',
};

export enum ACTION_ITEMS {
  BOARDING = 'Boarding Time Update',
  NEW_TOBT = 'Request new TOBT',
  DELAY_CODE = 'Enter delay code',
  ETD_SETTINGS = 'ETD Setting',
  BRIEFING_PACKAGE = 'Briefing Package',
}

export const ACTIONS_DROPDOWN = {
  TITLE: 'Actions',
  ITEMS: [{ name: ACTION_ITEMS.BOARDING }],
};

interface AirlineColorMap {
  [key: string]: string;
}

export const AIRLINE_COLOR_MAP: AirlineColorMap = {
  lh: 'bg-primary',
  vl: 'bg-primary',
  lx: 'bg-sw',
  os: 'bg-au',
  en: 'bg-ad',
  sn: 'bg-ba',
  '4y': 'bg-4y',
};

export const LIGHT_FONT_AIRLINES_BG = ['bg-4y', 'bg-au', 'bg-sw', 'bg-ad'];

export const LIGHT_FONT_AIRLINES = ['os', '4y', 'en', 'lx'];

export const NO_SELECTED_FLIGHTS = 'You have not selected any flights yet.';

export const SEARCH_FLIGHTS = 'Search All Flights';

//Gantt Dummy Times. These will move to API later
//For demo purposes, setting the current day manually.
const getDay = moment().format('DD-MM-YYYY ');
export const GANTT_TIMES = {
  SAMPLE_TIMES: [
    //target start and target end displayed below as start & end
    //on BE the times that are not need must not be added all logic is applied directly on FE.
    {
      id: 1,
      name: 'Unloading',
      start: getDay + '13:25:00',
      end: getDay + '13:55:00',
      delayStart: getDay + '13:20:00',
      delayEnd: getDay + '13:50:00',
    },
    {
      id: 2,
      name: 'Deboarding',
      start: getDay + '13:25:00',
      end: getDay + '13:55:00',
      delayStart: getDay + '13:20:00',
      delayEnd: getDay + '14:00:00',
    },
    {
      id: 3,
      name: 'Catering',
      start: getDay + '13:40:00',
      end: getDay + '14:00:00',
      delayStart: getDay + '13:46:00',
      delayEnd: getDay + '14:13:00',
    },
    {
      id: 4,
      name: 'Fueling',
      start: getDay + '13:25:00',
      end: getDay + '13:55:00',
      delayStart: getDay + '13:30:00',
      delayEnd: getDay + '13:50:00',
    },
    {
      id: 5,
      name: 'Cleaning',
      start: getDay + '13:30:00',
      end: getDay + '13:50:00',
      delayStart: getDay + '13:30:00',
      delayEnd: getDay + '13:56:00',
    },
    {
      id: 6,
      name: 'Loading',
      start: getDay + '13:30:00',
      end: getDay + '13:50:00',
      delayStart: getDay + '13:30:00',
      delayEnd: getDay + '13:56:00',
    },
    {
      id: 7,
      name: 'Boarding',
      start: getDay + '13:15:00',
      end: getDay + '13:45:00',
    },
    {
      id: 8,
      name: 'Maintenance',
      start: getDay + '13:30:00',
      end: getDay + '13:50:00',
      delayStart: getDay + '13:36:00',
      delayEnd: getDay + '13:58:00',
    },
  ],
};

export const GANTT_SETTINGS = {
  DEMO: true,
  DEMO_TITLE:
    '*Attention: The reference model is in preview mode for demonstration purposes only.* ',
  DEMO_TEXT:
    'This element relies on external services which are currently being developed. Look forward to experiencing the full feature soon!',
  USE_UTC: false,
};

export const FLIGHT_DETAIL_PAGES = {
  ABOVE: 'above',
  PROCESS: 'process',
};

export const REGIONAL_SETTING = 'Regional Settings';
export const TIME_ZONE = 'Time zone';
export const TIME_FORMAT = 'Time format';
export const NOTIFICATIONS = 'Notifications';
export const FLY_IN_NOTIFICATIONS = 'Fly-in Notifications';
export const INTERFACE = 'Interface';
export const USER_ROLES = 'Roles';
export const THEMING = 'Theming';
export const LOGOUT = 'Logout';
export const RESET_ALL = 'Reset';
export const SETTINGS = 'Settings';
export const SAVE = 'Save';
export const COMING_SOON = 'Coming Soon...';

export const NEWSFEED_TITLE = 'Coming soon';
export const NEWSFEED_SUBTITLE = 'This is a newsfeed teaser';
export const NEWSFEED_TEXT =
  "Please find status updates for flights on your 'My flights' list prompted here soon";
export const NEW_CHAT_MESSAGE_SUBTITLE = 'New Message from ';

export const IS_DAY_TIME = (hours: number) => hours >= 4 && hours < 18;

export const IS_MORNING_HOURS = (hours: number) => hours >= 4 && hours < 9;
export const IS_NOON_HOURS = (hours: number) => hours >= 9 && hours < 18;
export const IS_EVENING_HOURS = (hours: number) =>
  hours >= 18 || (hours >= 0 && hours < 4);

export const LINK_ROUTES: { [key: string]: string } = {
  LH: 'https://afmgui.si.amadeus.net/1ASIHDFLH/fm/home/RampMobile',
  LX: 'https://afmgui.si.amadeus.net/1ASIHDFLX/fm/home/RampMobile',
  DSM: 'https://docsurf.app.lufthansa.com/',
  FLT: 'https://float.cms.lhgroup.de/',
  L: 'https://look.lufthansa-group.com/',
  FEEDBACK: 'https://forms.office.com/e/AherCJPVGC',
};

export const VARIANTS = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const ERRORS = {
  MIN_3_CHAR: 'Minimum 3 characters required',
  COULD_NOT_SAVE: 'Could not save changes',
  NO_DATA: 'No data found',
  NO_FAVOURITE_FLIGHTS: 'No favourite flights found',
  COULD_NOT_LOGIN: 'Could not login',
  NOT_AUTHORIZED: 'Not authorized',
  ERROR_GET_SETTINGS: 'Error retrieving user settings',
  GENERAL_ERROR: 'Something went wrong!',
};

export const NOTIFICATION_MSG = {
  SAVED: 'Changes saved',
};

export const ASSINGNMENT_TYPE = {
  FOC: 'FOC',
  HTL: 'HTL',
};

export const URL_SOURCE = { FAVOURITES: 'favourites', SEARCH: 'search' };

export const DEFAULT_FLIGHTS_SHOWN = 3;
export const DEFAULT_BAGGAGE_SHOWN = 10;
export const INCREMENT_BAGGAGE = 10;

export const NO_CREW_DEADHEAD_DATA = 'No data available';
export const NO_CREW_ON_DUTY_DATA = 'No data available';

export const EMPTY_STATE = '-';
export const INITIAL_STATE = ' ';
export const N_A = 'N/A';

export const BAGGAGE_LIST = 'Baggage list';
export const NO_BAGGAGE_FOUND = 'No baggage found';

export const HOUR_12 = '12h time format';
export const HOUR_24 = '24h time format';

export const HOUR_FORMAT_12 = {
  options: [
    {
      value: HOUR_24,
      selected: false,
    },
    {
      value: HOUR_12,
      selected: true,
    },
  ],
  title: TIME_FORMAT,
};

export const HOUR_FORMAT_24 = {
  options: [
    {
      value: HOUR_24,
      selected: true,
    },
    {
      value: HOUR_12,
      selected: false,
    },
  ],
  title: TIME_FORMAT,
};

export const TAGS = {
  BOARDING_NOT_READY: 'boardingNotReady',
  NEW_BOARDING_TIME: 'newBoardingTime',
};

export const TAGS_COPY = {
  BOARDING_NOT_READY: 'Boarding not ready',
  NEW_BOARDING_TIME: 'First PAX at aircraft',
};

export const MARK_AS_READ = 'Mark all as read';

export const TIME_SORTING = { ASC: 'time-ASC', DSC: 'time-DSC' };
export const DEPARTURE_SORTING = { ASC: 'departure-ASC', DSC: 'departure-DSC' };

export const CONFIRM_CHANGES = 'Confirm changes';
export const NO_CHANGES = 'No changes registered';
export const EDIT_MY_FLIGHTS = 'Edit My Flights';
export const MUTE_ALL = 'Mute All';
export const UNMUTE_ALL = 'Unmute All';

export const NO_ACTIVE_MY_FLIGHTS = 'NO ACTIVE MY FLIGHTS';

export const DEPARTED_OR_CANCELLED = 'Departed or cancelled';

export const ON = 'on';
export const OFF = 'off';

export const DEFAULT_SETTING_MY_FLIGHTS = 'Default setting for my Flights';
export const NOTIFICATIONS_FOR_STATIONS = 'Notifications for station';
export const NOTIFICATIONS_FOR_CHAT = 'Notifications for chat messages';

export const GENERAL_NOTIFICATION_ARRAY = [
  {
    code: 500,
    message: 'Error retrieving flight data',
  },
];

export const INITIAL_COUNTDOWN_VALUE = 30;
export const ONE_SECOND_IN_MILLISECONDS = 1000;

export const MOBILE_NOTIFICATION_BREAKPOINT = 480;

export const ADMIN_ROLE = 'TAC_ADMIN';

export const NOTIFICATION_MODAL_TITLE = {
  CREATE: 'Create Notification',
  EDIT: 'Edit Notification',
  DELETE: 'Delete Notification',
};

export const NOTIFICATION_MODAL_BODY = {
  NOTIFICATION_CONFIGURATION: 'Notification Configuration',
  TARGET_AUDIENCE: 'Target Audience',
  MESSAGE: 'Message',
  SCHEDULE: 'Notification Schedule',
};

export const ADMIN_NOTIFICATIONS = {
  NEW_NOTIFICATION: 'New Notification',
  NOTIFICATION_HISTORY: 'Notifications History',
};

export const DELETE_NOTIFICATION_COPY =
  'Are you sure you want to delete this notification?';

export const BROADCAST = 'Broadcast';

export const ALL_CHATS = {
  TITLE: 'ALL CHATS',
  TODAY: 'Today',
  MY_CHATS: 'My Chats',
};

export const NOTIFICATIONS_CENTER = 'Notifications Center';

export const FLIGHT_CHAT = {
  ALL_CHATS: 'All Chats',
  NO_CHATS: 'No active chats',
  START_CONVERSATION: 'Start the conversation',
  TYPE: 'Type something ...',
  RETRY: 'Retry',
  DELETE: 'Delete',
};

export const MAX_LENGTH_SUBJECT = 50;

export const MAX_LENGTH_DESCRIPTION = 200;

export const TOBT = 'TOBT';
export const TSAT = 'TSAT';
export const CTOT = 'CTOT';
export const NULL_TIME_STATE = '--:--';
export const ONE_HOUR = '1h';

export const APP_NAME = 'TURNAROUND';

export const CHAT_TOPIC_SEPARATOR = '~';

export const DAY_PERIOD = {
  AM: 'AM',
  PM: 'PM',
};

export const CONFIRM = 'Confirm';
export const DISMISS = 'Dismiss';

export const GO_TO_SETTINGS = 'Enter phone number';

export const NO_THANK_YOU = 'No, thank you';

export const REMIND_ME_LATER = 'Remind me later';

export const BOARDING_TIME = {
  EDIT_CTA: 'Edit Boarding Time',
  TITLE: 'Boarding Time Update',
  CANCEL: 'Cancel',
  DELAY_REASONS: 'Reasons for Delay (max. 2)',
  SEND: 'Send',
};

export const ETD_SETTINGS = {
  EDIT_CTA: 'Edit ETD Setting',
  TITLE: 'ETD Setting',
  CANCEL: 'Cancel',
  DELAY_REASONS: 'Reasons (max. 4)',
  SI_MESSAGE: 'SI-Message',
  SEND: 'Send',
  CONFIRM,
  SI_MESSAGE_LENGTH: 150,
  ADD_REASONS: 'Add reasons',
  EDIT_REASONS: 'Add/Edit reasons',
  NO_REASONS_SELECTED: 'No reasons selected',
};

export const BRIEFING_PACKAGE = {
  TITLE: 'Briefing Package',
  CANCEL: 'Cancel',
  CONFIRM,
  DOWNLOAD: 'Download',
  DIGITAL_BRIEFING: 'Digital Briefing (EFF File)',
  PAPER_BRIEFING: 'Paper Briefing (PDF File)',
  HIL_BRIEFING: 'HIL',
  INFO_TEXT:
    'Briefing documents are provided to the cockpit crew on demand according to LHG AHM GP6.2.2 (LH, CL only). These can now be accessed here.',
};

export const MOBILE_BREAKPOINT = 767;

export const DESKTOP_BREAKPOINT = 1024;

export enum DEVICE_TYPE {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
}

export const INVALID_TOKEN = 'INVALID_TOKEN';

export const BOARDING_REASONS_ERROR =
  'Error retrieving boarding update reasons.';

export const UPDATE_BOARDING_ERROR = 'Error updating boarding time.';

export const TIME_FORMAT_12 = 'h:mm aZ';

export const MERIDIAN_CHANGE = 12;

export const TIME_FORMAT_24 = 'HH:mm Z';

export const UTC_ZERO = '+0000';

export const HBAG_LABEL = 'Carry-on Baggage collection';

export const COB_WARNING = 'Overhead bins critical';

export const YOU = 'You';

export const PLUS_ONE = '+1';

export const TELEPHONY = 'Telephony';

export const TELEPHONY_OVERLAY = 'TELEPHONY';

export const MY_CHECKIN_FLIGHTS = 'MY CHECKED-IN FLIGHTS';

export const TELEPHONY_SEARCH_TITLE = 'Enter flight number';

export const GET_CONTACTS = 'Get contacts';

export const DIRECT_CALL = 'DIRECT CALL';

export const TELEPHONE_BOOKS = 'TELEPHONE BOOKS';

export const SEARCH_TELEPHONY_PLACEHOLDER = 'e.g. LH400 or FRA';

export const EDITING_PHONE_NUMBER = 'Editing phone number';

export const NO_ROLE_CHECKEDIN = 'No role checked-in for this flight';

export const NO_STATION_DATA = 'No role for this station';

export const NO_FLIGHTS_CHECKEDIN = 'You are not checked-in on any flights yet';

export const EDITING_PHONE_NUMBER_COPY =
  'This application is intended for business use only. Therefore, please register a business phone number. Private phone numbers are not allowed.';

export const MINUTES_IN_HOUR = 60;

export const MINUTES_IN_DAY = 1440;

export const INVALID_INPUT = 'Invalid input';
export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  UAT: 'uat',
  LOCAL: 'local',
};

export const BASE_ROLES = {
  DEV: 'TAC_BASE_DEV',
  UAT: 'TAC_BASE_UAT',
  PROD: 'TAC_BASE',
};

export const MAX_ETD_NO_REASONS = 4;

export const MISSING_PHONE_NUMBER = 'Missing Telephone Number';
export const MISSING_PHONE_NUMBER_COPY =
  'There is no telephone number in your user account. If you want to be available for telephone calls, please enter a business phone number.';
export const MY_ROLE = 'My role';

export const FLIGHT_CANCELLED = 'FLIGHT CANCELLED';
export const SLOT_CANCELLED = 'Slot Cancelled';

export const TEAM_CHAT = {
  roleGroup: 'ALL',
  roleName: 'Team',
};

export const NO_CHECKED_IN_FLIGHT = 'No checked-in flights.';

export const NO_TELEPHONY_STATION = 'No available telephony books.';

export const ROLES_SEARCH_PLACEHOLDER = 'Search role by keyword';

export const UPDATE_ETD_ERROR = 'Error updating ETD.';

export const ETD_TIME_FORMATTING = 'YYYY-MM-DDTHH:mm:ss Z';

export const HIGHLANDER_MODAL_COPY =
  'There is another user already checked in on the same role. Checking in now will result in them being checked out. Do you wish to proceed?';

export const HIGHLANDER_CHECKOUT_MODAL_COPY = (
  role: string,
  flightNumber: string
) =>
  `Another user has checked in as ${role} on flight ${flightNumber}. As a result, you have been automatically checked out.`;
