import { useMutation } from '@apollo/client';
import { REMOVE_MY_FLIGHTS } from '../../graphql/myFlights/my-flights.mutations';
import { FlightSearch, Mutation } from '../generated/graphql';
import { IMutationResponse } from '../types';
import { MutationOptions } from '../types';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import { updateFlightNotificationsHistory } from '../../redux/reducers/newsfeedReducer';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_FLIGHTS } from '../../graphql/flightSearch/flight-search.queries';
import { selectQuery } from '../../redux/reducers/searchReducer';

interface SuccessResponse {
  success?: boolean;
}

interface ICachedFlightSearch {
  flightSearch: { data: FlightSearch[]; errors: null };
}

interface RemoveMyFlightResponse extends IMutationResponse<SuccessResponse> {
  onRemoveFromFavourites: (flightIds: string[]) => Promise<string[]>;
}

export const useRemoveMyFlight = (
  options?: MutationOptions
): RemoveMyFlightResponse => {
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);
  const [removeFromFavourites, { data, error }] = useMutation<
    Pick<Mutation, 'removeMyFlight'>
  >(REMOVE_MY_FLIGHTS, options);

  const onRemoveFromFavourites = async (flightIds: string[]) => {
    try {
      const result = await removeFromFavourites({
        variables: {
          input: {
            flights: flightIds,
          },
        },
        optimisticResponse: {
          removeMyFlight: {
            removedFlights: flightIds,
            success: true,
            __typename: 'MyFlightsRemoveOutput',
          },
        },
        update: (cache, { data: { removeMyFlight } }) =>
          cache.updateQuery<ICachedFlightSearch>(
            {
              query: SEARCH_FLIGHTS,
              variables: { genericQuery: query?.toLocaleUpperCase() ?? '' },
            },
            (data) => ({
              flightSearch: {
                ...data.flightSearch,
                data: data.flightSearch.data.map((flight) => ({
                  ...flight,
                  isFavourite: removeMyFlight.removedFlights.includes(
                    flight.flightId
                  )
                    ? false
                    : flight.isFavourite,
                })),
              },
            })
          ),
      });

      if (result && result.data?.removeMyFlight?.success) {
        dispatch(updateFlightNotificationsHistory());
        flightIds.forEach((flightId) => {
          UtilFactory.newMessageHandler().unsubscribe(
            `${MQTT_TOPIC.FLIGHT}${flightId}`
          );
        });

        return flightIds;
      }

      return [];
    } catch (error) {
      throw error;
    }
  };
  return {
    onRemoveFromFavourites,
    data: { success: data?.removeMyFlight?.success ?? false },
    error: error,
  };
};
