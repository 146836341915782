import moment from 'moment';
import { GANTT_SETTINGS } from '../../../utils/constants';

export const delayOffsetted = ({ start, end, delayStart, delayEnd }, currentTime, delayOffset) => {
  let startTemp = moment(start, 'DD-MM-YYYY HH:mm:ss');
  let endTemp = moment(end, 'DD-MM-YYYY HH:mm:ss');
  let delayStartTemp = delayStart ? moment(delayStart, 'DD-MM-YYYY HH:mm:ss') : null;
  let delayEndTemp = delayEnd ? moment(delayEnd, 'DD-MM-YYYY HH:mm:ss') : null;

  // Remove the code comment below to only show the delay if the NOW time has NOT passed

  // let delayEndTime = moment(delayEnd, 'DD-MM-YYYY HH:mm').format('HH:mm');
  // let isBeforeTime = moment(delayEndTime, 'HH:mm').isBefore(
  //   moment(currentTime, 'HH:mm'),
  //   'minute'
  // );

  // let currentStartTime = moment(start, 'DD-MM-YYYY HH:mm').format('HH:mm');
  // let isCurrentStartTime = moment(currentStartTime, 'HH:mm').isBefore(
  //   moment(currentTime, 'HH:mm'),
  //   'minute'
  // );

  // Calculate the time differences between relevant moments
  const actionEndAvailable = delayEndTemp
    ? moment.duration(delayEndTemp.diff(endTemp)).format('h:mm', { trim: false })
    : null;
  const actionEndUnavailable = delayStartTemp
    ? moment.duration(delayStartTemp.diff(startTemp)).format('h:mm', { trim: false })
    : null;

  // if (!isBeforeTime && isCurrentStartTime) {
  if (GANTT_SETTINGS.DEMO !== true) {
    if (delayEndTemp) {
      const delayEndOffset = moment(actionEndAvailable, 'h:mm').diff(delayOffset, 'minutes');
      return delayEndOffset > 0 ? `+${actionEndAvailable}` : null;
    } else {
      const delayStartOffset = moment(actionEndUnavailable, 'h:mm').diff(delayOffset, 'minutes');
      return delayStartOffset > 0 ? `+${actionEndUnavailable}` : null;
    }
  } else {
    return 'Demo';
  }
  // }
}
