import { gql } from '@apollo/client';
import { USER_SETTINGS_FRAGMENT } from '../fragments';

export const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    getUserSettings {
      userId
      ...UserSettings
    }
  }
  ${USER_SETTINGS_FRAGMENT}
`;
