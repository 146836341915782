import { gql } from '@apollo/client';

export const TELEPHONY_BOOKS = gql`
  query CachedTelephonyBook($stationsList: [String!]) {
    cachedTelephonyBook(stationsList: $stationsList) {
      station
      roles {
        roleName
        users {
          phoneNumber
          externalUniqueID
          externalID
          group
        }
      }
    }
  }
`;
