import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Outlet, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainNavTablet from './components/Navigation/MainNav/MainNavTablet';
import HeaderContainer from './components/Navigation/Header/HeaderContainer/HeaderContainer';
import FlightHeaderContainer from './components/FlightDetailsHeader/FlightHeaderContainer';
import './utils/headerStyles.scss';
import NotificationsList from './components/NotificationsList/NotificationsList';
import { ToastContainer } from 'react-toastify';
import { getWindowDimensions, isUserLoggedIn } from './utils/helpers';
import PullToRefresh from './PullToRefresh';
import StickySubNav from './components/Navigation/StickySubNav/StickySubNav';
import './globals.scss';

import { MOBILE_NOTIFICATION_BREAKPOINT } from './utils/constants';
import 'react-toastify/dist/ReactToastify.css';
import StorageUtil from './utils/StorageUtil';
import useCheckPermissions, {
  CheckType,
} from './utils/hooks/useCheckPermissions';
import { Permission } from './utils/generated/graphql';
import Overlay from './components/Overlay/Overlay';
import { useGetNotificationsHistory } from './utils/hooks/useGetNotificationsHisotry';
import { selectShouldFetchNotifications } from './redux/reducers/newsfeedReducer';

const Layout = () => {
  const isLoggedIn = isUserLoggedIn();
  const { headerTypeMobile, headerTypeTablet, isMenuOpen } = useSelector(
    (state) => state.headerReducer
  );
  const shouldFetchNotification = useSelector(selectShouldFetchNotifications);

  const { getNotificationsHistory } = useGetNotificationsHistory();

  useEffect(() => {
    if (shouldFetchNotification) {
      getNotificationsHistory();
    }
  }, [shouldFetchNotification]);

  useEffect(() => {
    if (isLoggedIn) {
      StorageUtil.set('is_token_refreshing', 'false');
    }
  }, [isLoggedIn]);

  const searchParams = useMatch('/search/:flight/*');
  const favouritesParams = useMatch('/favourites/:flight/*');

  const currentFlightDetailsPage =
    favouritesParams?.params['*'] || searchParams?.params['*'];

  const isMobileNotification =
    getWindowDimensions().width <= MOBILE_NOTIFICATION_BREAKPOINT;

  const hasStickyPermission = useCheckPermissions(
    [
      Permission.DISPLAY_ABOVE_THE_WING_PAGE,
      Permission.DISPLAY_BELOW_THE_WING_PAGE,
      Permission.DISPLAY_TEAM_PAGE,
    ],
    CheckType.PARTIAL
  );

  return (
    <>
      <PullToRefresh>
        <div
          className="flex mobile:flex-col h-screen"
          id="test"
          style={{ height: '100dvh' }}>
          <ToastContainer
            toastClassName={
              isMobileNotification
                ? () =>
                    'bg-blue-100 dark:bg-grey-90 dark:shadow dark:shadow-black flex p-1 min-h-10 cursor-pointer shadow rounded-4'
                : () =>
                    'my-[8px] bg-white dark:bg-grey-90 dark:shadow dark:shadow-black flex p-1 min-h-10 cursor-pointer shadow rounded-4'
            }
            closeButton={!isMobileNotification}
            hideProgressBar={true}
            autoClose={
              Number(process.env.REACT_APP_TAC_FLY_IN_NOTIFICATION_DELAY) ||
              5000
            }
          />
          <div className="mobile:hidden">
            <MainNavTablet />
          </div>
          <div
            className="flex flex-col w-full overflow-scroll h-full hide-scrollbar"
            id="id-scroll">
            <div
              className="fixed mobile:hidden"
              style={{ width: 'calc(100% - 64px)', zIndex: 2 }}>
              <FlightHeaderContainer />
            </div>
            {isLoggedIn && (
              <div
                className={classNames(
                  'fixed w-full tablet:hidden laptop:hidden desktop:hidden',
                  { 'z-[90]': isMenuOpen, 'z-20': !isMenuOpen }
                )}>
                <HeaderContainer />
              </div>
            )}
            <div className="absolute w-full max-w-[75%] left-1/2 -translate-x-1/2 mt-24 z-[60]">
              <NotificationsList />
            </div>
            <div
              className={classNames({
                headerContainer: headerTypeTablet,
                flightHeaderContainer: headerTypeMobile === 'flight',
              })}>
              <Outlet />
              {!!currentFlightDetailsPage && hasStickyPermission && (
                <StickySubNav page={currentFlightDetailsPage} />
              )}
            </div>
          </div>
        </div>
      </PullToRefresh>
      <Overlay />
    </>
  );
};

export default Layout;
