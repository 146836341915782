class StorageUtil {
  static set(key: string, value: string) {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  }

  static get(key: string): string | null {
    if (window.localStorage) {
      return window.localStorage.getItem(key);
    }

    return null;
  }

  static remove(key: string) {
    if (window.localStorage) {
      window.localStorage.removeItem(key);
    }
  }

  static clear() {
    if (window.localStorage) {
      window.localStorage.clear();
    }
  }
}

export default StorageUtil;
