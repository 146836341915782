import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { arc } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import classNames from 'classnames';
import { FLIGHT_STATUS, INITIAL_STATE } from '../../utils/constants';

const labelClass = 'font-body-text text-12 leading-[12px] mb-8 text-grey-40';
const maxClass = 'text-grey-40 text-18 leading-[19px] h-[19px] font-head-light';
const gaugeDataClass = 'flex flex-col justify-center items-center h-[100%]';
const gaugeDataContainer = 'absolute bottom-0 top-0 left-0 right-0';

const Gauge = ({
  value,
  min,
  max,
  status = 'DEFAULT',
  label,
  isLoading,
  className,
  ...others
}) => {
  const backgroundArc = arc()
    .innerRadius(0.99)
    .outerRadius(1)
    .startAngle(-Math.PI)
    .endAngle(Math.PI)();

  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent =
    max === 0 || typeof max !== 'number' ? 0 : percentScale(value);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([0, 2 * Math.PI])
    .clamp(true);
  const angle = angleScale(percent);

  const filledArc = arc()
    .innerRadius(0.95)
    .outerRadius(1)
    .startAngle(0)
    .endAngle(angle)();

  const valueClass = classNames(
    'mb-8 text-80 leading-[80px] h-[80px] font-body-thin',
    {
      'dark:text-grey-12 text-primary': status === FLIGHT_STATUS.DEFAULT,
      'text-red': status === FLIGHT_STATUS.CANCELLED,
      'text-green': status === FLIGHT_STATUS.DEPARTED,
    }
  );

  const backgroundArcClass = classNames({
    'dark:fill-grey-80 fill-grey-12': status === FLIGHT_STATUS.DEFAULT,
    'fill-red': status === FLIGHT_STATUS.CANCELLED,
    'fill-green': status === FLIGHT_STATUS.DEPARTED,
  });

  const filledArcClass = classNames({
    'dark:fill-grey-12 fill-primary': status === FLIGHT_STATUS.DEFAULT,
    'fill-none': status !== FLIGHT_STATUS.DEFAULT,
  });

  const containerClassName = classNames('relative w-[210px]', className);

  return (
    <div className={containerClassName} {...others}>
      <svg viewBox={[-1, -1, 2, 2]} title={label} width="auto">
        <path d={backgroundArc} className={backgroundArcClass} />
        <path d={filledArc} className={filledArcClass} />
      </svg>
      <div className={gaugeDataContainer}>
        <div className={gaugeDataClass}>
          <p className={labelClass}>{label}</p>
          <p className={valueClass}>{isLoading ? INITIAL_STATE : value}</p>
          <p className={maxClass}>{isLoading ? INITIAL_STATE : max}</p>
        </div>
      </div>
    </div>
  );
};

Gauge.propTypes = {
  value: oneOfType([string, number]),
  min: number,
  max: oneOfType([string, number]),
  status: string,
  label: string,
  isLoading: bool,
  className: string,
};

export default Gauge;
