import classNames from 'classnames';
import React from 'react';
import { LINKS, LINK_ROUTES } from '../../../utils/constants';
import Icon from '../../Icon/Icon';
import useCheckPermissions from '../../../utils/hooks/useCheckPermissions';

const subheadingClass =
  'dark:text-grey-12 text-center mt-[6px] font-body-text text-12 leading-[12px]';
const iconContainer = 'w-[58px]';

interface ILinkIcon {
  variant: string;
  className?: string;
}

const LinkIcon = ({ variant = 'LH', className, ...others }: ILinkIcon) => {
  const linkObject = LINKS[variant];
  const hasLinkPermission = useCheckPermissions([linkObject.permission]);
  const headingClass = classNames('font-head-light text-24 leading-[27px]', {
    'text-white': variant !== 'DSM',
    'text-primary dark:text-primary text-[16px] leading-[22px]':
      variant === 'DSM',
  });

  const iconClass = classNames(
    'flex justify-center items-center w-[60px] h-[58px] rounded-12 ',
    {
      'bg-primary': variant === 'LH',
      'bg-sw': variant === 'LX',
      'bg-grey-12': variant === 'DSM' || variant === 'FEEDBACK',
      'bg-grey-80': variant === 'FLT',
      'bg-secondary': variant === 'L',
    }
  );

  return (
    hasLinkPermission && (
      <div className={classNames(iconContainer, className)} {...others}>
        <a
          href={LINK_ROUTES[variant]}
          target="_blank"
          rel="noopener noreferrer">
          <div className={iconClass}>
            {variant !== 'FEEDBACK' ? (
              <h1 className={headingClass}>{variant}</h1>
            ) : (
              <Icon
                width={85}
                height={85}
                variant={'feedback'}
                className="fill-white"
              />
            )}
          </div>
          <h2 className={subheadingClass}>{linkObject.link}</h2>
        </a>
      </div>
    )
  );
};

export default LinkIcon;
