import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CacheTelephonyEntry } from '../../utils/generated/graphql';

interface TelephonyEntry {
  [key: string]: CacheTelephonyEntry;
}

export const telephonyReducer = createSlice({
  name: 'telephony',
  initialState: { stations: {} as TelephonyEntry },
  reducers: {
    setStation: (state, action) => {
      state.stations = {};
      action.payload.forEach((elem: CacheTelephonyEntry) => {
        state.stations[elem?.station ?? ''] = elem;
      });
    },
  },
});

export const { setStation } = telephonyReducer.actions;

export const selectTelephonyStation = (state: RootState) =>
  state.telephonyReducer.stations;

export default telephonyReducer.reducer;
