import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { LOCAL_TIME, UTC } from '../../../utils/constants';
import { TimeOption } from '../../../utils/types';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';

const optionClassNames = 'appearance-none hidden';
const defaultClassNames =
  'font-head-bold text-primary text-14 leading-[18px] border-1 border-primary w-full py-13 flex justify-center dark:text-grey-12 dark:border-grey-12';
const selectedClassNames =
  'bg-primary text-white1 dark:bg-grey-12 dark:text-grey-90';
const notSelectedClassNames = 'bg-white dark:bg-grey-90';

interface ITimeZone {
  timeZone: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TimeZone = ({ timeZone, handleChange, ...others }: ITimeZone) => {
  const isOffline = !useNetworkStatus();

  const getLabelClassNames = (option: TimeOption) => {
    return classNames(defaultClassNames, {
      [selectedClassNames]: option === timeZone,
      [notSelectedClassNames]: option !== timeZone,
      'opacity-50': isOffline,
      'rounded-l-4': option === LOCAL_TIME,
      'rounded-r-4': option === UTC,
    });
  };

  return (
    <div className="flex" {...others}>
      <input
        type="radio"
        name="timeZone"
        value={LOCAL_TIME}
        id={LOCAL_TIME}
        className={optionClassNames}
        checked={timeZone === LOCAL_TIME}
        onChange={(e) => handleChange(e)}
        disabled={isOffline}
      />
      <label htmlFor={LOCAL_TIME} className={getLabelClassNames(LOCAL_TIME)}>
        {LOCAL_TIME}
      </label>
      <input
        type="radio"
        name="timeZone"
        value={UTC}
        id={UTC}
        className={optionClassNames}
        checked={timeZone === UTC}
        onChange={(e) => handleChange(e)}
        disabled={isOffline}
      />
      <label htmlFor={UTC} className={getLabelClassNames(UTC)}>
        {UTC}
      </label>
    </div>
  );
};

export default TimeZone;
