import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import AppVersion from '../app.json';

const checkOutdatedCache = (cacheNames: string[]) =>
  !!cacheNames.filter(
    (version) =>
      version.startsWith('tac-') && !version.endsWith(`-${AppVersion.version}`)
  ).length;

export const rebuildCache = async () => {
  console.log(
    `Current cache version ${AppVersion.version}. Start rebuilding cache...`
  );
  if ('serviceWorker' in navigator) {
    const cacheNames = await caches.keys();
    console.log('Found the following cache versions: ', cacheNames);
    const hasMissingTACCache = cacheNames.every(
      (version) => !version.startsWith('tac')
    );
    const shouldRebuildCache =
      checkOutdatedCache(cacheNames) || hasMissingTACCache;
    console.log('Has outdated cache: ', shouldRebuildCache);
    console.log('Has missing TAC cache: ', hasMissingTACCache);

    if (shouldRebuildCache) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        await registration.unregister();
      }

      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );

      serviceWorkerRegistration.register({
        onUpdate: (registration) => {
          if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
          window.location.reload();
        },
      });
      console.log('Service worker re-registered');
    }
  }
};
