import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { node, array, number, string } from 'prop-types';
import { oneOfType } from 'prop-types';

/**
 * Grid will accept an array for each prop, containing 3 values. The first value is the number of columns. The second value is the horizontal gap.
 * The third value is the vertical gap. If you only use a number, the grid will not have gaps between the columns. The gap size is set in tailwind.config.js, in spacing
 */

interface IGridProp extends Array<string | number> {}

interface IGridProps<T> {
  mobile?: T;
  tablet?: T;
  laptop?: T;
  desktop?: T;
  col?: T;
  className?: string;
  gridClassName?: string;
  children?: ReactNode;
}

const Grid = <T extends IGridProp>(props: IGridProps<T>) => {
  const {
    mobile,
    tablet,
    laptop,
    desktop,
    col,
    className,
    gridClassName,
    children,
    ...others
  } = props;

  const gridClassNames = classNames(
    'grid',
    {
      [`grid-cols-${col?.[0] || col}`]: col?.[0] || col,
      [`gap-x-${col?.[1]}`]: col?.[1],
      [`gap-y-${col?.[2]}`]: col?.[2],

      [`mobile:grid-cols-${mobile?.[0] || mobile}`]: mobile?.[0] || mobile,
      [`mobile:gap-x-${mobile?.[1]}`]: mobile?.[1],
      [`mobile:gap-y-${mobile?.[2]}`]: mobile?.[2],

      [`tablet:grid-cols-${tablet?.[0] || tablet}`]: tablet?.[0] || tablet,
      [`tablet:gap-x-${tablet?.[1]}`]: tablet?.[1],
      [`tablet:gap-y-${tablet?.[2]}`]: tablet?.[2],

      [`laptop:grid-cols-${laptop?.[0] || laptop}`]: laptop?.[0] || laptop,
      [`laptop:gap-x-${laptop?.[1]}`]: laptop?.[1],
      [`laptop:gap-y-${laptop?.[2]}`]: laptop?.[2],

      [`desktop:grid-cols-${desktop?.[0] || desktop}`]: desktop?.[0] || desktop,
      [`desktop:gap-x-${desktop?.[1]}`]: desktop?.[1],
      [`desktop:gap-y-${desktop?.[2]}`]: desktop?.[2],
    },
    gridClassName
  );

  return (
    <div className={className}>
      <div className={gridClassNames} {...others}>
        {children}
      </div>
    </div>
  );
};

Grid.displayName = 'Grid';

export default Grid;
