import { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { setFormError } from '../../redux/reducers/settingsReducer';

interface IUseForm {
  validation?: (value: string) => { isError: boolean; errorMessage: string };
  validateIfTouched?: boolean;
  extraCondition?: boolean;
  currentValue: string;
}

export const useForm = ({
  validation,
  validateIfTouched = false,
  currentValue,
}: IUseForm) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTouched, setIsTouched] = useState(false);

  const dispatch = useDispatch();

  const validate = (inputValue: string) => {
    if (validation) {
      const validationResponse = validation(inputValue);
      setIsError(validationResponse.isError);
      setErrorMessage(validationResponse.errorMessage);
    }
  };

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    validate(inputValue);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value;
    validate(inputValue);
  };

  useEffect(() => {
    dispatch(setFormError(isError));
  }, [isError, dispatch]);

  useEffect(() => {
    if (validateIfTouched && isTouched) return;
    if (currentValue === '' && isError) {
      setIsError(false);
      setErrorMessage('');
      dispatch(setFormError(false));
    }
  }, [currentValue, dispatch, isError]);
  return {
    isError,
    errorMessage,
    onBlur,
    onKeyDown,
    validate,
    setIsTouched,
    isTouched,
  };
};
