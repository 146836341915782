import { gql } from '@apollo/client';
import { NOTIFICATION_FRAGMENT } from './notifications.fragments';
import { ERROR_FRAGMENT } from '../fragments';

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAsRead($notifications: MarkUserNotificationsAsReadInput!) {
    markUserNotificationsAsRead(notifications: $notifications) {
      data {
        _id
        userId
        notification {
          ...NotificationFragment
        }
        read
      }
      error {
        ...ErrorFragment
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($input: NotificationInput!) {
    createNotification(input: $input) {
      title
      subtitle
      description
      type
      category
      _id
      status
      details {
        flightId
        station
        times {
          local12
          local24
          utc12
          utc24
        }
        schedule {
          sendDate
          deleteDate
        }
        createdBy
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($input: NotificationUpdateInput!) {
    updateNotification(input: $input) {
      title
      subtitle
      description
      type
      category
      _id
      details {
        flightId
        station
        times {
          local12
          local24
          utc12
          utc24
        }
        schedule {
          sendDate
          deleteDate
        }
        createdBy
      }
      createdAt
      updatedAt
      status
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notificationId: String) {
    deleteNotification(notificationId: $notificationId) {
      deleted
    }
  }
`;
