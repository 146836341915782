import { useMutation } from '@apollo/client';
import { CREATE_NOTIFICATION } from '../../graphql/notifications/notifications.mutations';
import {
  Mutation,
  Notification,
  NotificationInput,
} from '../generated/graphql';
import { IMutationResponse, MutationOptions } from '../types';

interface CreateNotificationResponse extends IMutationResponse<Notification> {
  onCreateNotification: (
    notification: NotificationInput
  ) => Promise<Notification>;
}

export const useCreateNotification = (
  options?: MutationOptions
): CreateNotificationResponse => {
  const [createNotification, { data, error, loading }] = useMutation<
    Pick<Mutation, 'createNotification'>
  >(CREATE_NOTIFICATION, options);

  const onCreateNotification = async (notification: NotificationInput) => {
    try {
      const response = await createNotification({
        variables: {
          input: notification,
        },
      });
      return response?.data?.createNotification?.[0] ?? null;
    } catch (err) {
      return null;
    }
  };

  return {
    onCreateNotification,
    data: data?.createNotification?.[0] ?? null,
    error,
    loading,
  };
};
