import { useQuery } from '@apollo/client';
import { Process, Query, DataError } from '../generated/graphql';
import { IDataResponse } from '../types';
import { PROCESS } from '../../graphql/flightDetails/flight-details.queries';
import useNetworkStatus from './useNetworkStatus';

interface IGetProcessg extends IDataResponse<Process> {
  refetchProcess: () => Promise<Process | null>;
  loadingFirstTime: boolean;
  responseErrors: DataError[] | null;
}

export const useGetProcess = (flightId?: string): IGetProcessg => {
  const isOffline = !useNetworkStatus();
  const { data, loading, error, networkStatus, refetch } = useQuery<
    Pick<Query, 'process'>
  >(PROCESS, {
    variables: {
      flightId,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    skip: !flightId || isOffline,
  });

  const refetchProcess = async () => {
    const result = await refetch();

    return result?.data?.process?.data ?? null;
  };

  const convertedErrors =
    data?.process?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ?? null;

  return {
    data: data?.process?.data ?? null,
    loading,
    error,
    responseErrors: convertedErrors,
    refetchProcess,
    loadingFirstTime: networkStatus === 1,
  };
};
