import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const MAX_SECONDS_COUNTDOWN = 30;
const MIN_SECONDS_COUNTDOWN = 0;

export const reloadReducer = createSlice({
  name: 'reload',
  initialState: {
    countdown: MAX_SECONDS_COUNTDOWN,
  },
  reducers: {
    decrementCountdown: (state) => {
      state.countdown = state.countdown - 1;
    },
    refreshCountdown: (state) => {
      state.countdown = MAX_SECONDS_COUNTDOWN;
    },
    setCountdownToZero: (state) => {
      state.countdown = MIN_SECONDS_COUNTDOWN;
    },
  },
});

export const { decrementCountdown, refreshCountdown, setCountdownToZero } =
  reloadReducer.actions;
export const selectCountdown = (state: RootState) =>
  state.reloadReducer.countdown;
export default reloadReducer.reducer;
