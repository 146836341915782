import React, { ReactElement, useEffect } from 'react';
import { useMqtt } from '../../utils/hooks/useMqtt';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { selectUserId } from '../../redux/reducers/authReducer';
import { useGetMyFlightsCached } from '../../utils/hooks/useGetMyFlightsCached';
import {
  select24Format,
  selectRoles,
  selectStationsList,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { setCheckedinUsers } from '../../redux/reducers/checkinReducer';
import { useGetCheckedInRoles } from '../../utils/hooks/useGetCheckedInRoles';
import {
  onMessage,
  subscribeToFlight,
  subscribeToFlightCheckin,
} from '../../utils/notifications/subscribeToFlight';
import { useGetChatMessagesLazy } from '../../utils/hooks/useGetChatMessages';
import {
  selectLastStatusUpdate,
  selectNewChatMessagesReceived,
  selectUpdatedChatMessagesReceived,
} from '../../redux/reducers/chatReducer';
import { subscribeToChat } from '../../utils/notifications/subscribeToChat';
import { ChatUpdateType } from '../../models/ChatUpdateType';
import { getCarrierFromFlighId } from '../../utils/helpers';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';
import { Permission } from '../../utils/generated/graphql';
import { subscribeToHighlander } from '../../utils/notifications/subscribeToHighlander';
import { subscribeToRoles } from '../../utils/notifications/subscribeToRole';
import { subscribeToStations } from '../../utils/notifications/subscribeToStations';

interface IMqttProps {
  loggedIn: boolean;
  children: ReactElement;
}

const Mqtt: React.FC<IMqttProps> = (props) => {
  const { loggedIn, children } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector(selectUserId);
  const useUTC = useSelector(selectUTC);
  const use24Format = useSelector(select24Format);
  const userRoles = useSelector(selectRoles);
  const userStations = useSelector(selectStationsList);
  const { data: myFlights, refetchMyFlightsCache } =
    useGetMyFlightsCached(userId);
  const { data: checkedinFlights, refetchCheckInRoles } =
    useGetCheckedInRoles();
  // will be changes with useGetChatMessages once the role parameter is not needed
  const { getChatMessages } = useGetChatMessagesLazy();
  const lastStatusUpdate = useSelector(selectLastStatusUpdate);
  const newChatMessagesReceived = useSelector(selectNewChatMessagesReceived);
  const updatedChatMessageReceived = useSelector(
    selectUpdatedChatMessagesReceived
  );
  const messageHandler = UtilFactory.newMessageHandler();

  const hasFlightNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_FLIGHT_NOTIFICATIONS,
  ]);

  const hasChatReadAccess = useCheckPermissions([Permission.CHAT_READ]);
  const hasStationNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_STATION_NOTIFICATIONS,
  ]);

  const hasRoleNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_ROLE_NOTIFICATIONS,
  ]);

  useMqtt(loggedIn);

  const hasSystemNotificationsAccess = useCheckPermissions([
    Permission.RECEIVE_SYSTEM_NOTIFICATIONS,
  ]);

  useEffect(() => {
    if (
      process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED?.toUpperCase() !==
        'TRUE' &&
      hasSystemNotificationsAccess
    ) {
      const topic = `${MQTT_TOPIC.SYSTEM}all`;

      messageHandler.subscribe({
        topic,
        onMessage: onMessage(
          userId,
          dispatch,
          useUTC,
          use24Format,
          refetchMyFlightsCache
        ),
      });
    }

    if (
      process?.env?.REACT_APP_TAC_CHAT_FEATURE_DISABLED?.toUpperCase() !==
        'TRUE' &&
      hasChatReadAccess
    ) {
      subscribeToChat(userId, dispatch);
    }

    if (
      process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED?.toUpperCase() !==
        'TRUE' &&
      hasStationNotificationAccess
    ) {
      subscribeToStations(userId, userStations, useUTC, use24Format, dispatch);
    }

    if (
      process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED?.toUpperCase() !==
        'TRUE' &&
      hasRoleNotificationAccess
    ) {
      subscribeToRoles(userId, userRoles, useUTC, use24Format, dispatch);
    }
  }, [
    hasChatReadAccess,
    hasSystemNotificationsAccess,
    hasRoleNotificationAccess,
    hasStationNotificationAccess,
  ]);

  useEffect(() => {
    if (
      newChatMessagesReceived &&
      checkedinFlights &&
      checkedinFlights.length
    ) {
      getChatMessages(
        lastStatusUpdate,
        `TAC_${getCarrierFromFlighId(checkedinFlights[0]?.flightId ?? '')}_${
          checkedinFlights[0]?.myRole?.roleGroup ?? ''
        }`,

        dispatch,
        ChatUpdateType.NEW_MESSAGE
      );
    }
  }, [newChatMessagesReceived]);

  useEffect(() => {
    if (
      updatedChatMessageReceived &&
      checkedinFlights &&
      checkedinFlights.length
    ) {
      getChatMessages(
        lastStatusUpdate,
        `TAC_${getCarrierFromFlighId(checkedinFlights[0]?.flightId ?? '')}_${
          checkedinFlights[0]?.myRole?.roleGroup ?? ''
        }`,
        dispatch,
        ChatUpdateType.UPDATED_MESSAGE
      );
    }
  }, [updatedChatMessageReceived]);

  useEffect(() => {
    const favoriteFlights = myFlights?.filter((flight) => flight.isFavourite);
    if (
      favoriteFlights &&
      favoriteFlights.length &&
      hasFlightNotificationAccess
    ) {
      favoriteFlights?.forEach((flight) => {
        if (flight.flightId) {
          subscribeToFlight(
            flight.flightId,
            userId,
            dispatch,
            useUTC,
            use24Format,
            refetchMyFlightsCache
          );
        }
      });
    }
  }, [myFlights, hasFlightNotificationAccess]);

  useEffect(() => {
    const highlanderFlights = checkedinFlights
      ?.filter((flight) => flight?.myRole?.isHighlander)
      .map((flight) => flight?.flightId);
    const cachedCheckedInFlights = myFlights?.filter(
      (flight) => flight.isCheckedIn
    );
    if (cachedCheckedInFlights && cachedCheckedInFlights.length) {
      // the api call requires a role at the moment but returns all conversations
      cachedCheckedInFlights.forEach((flight) => {
        if (flight?.flightId) {
          subscribeToFlightCheckin(flight.flightId, refetchCheckInRoles);
        }
      });
    }
    if (!!highlanderFlights?.length) {
      highlanderFlights.forEach((flight) => {
        subscribeToHighlander(flight ?? '', userId, dispatch);
      });
    }
  }, [myFlights, checkedinFlights]);

  useEffect(() => {
    if (checkedinFlights && checkedinFlights.length) {
      dispatch(setCheckedinUsers(checkedinFlights));
      getChatMessages(
        null,
        `TAC_${getCarrierFromFlighId(checkedinFlights[0]?.flightId ?? '')}_${
          checkedinFlights[0]?.myRole?.roleGroup ?? ''
        }`,
        dispatch,
        ChatUpdateType.NEW_MESSAGE
      );
    }
  }, [checkedinFlights]);

  return children;
};

export default Mqtt;
