import { useState, useEffect, useCallback } from 'react';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const toggle = useCallback(() => setValue((v) => !v), []);

  return [value, toggle] as const;
};
