import React, { useEffect, useState } from 'react';
import StationContactItem from './StationContactItem';
import ListHeader from '../Chat/ChatHeader/ChatHeader';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import {
  NO_STATION_DATA,
  ROLES_SEARCH_PLACEHOLDER,
} from '../../utils/constants';
import {
  CacheTelephonyEntry,
  CachedRoles,
} from '../../utils/generated/graphql';

interface IStationContact {
  stationInfo: CacheTelephonyEntry | null;
  showEmptyMessage?: boolean;
  handleClose?: () => void;
  handleBack?: () => void;
}

const StationContact = ({
  stationInfo,
  showEmptyMessage = false,
  handleClose,
  handleBack,
}: IStationContact) => {
  const roles = stationInfo?.roles;
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState<CachedRoles[]>(roles ?? []);

  const containerClass =
    'flex flex-1 flex-col bg-white dark:bg-grey-90 mobile:h-dvh';

  const noFlightsClassNames =
    'self-center m-auto font-head-bold text-grey-25 text-24';

  const inputContainer =
    'p-4 pl-24 py-[13px] pl-14 mobile:ml-0 pr-32 w-full bg-white font-body-text border-b-1 border-b-grey-12 max-h-[66px] mobile:max-h-[76px] dark:bg-grey-90 dark:border-grey-40 dark:text-grey-12';

  const inputClassName =
    'text-16 border-grey-25 border-1 p-2 w-[100%] rounded-4 dark:bg-grey-90 focus:border-1 focus:border-primary focus:outline-none placeholder:normal-case dark:bg-grey-90 dark:border-grey-40 dark:text-grey-12';

  const handleSearchRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);

    const filter = roles?.filter((role) =>
      role.roleName.toLocaleLowerCase().includes(newValue.toLocaleLowerCase())
    );

    setSearchResult(filter ?? []);
  };

  const renderHeaderTelephony = () => {
    return (
      <ListHeader handleClose={handleClose} key={stationInfo?.station}>
        <Button
          Icon={
            <Icon
              height={14}
              width={14}
              variant="arrowBack"
              className="fill-primary dark:fill-white"
            />
          }
          className="flex items-center gap-8 tablet:hidden laptop:hidden desktop:hidden"
          textClassName="text-primary font-head-bold text-14 dark:text-white"
          onClick={handleBack}
        />
        <div className="text-primary text-18 font-head-bold dark:text-grey-12">
          {!showEmptyMessage && stationInfo?.station}
        </div>
      </ListHeader>
    );
  };

  const renderSearch = () => {
    return (
      <div className={inputContainer}>
        <input
          key="search"
          type="text"
          className={inputClassName}
          placeholder={ROLES_SEARCH_PLACEHOLDER}
          value={searchValue}
          onChange={handleSearchRole}
        />
      </div>
    );
  };

  const renderMappedRoles = () => {
    return searchResult?.map((role) => {
      return (
        <StationContactItem
          key={role.roleName}
          users={role.users}
          roleName={role.roleName}
        />
      );
    });
  };

  const renderRolesTelephony = () => {
    return searchResult ? (
      <div className="overflow-y-auto">{renderMappedRoles()}</div>
    ) : (
      <div className={noFlightsClassNames}>{NO_STATION_DATA}</div>
    );
  };

  return (
    <div className={containerClass}>
      {!showEmptyMessage ? (
        [renderHeaderTelephony(), renderSearch(), renderRolesTelephony()]
      ) : (
        <>
          <div className={noFlightsClassNames}>{NO_STATION_DATA}</div>
        </>
      )}
    </div>
  );
};

export default StationContact;
