import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import {
  BOARDING_REASONS_ERROR,
  BOARDING_TIME,
  UPDATE_BOARDING_ERROR,
} from '../../utils/constants';
import Icon from '../Icon/Icon';
import TimePickerInput from './TimePickerInput';
import MenuHeader from '../Navigation/Header/MenuHeader/MenuHeader';
import { setIsMenuOpen } from '../../redux/reducers/headerReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBoardingReasons } from '../../utils/hooks/useGetBoardingReasons';
import { BoardingReason, TimeObject } from '../../utils/generated/graphql';
import { useUpdateBoardingTime } from '../../utils/hooks/useUpdateBoardingTime';
import { selectUTC } from '../../redux/reducers/settingsReducer';
import { getFlightName } from '../../utils/helpers';
import moment from 'moment';
import { addError } from '../../redux/reducers/notificationReducer';
import { setIsBoardingTimeOpen } from '../../redux/reducers/boardingTimeReducer';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const MAX_NO_REASONS = 2;
const MAX_REASONS_SINGLE = 1;

interface IBoardingTime {
  departureTimes: TimeObject;
  handleCloseBoardingTime: () => void;
  flightId: string;
  airlineColor: string;
  carrier: string;
}

interface IReason extends BoardingReason {
  isSelected: boolean;
}

const generateReasonsArray = (data): IReason[] => {
  return data.map((elem: IReason) => ({
    id: elem.id,
    reason: elem.reason,
    single: elem.single,
    isSelected: false,
  }));
};

const BoardingTime = ({
  departureTimes,
  handleCloseBoardingTime,
  flightId,
  airlineColor,
  carrier,
}: IBoardingTime) => {
  const dispatch = useDispatch();

  const { data, error, loading } = useGetBoardingReasons(carrier);
  const { loading: isLoadingUpdateTime, onUpdateBoardingTime } =
    useUpdateBoardingTime();

  const [boardingTimeReasons, setBoardingTimeReasons] = useState(
    generateReasonsArray(data?.reasons ?? [])
  );
  const [maxReasons, setMaxReasons] = useState(MAX_NO_REASONS);

  const isUTC = useSelector(selectUTC);

  const initialTimeString = (
    isUTC ? departureTimes.UTCTime : departureTimes.localTime
  )?.replace(' ', '');
  const parsedDate = moment.parseZone(initialTimeString);
  const timezoneOffset = parsedDate.utcOffset();

  const initialTime = isUTC
    ? moment().utc()
    : moment().utc().utcOffset(timezoneOffset);

  const [boardingTimeEstimated, setBoardingTimeEstimated] =
    useState(initialTime);

  const [disabledSend, setDisabledSend] = useState(false);

  const boardingTimeRef = useRef(boardingTimeEstimated);

  const checkSendDisabled = () => {
    const isPastDate = boardingTimeRef.current
      .clone()
      .set('seconds', 1)
      .isBefore(moment().set('seconds', 0));
    setDisabledSend(isPastDate);
    return isPastDate;
  };

  useEffect(() => {
    boardingTimeRef.current = boardingTimeEstimated.clone();

    checkSendDisabled();
  }, [boardingTimeEstimated]);

  useEffect(() => {
    const ONE_MINUTE = 60000;
    const now = moment();
    const nextMinute = now.clone().add(1, 'minute').startOf('minute');
    const timeoutDuration = nextMinute.diff(now);
    let intervalId;
    const timeoutId = setTimeout(() => {
      checkSendDisabled();
      intervalId = setInterval(() => {
        checkSendDisabled();
      }, ONE_MINUTE);
    }, timeoutDuration);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      clearTimeout(timeoutId);
    };
  }, []);
  const calculatedDays = Math.max(
    boardingTimeEstimated.diff(initialTime.clone().startOf('day'), 'days'),
    0
  );

  useEffect(() => {
    !loading &&
      setBoardingTimeReasons(generateReasonsArray(data?.reasons ?? []));

    !loading && error && dispatch(addError(BOARDING_REASONS_ERROR));
  }, [loading]);

  const noReasonsSelected = boardingTimeReasons.filter(
    (reason) => reason.isSelected
  ).length;

  const isBelowLimit = noReasonsSelected < maxReasons;

  const toggleSelect = (id: string) => {
    setBoardingTimeReasons((prevReasons) => {
      const noSelectedReasons = boardingTimeReasons.reduce(
        (noSelectedReasons, reason) => {
          return reason.isSelected ? ++noSelectedReasons : noSelectedReasons;
        },
        0
      );

      return prevReasons.map((reason) => {
        const maxReasonsNo =
          reason.single === 'true' ? MAX_REASONS_SINGLE : maxReasons;
        if (
          reason.id === id &&
          (noSelectedReasons < maxReasonsNo || reason.isSelected)
        ) {
          setMaxReasons(
            reason.single === 'true' ? MAX_REASONS_SINGLE : MAX_NO_REASONS
          );
          return { ...reason, isSelected: !reason.isSelected };
        }
        return reason;
      });
    });
  };

  useEffect(() => {
    return () => handleCloseBoardingTime();
  }, []);

  const buttonClassNames = classNames('rounded-4 bg-green w-full  mb-32', {
    'bg-grey-12':
      noReasonsSelected === 0 || isLoadingUpdateTime || disabledSend,
    'py-[6px]': isLoadingUpdateTime,
    'py-12': !isLoadingUpdateTime,
  });

  const onConfirmButtonClick = async () => {
    const boardingNotReadyTime = moment
      .utc()
      .set({ second: 0, millisecond: 0 });

    const response = await onUpdateBoardingTime({
      boardingNotReadyTime,
      boardingTimeEstimated: boardingTimeEstimated.clone().utc(),
      reasons: boardingTimeReasons
        .filter((elem) => elem.isSelected)
        .map((elem) => elem.id ?? ''),
      flightId,
    });

    setBoardingTimeReasons(generateReasonsArray(data?.reasons ?? []));

    if (response?.timeUpdated) {
      handleCloseBoardingTime();
    } else if (!response?.timeUpdated && response?.errors.length) {
      response.errors.forEach((error) => dispatch(addError(error.message)));
    } else if (!response?.timeUpdated && !response?.errors.length) {
      dispatch(addError(UPDATE_BOARDING_ERROR));
    }
  };

  const isItemDisabled = (boardingReason: IReason) => {
    return (
      !boardingReason.isSelected &&
      (!isBelowLimit ||
        (boardingReason.single === 'true' &&
          noReasonsSelected === MAX_REASONS_SINGLE))
    );
  };

  return (
    <div className="h-screen flex flex-col relative dark:bg-grey-90">
      <MenuHeader
        className="tablet:hidden laptop:hidden desktop:hidden"
        themeColor={classNames(airlineColor, 'dark:bg-grey-90')}
        onMenuClick={() => {
          dispatch(setIsMenuOpen(true));
        }}
        hasBackArrow
        onBackArrowClick={() => {
          dispatch(setIsBoardingTimeOpen(false));
        }}
      />
      <div className="p-16">
        <div className="flex justify-between content-center items-center border-b-grey-12 border-b-1 pb-16">
          <div>
            <div className="text-14 text-primary dark:text-white font-body-text font-semibold uppercase">
              {BOARDING_TIME.TITLE}
            </div>
            <div className="text-12 text-primary dark:text-white font-bold font-body-text">
              {getFlightName(flightId)}
            </div>
          </div>
          <Button
            text={BOARDING_TIME.CANCEL}
            className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
            textClassName="text-14 !text-primary font-body-text leading-[18px]"
            onClick={handleCloseBoardingTime}
          />
        </div>
        <TimePickerInput
          dateTime={boardingTimeEstimated}
          setDateTime={setBoardingTimeEstimated}
          delayDays={calculatedDays}
        />
      </div>
      <div className="text-12 text-grey-40 dark:text-white font-body-text px-16 pb-16">
        {BOARDING_TIME.DELAY_REASONS}
      </div>
      <div className="overflow-y-auto flex-1 px-16">
        {boardingTimeReasons.map((boardingReason) => (
          <li
            key={boardingReason.id}
            className={classNames(
              'list-none text-18 text-primary font-body-text border-b-1 border-b-grey-12 py-4 last:border-b-0 flex justify-between',
              {
                'opacity-50': isItemDisabled(boardingReason),
                'opacity-100': isBelowLimit,
              }
            )}>
            <input
              id={boardingReason.id ?? ''}
              type="checkbox"
              className="hidden"
              disabled={isItemDisabled(boardingReason)}
              onChange={() => toggleSelect(boardingReason.id ?? '')}
            />
            <label htmlFor={boardingReason.id ?? ''} className="w-full">
              {boardingReason.reason}
            </label>
            {boardingReason.isSelected && (
              <Icon
                variant="checkmark"
                className="dark:fill-white top-0 bottom-0 my-auto"
              />
            )}
          </li>
        ))}
      </div>
      <div className="w-full box-border bg-white dark:bg-grey-90 px-56">
        <Button
          text={
            isLoadingUpdateTime ? (
              <LoadingSpinner width={25} height={25} />
            ) : (
              BOARDING_TIME.SEND
            )
          }
          className={buttonClassNames}
          textClassName={classNames(
            'font-body-text font-bold text-white text-14',
            {
              'dark:text-grey-40': !noReasonsSelected || disabledSend,
            }
          )}
          disabled={
            noReasonsSelected === 0 || isLoadingUpdateTime || disabledSend
          }
          onClick={onConfirmButtonClick}
        />
      </div>
    </div>
  );
};

export default BoardingTime;
