import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATION } from '../../graphql/notifications/notifications.mutations';
import {
  Mutation,
  Notification,
  NotificationUpdateInput,
} from '../generated/graphql';
import { IMutationResponse, MutationOptions } from '../types';

interface UpdateNotificationResponse extends IMutationResponse<Notification> {
  onUpdateNotification: (
    data: NotificationUpdateInput
  ) => Promise<Notification | null>;
}

export const useUpdateNotification = (
  options?: MutationOptions
): UpdateNotificationResponse => {
  const [updateNotification, { data, error, loading }] = useMutation<
    Pick<Mutation, 'updateNotification'>
  >(UPDATE_NOTIFICATION, options);

  const onUpdateNotification = async (data: NotificationUpdateInput) => {
    try {
      const result = await updateNotification({
        variables: {
          input: data,
        },
      });
      return result.data?.updateNotification ?? null;
    } catch (err) {
      return null;
    }
  };

  return {
    onUpdateNotification,
    data: data?.updateNotification ?? null,
    loading,
    error,
  };
};
