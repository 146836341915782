import { gql } from '@apollo/client';

export const SEARCH_FLIGHTS = gql`
  query SearchFlights($genericQuery: String) {
    flightSearch(genericQuery: $genericQuery) {
      data {
        arrivalTimes {
          UTCTime
          UTCDiff
          airportCode
          bestTimeLocal
          bestTimeLocal12format
          bestTimeUTC
          bestTimeUTC12format
          localTime
        }
        closestDeparture
        departureDetails {
          departureTime {
            time
            delay
            time12format
            timeLeft
            timeLogicUsed
            timeString
          }
          gate
          position {
            value
          }
          type
        }
        departureTimes {
          UTCDiff
          UTCTime
          bestTimeUTC
          bestTimeUTC12format
          localTime
          bestTimeLocal
          bestTimeLocal12format
          airportCode
        }
        flightDetails {
          flightName
          departureAirport
          arrivalAirport
          flightStatus
        }
        flightId
        isFavourite
        isCheckedIn
        scheduledDepartureTime
      }
      errors {
        error
        message
      }
    }
  }
`;
