import { gql } from '@apollo/client';
export const ETD_DELAYS = gql`
  query EtdDelayCodes($carrier: String!) {
    etdDelayCodes(carrier: $carrier) {
      carrier
      code
      subcode
      description
    }
  }
`;
