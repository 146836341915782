import React from 'react';
import LinkIcon from './LinkIcon/LinkIcon';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import classNames from 'classnames';

const linksContainer =
  'max-w-[716px] dark:bg-grey-90 bg-white rounded-8 flex gap-x-[64px] mobile:gap-x-[60px] gap-y-16 p-24 flex-wrap';

interface IMyLinks {
  linkArray: string[];
}

const MyLinks = ({ linkArray = [], ...others }: IMyLinks) => {
  const isOnline = useNetworkStatus();
  return (
    <div className={linksContainer} {...others}>
      {linkArray.map((item) => (
        <LinkIcon
          key={item}
          variant={item}
          className={classNames({
            'cursor-none pointer-events-none': !isOnline,
          })}
        />
      ))}
    </div>
  );
};

export default MyLinks;
