import React from 'react';
import './PulseNotificationIcon.scss';

interface IPulseNotificationIcon {
  className?: string;
}

const PulseNotificationIcon = (props: IPulseNotificationIcon) => {
  const { className } = props;

  return (
    <div className={className}>
      <div className="ring-container">
        <div className="ringring border-secondaryFade"></div>
        <div className="circle bg-secondary"></div>
      </div>
    </div>
  );
};

PulseNotificationIcon.displayName = 'Pulse Notification Icon';

export default PulseNotificationIcon;
