import { useEffect, useState } from 'react';
import {
  DESKTOP_BREAKPOINT,
  DEVICE_TYPE,
  MOBILE_BREAKPOINT,
} from '../constants';

const calculateTypeTruthy = (deviceType: DEVICE_TYPE) => {
  let type = false;
  switch (deviceType) {
    case DEVICE_TYPE.DESKTOP:
      type = window.innerWidth >= DESKTOP_BREAKPOINT;
      break;
    case DEVICE_TYPE.TABLET:
      type =
        window.innerWidth < DESKTOP_BREAKPOINT &&
        window.innerWidth > MOBILE_BREAKPOINT;
      break;
    case DEVICE_TYPE.MOBILE:
      type = window.innerWidth <= MOBILE_BREAKPOINT;
      break;

    default: {
      type = false;
    }
  }
  return type;
};

export const useDeviceBreakpoint = (deviceType: DEVICE_TYPE) => {
  const isDeviceByType = calculateTypeTruthy(deviceType);

  const [isFitting, setIsFitting] = useState(isDeviceByType);

  const handleResize = () => {
    setIsFitting(isDeviceByType);
  };

  useEffect(() => {
    setIsFitting(isDeviceByType);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isDeviceByType]);

  return isFitting;
};
