import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../redux/reducers/authReducer';
import {
  selectAllNotifications,
  selectShowSpecificNotificationsOnly,
  selectSortingCriteria,
} from '../../redux/reducers/newsfeedReducer';
import {
  handleDepartureSort,
  handleNotificationTimeSort,
  handleTimeSort,
} from '../../utils/notifications/notificationsUtil';
import Newsfeed from './Newsfeed';
import { useGetMyFlightsCached } from '../../utils/hooks/useGetMyFlightsCached';
import { selectStationsList } from '../../redux/reducers/settingsReducer';
import { useParams } from 'react-router-dom';

interface INewsfeedContainer {
  handleCloseNotification: () => void;
}

const NewsfeedContainer = ({ handleCloseNotification }: INewsfeedContainer) => {
  const { flight } = useParams();
  const userId = useSelector(selectUserId);
  const stationsList = useSelector(selectStationsList);
  const notifications = useSelector(selectAllNotifications);
  const sortingCriteria = useSelector(selectSortingCriteria);
  const { data } = useGetMyFlightsCached(userId);
  const showSpecificNotificationsOnly = useSelector(
    selectShowSpecificNotificationsOnly
  );

  const myFlightsOrder = useMemo(
    () => data?.map((flight) => flight.flightId) || [],
    [data]
  );

  const sortedNotifications = useMemo(() => {
    const sortedInnerNotification = handleNotificationTimeSort(
      notifications,
      'time-ASC'
    );
    if (sortingCriteria.includes('time')) {
      return handleTimeSort(sortedInnerNotification, sortingCriteria);
    }

    return handleDepartureSort(
      sortedInnerNotification,
      stationsList,
      sortingCriteria,
      myFlightsOrder
    );
  }, [myFlightsOrder, notifications, sortingCriteria]);

  return (
    <Newsfeed
      isFlightSpecific={showSpecificNotificationsOnly}
      handleCloseNotification={handleCloseNotification}
      notificationsList={
        showSpecificNotificationsOnly
          ? [notifications?.[flight ?? '']]
          : Object.values(sortedNotifications || [])
      }
      isOverlay={true}
      myFlights={data ?? []}
    />
  );
};

export default NewsfeedContainer;
