import React, { ChangeEvent, useState } from 'react';
import {
  CONFIRM,
  EDITING_PHONE_NUMBER,
  EDITING_PHONE_NUMBER_COPY,
  MY_PROFILE,
} from '../../../utils/constants';
import Input from '../Input/Input';
import Grid from '../../Grid/Grid';
import { useSelector } from 'react-redux';
import { validateNames, validatePhone } from '../../../utils/helpers';
import { selectAuthReducer } from '../../../redux/reducers/authReducer';
import Title from '../../Title/Title';
import Modal from '../../Modal/Modal';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';

interface IProfileData {
  firstName: string;
  phoneNumber: string;
}

interface IMyProfile {
  profileData: IProfileData;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const containerClassNames =
  'bg-white rounded-8 px-24 pt-[21px] pb-24 dark:bg-grey-90';
enum EditStatus {
  INITIAL,
  DECLINED,
  ACCEPTED,
}
const MyProfile = ({ handleChange, profileData, ...others }: IMyProfile) => {
  const tokenSettings = useSelector(selectAuthReducer);
  const { firstName, phoneNumber } = profileData;
  const [editStatus, setEditStatus] = useState(EditStatus.INITIAL);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAcceptEditing = () => {
    setEditStatus(EditStatus.ACCEPTED);
    setIsModalOpen(false);
  };

  const isOffline = !useNetworkStatus();

  return (
    <div className={containerClassNames} {...others}>
      <Title
        title={MY_PROFILE.TITLE}
        titleColorClass={'text-primary text-14 font-head-light'}
        className="pb-32"
      />
      <Grid col={[3, 64, 24]} mobile={[1, 0, 24]}>
        <Input
          inputName="name"
          currentValue={tokenSettings.tokenName}
          handleChange={(e) => handleChange(e)}
          title={MY_PROFILE.NAME}
          disabled={true}
        />

        <Input
          inputName="firstName"
          currentValue={firstName}
          handleChange={(e) => handleChange(e)}
          title={MY_PROFILE.FIRST_NAME}
          validation={validateNames}
          disabled={isOffline}
        />

        <Input
          inputName="phoneNumber"
          currentValue={phoneNumber}
          handleChange={(e) => handleChange(e)}
          handleFocus={() => {
            if (editStatus === EditStatus.INITIAL) {
              setIsModalOpen(true);
            }
          }}
          title={MY_PROFILE.PHONE}
          validation={validatePhone}
          disabled={isOffline}
        />

        <Input
          inputName="email"
          currentValue={tokenSettings.tokenEmail}
          handleChange={(e) => handleChange(e)}
          title={MY_PROFILE.EMAIL}
          disabled={true}
        />

        <Input
          inputName="username"
          currentValue={tokenSettings.userId}
          title={MY_PROFILE.USERNAME}
          disabled={true}
        />
        <Modal
          title={EDITING_PHONE_NUMBER}
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
          onSubmit={handleAcceptEditing}
          submitText={CONFIRM}>
          <p className="my-32">{EDITING_PHONE_NUMBER_COPY}</p>
        </Modal>
      </Grid>
    </div>
  );
};

export default MyProfile;
