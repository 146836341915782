import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import {
  formatNotificationTime,
  formatNotificationsDescription,
} from '../../../utils/notifications/notificationsUtil';
import { UserNotification } from '../../../utils/generated/graphql';

const Notification = ({
  createdAt,
  notification,
  read = false,
  ...others
}: UserNotification) => {
  const use24Format = useSelector(select24Format);
  const useUTC = useSelector(selectUTC);

  const containerClassNames = classNames(
    'w-full min-h-[135px] p-24 bg-white dark:bg-grey-90 rounded-4',
    { 'border-l-6 border-secondary': !read }
  );
  const titleContainerClassNames = classNames(
    'flex justify-between leading-[19px] mb-16'
  );

  return (
    <div className={containerClassNames} {...others}>
      <div className={titleContainerClassNames}>
        <div className={'font-head-bold text-18 text-primary dark:text-white'}>
          {notification.title}
        </div>
        <div className={'font-body-text text-12 text-grey-40'}>
          {formatNotificationTime(createdAt, use24Format)}
        </div>
      </div>
      <div className={'font-body-bold text-14 text-grey-80 dark:text-white'}>
        {notification.subtitle}
      </div>
      <div className={'font-body-text text-14 text-grey-60'}>
        {formatNotificationsDescription(notification, { useUTC, use24Format })}
      </div>
    </div>
  );
};

export default Notification;
