import React from 'react';
import FlightCrewItem from './FlightCrewItem/FlightCrewItem';
import Title from '../Title/Title';
import {
  FLIGHT_CREW,
  NO_CREW_DEADHEAD_DATA,
  NO_CREW_ON_DUTY_DATA,
} from '../../utils/constants';
import { IFlightCrewItem } from './FlightCrewItem/FlightCrewItem';

interface IFlightCrew {
  className?: string;
  crewTitle: string;
  crewList?: IFlightCrewItem[] | null;
  hasNoData: boolean;
}

const FlightCrew = ({
  className,
  crewTitle,
  crewList,
  hasNoData,
  ...others
}: IFlightCrew) => {
  const containerClass = className;

  return (
    <div className={containerClass} {...others}>
      <Title title={crewTitle} className="mb-16 mt-32" />

      {hasNoData && (
        <h1>
          {crewTitle === FLIGHT_CREW.CREW_ON_DUTY
            ? NO_CREW_ON_DUTY_DATA
            : NO_CREW_DEADHEAD_DATA}
        </h1>
      )}
      {crewList?.map((item) => {
        return (
          <FlightCrewItem key={item.id} {...item} className="mb-16 mt-32" />
        );
      })}
    </div>
  );
};

export default FlightCrew;
