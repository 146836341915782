import { Dispatch } from '@reduxjs/toolkit';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import UtilFactory from '../../factory/UtilFactory';
import {
  setNewChatMessagesReceived,
  setUpdatedChatMessagesReceived,
} from '../../redux/reducers/chatReducer';
import { ChatUpdateType } from '../../models/ChatUpdateType';

const messageHandler = UtilFactory.newMessageHandler();

export const subscribeToChat = (userId: string, dispatch: Dispatch) => {
  const topic = `${MQTT_TOPIC.CHAT}${userId}`;
  messageHandler.subscribe({
    topic,
    onMessage: async (topic: string, message: string) => {
      const chatUpdate = JSON.parse(message);
      if (
        chatUpdate?.updateType === ChatUpdateType.NEW_MESSAGE &&
        chatUpdate?.hasNewMessage
      ) {
        dispatch(setNewChatMessagesReceived());
      } else if (
        chatUpdate?.updateType === ChatUpdateType.UPDATED_MESSAGE &&
        !chatUpdate?.hasNewMessage
      ) {
        dispatch(setUpdatedChatMessagesReceived());
      }
    },
  });
};

export const unsubscribeToChat = (userId: string) => {
  const topic = `${MQTT_TOPIC.CHAT}${userId}`;
  messageHandler.unsubscribe(topic);
};
