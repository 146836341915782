import { useQuery } from '@apollo/client';
import { GET_ALL_NOTIFICATIONS } from '../../graphql/notifications/notifications.quries';
import { Notification, NotificationType, Query } from '../generated/graphql';
import { IDataResponse } from '../types';

interface IGetNotifications extends IDataResponse<Notification[]> {
  refetchNotifications: () => Promise<Notification[] | null>;
}

export const useGetAllNotifications = (
  types: NotificationType[]
): IGetNotifications => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'notifications'>
  >(GET_ALL_NOTIFICATIONS, { variables: { type: types }, skip: !types.length });

  const refetchNotifications = async () => {
    const result = await refetch();

    return result?.data?.notifications ?? null;
  };

  return {
    data: data?.notifications ?? [],
    loading,
    error,
    refetchNotifications,
  };
};
