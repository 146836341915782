import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '../fragments';

export const SEND_MESSAGE = gql`
  mutation SendChatMessage($input: SendChatMessageInput) {
    sendChatMessage(input: $input) {
      messageId
      status
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
