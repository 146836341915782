import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { VARIANTS } from '../../utils/constants';
import { RootState } from '../store';

interface IToastNotification {
  id: string;
  variant: string;
  text: string;
}
interface INotificationReducerState {
  notifications: IToastNotification[];
}

const initialState: INotificationReducerState = {
  notifications: [],
};

export const notificationReducer = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    addError: (state, action) => {
      const { payload } = action;

      const isDuplicate = state.notifications.some(
        (notification) => notification.text === payload
      );

      if (!isDuplicate) {
        const newNotification = {
          id: uuidv4(),
          variant: VARIANTS.ERROR,
          text: payload,
        };

        state.notifications.push(newNotification);
      }
    },
    addSuccess: (state, action) => {
      state.notifications = [];
      const newNotification = {
        id: uuidv4(),
        variant: VARIANTS.SUCCESS,
        text: action.payload,
      };
      state.notifications.push(newNotification);
    },
    addWarning: (state, action) => {
      state.notifications = [];
      const newNotification = {
        id: uuidv4(),
        variant: VARIANTS.WARNING,
        text: action.payload,
      };
      state.notifications.push(newNotification);
    },
    removeNotification: (state, action) => {
      const indexToRemove = state.notifications.findIndex(
        (notification) => notification.id === action.payload
      );
      if (indexToRemove !== -1) {
        state.notifications.splice(indexToRemove, 1);
      }
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  addError,
  addWarning,
  addSuccess,
  removeNotification,
  clearNotifications,
} = notificationReducer.actions;
export const selectNotifications = (state: RootState) =>
  state.notificationReducer.notifications;
export default notificationReducer.reducer;
