import { useSelector } from 'react-redux';
import { selectRoles } from '../../redux/reducers/settingsReducer';

const useCheckTechnicalRole = (technicalRole: string): boolean => {
  const userRoles = useSelector(selectRoles);

  return userRoles.some((value) => value.technicalRole === technicalRole);
};

export default useCheckTechnicalRole;
