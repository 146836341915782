import { useMutation } from '@apollo/client';
import { Mutation, MarkChatAsReadOutput } from '../generated/graphql';
import { IMutationResponse } from '../types';
import { CHAT_MARK_AS_READ } from '../../graphql/chat/mark-as-read-message.mutations';
import { useCallback } from 'react';

interface MarkAsReadResponse
  extends IMutationResponse<MarkChatAsReadOutput | null> {
  markChatAsRead: (
    lastStatusUpdate: string | null,
    conversationIds: string[],
    chatId: string
  ) => Promise<MarkChatAsReadOutput | null>;
  called: boolean;
}

export const useMarkChatAsRead = (): MarkAsReadResponse => {
  const [onMarkChatAsRead, { data, error, loading, called }] =
    useMutation<Pick<Mutation, 'markChatAsRead'>>(CHAT_MARK_AS_READ);

  const markChatAsRead = useCallback(
    async (
      lastStatusUpdate: string | null,
      conversationIds: string[],
      chatId: string
    ) => {
      try {
        if (called) {
          return null;
        }
        const response = await onMarkChatAsRead({
          variables: {
            input: {
              lastStatusUpdate,
              conversationIds,
              chatId,
            },
          },
        });
        if (response?.data) {
          return response.data.markChatAsRead ?? null;
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    []
  );
  return {
    markChatAsRead,
    data: data?.markChatAsRead ?? null,
    error: error,
    loading: loading,
    called: called,
  };
};
