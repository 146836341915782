import { useState, useEffect } from 'react';
import moment from 'moment';
import { EMPTY_STATE, INITIAL_STATE, ONE_HOUR } from '../constants';

/* 
  if displayUnits is true then time is displayed as [h]h [mm]m
  if displayUnits is false/undefined then time is displayed as h:mm
  */
export const setTimeLeft = (
  time: string | undefined,
  displayUnits?: boolean
) => {
  if (time === INITIAL_STATE) {
    return INITIAL_STATE;
  }
  if (!time) {
    return displayUnits ? INITIAL_STATE : EMPTY_STATE;
  }

  const timeDifference = moment(time).diff(moment());
  const duration = moment.duration(timeDifference, 'milliseconds');
  const totalHours = duration.asHours();
  const hours = Math.floor(totalHours);
  const minutes = Math.ceil(duration.asMinutes() % 60)
    .toString()
    .padStart(displayUnits ? 1 : 2, '0');

  if (minutes === '60') {
    const minutesFormatted = displayUnits ? '' : '00';
    const extraHour = hours + 1;
    const hoursFormatted = extraHour;
    const hoursFormattedString =
      hours > 0
        ? `${hoursFormatted.toString().padStart(1, '0')}h`
        : displayUnits
        ? ONE_HOUR
        : ``;
    const timeDifference = displayUnits
      ? `${hoursFormattedString} ${minutesFormatted}`
      : `${hoursFormatted.toString().padStart(2, '0')}:${minutesFormatted}`;
    return timeDifference;
  }

  const noTimeDifference = displayUnits ? '0m' : '0:00';
  const hoursString = hours > 0 ? `${hours.toString().padStart(1, '0')}h` : ``;
  const timeDifferenceFormatted = displayUnits
    ? `${hoursString} ${minutes}m`
    : `${hours.toString().padStart(2, '0')}:${minutes}`;
  return timeDifference < 0 ? noTimeDifference : timeDifferenceFormatted;
};

const useCountdown = (time: string | undefined, displayUnits?: boolean) => {
  const [countdown, setCountdown] = useState<null | string>(null);

  const formattedTime = time && moment(time?.replace(' ', '')).toISOString();

  useEffect(() => {
    setCountdown(setTimeLeft(formattedTime, displayUnits));
    const intervalId = setInterval(
      () => setCountdown(setTimeLeft(formattedTime, displayUnits)),
      6000
    );
    return () => clearInterval(intervalId);
  }, [time]);

  return countdown;
};

export default useCountdown;
