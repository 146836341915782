import { useLazyQuery } from '@apollo/client';
import {
  NotificationCategory,
  Query,
  UserNotification,
} from '../generated/graphql';
import { IDataResponse } from '../types';
import { GET_NOTIFICATIONS_HISTORY } from '../../graphql/notifications/notifications.quries';
import { useDispatch } from 'react-redux';
import { addNotifications } from '../../redux/reducers/newsfeedReducer';

interface IGetNotificationsHistoryResponse
  extends IDataResponse<UserNotification[]> {
  getNotificationsHistory: () => Promise<UserNotification[]>;
}

export const useGetNotificationsHistory =
  (): IGetNotificationsHistoryResponse => {
    const dispatch = useDispatch();
    const [get, { data, loading, error }] = useLazyQuery<
      Pick<Query, 'notificationsHistory'>
    >(GET_NOTIFICATIONS_HISTORY, {
      onCompleted(data) {
        dispatch(
          addNotifications(
            (data.notificationsHistory?.data ?? []).filter(
              (userNotification) =>
                userNotification.notification.category !==
                NotificationCategory.DEPARTED
            )
          )
        );
      },
    });
    const getNotificationsHistory = async () => {
      const result = await get();

      return result.data?.notificationsHistory?.data ?? [];
    };
    return {
      data: data?.notificationsHistory?.data ?? [],
      loading,
      error,
      getNotificationsHistory,
    };
  };
