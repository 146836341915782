import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import './Drawer.scss';

interface IDrawerProps {
  drawerId: string;
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
  size?: string;
}

const createPortalRoot = (drawerId) => {
  const drawerRoot = document.createElement('div');
  drawerRoot.setAttribute('id', `drawer-root-${drawerId}`);

  return drawerRoot;
};

const Drawer = ({
  drawerId,
  isOpen,
  children,
  className,
  onClose,
  size = 'small',
}: IDrawerProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const bodyRef = useRef(document.querySelector('body'));
  const portalRootRef = useRef(
    document.getElementById(`drawer-root-${drawerId}`) ||
      createPortalRoot(drawerId)
  );

  useEffect(() => {
    bodyRef.current?.appendChild(portalRootRef.current);
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current;

    return () => {
      portal.remove();
      if (bodyEl !== null) {
        bodyEl.style.overflow = '';
      }
    };
  }, []);

  useEffect(() => {
    const updatePageScroll = () => {
      if (bodyRef.current !== null) {
        if (isOpen) {
          bodyRef.current.style.overflow = 'hidden';
        } else {
          bodyRef.current.style.overflow = '';
        }
      }
    };
    isOpen && setIsClosing(false);
    updatePageScroll();
  }, [isOpen]);

  return createPortal(
    <div
      className={classNames('drawer-container', className, {
        open: isOpen,
      })}>
      <div
        className={classNames('drawer right mobile:w-[100%]  rounded-l-4', {
          'w-[30%]': size === 'small',
          'w-[75%]': size === 'large',
        })}>
        {!isClosing && children}
      </div>
      {isOpen ? (
        <div
          className="backdrop"
          onClick={() => {
            setIsClosing(true);
            onClose();
          }}
        />
      ) : null}
    </div>,
    portalRootRef.current
  );
};

export default Drawer;
