import React, { useState, ReactNode, TouchEvent, useRef } from 'react';
import './globals.scss';

interface IPullToRefresh {
  children: ReactNode;
}

function isUserAtTop() {
  return window.scrollY < 0;
}

const PullToRefresh = ({ children }: IPullToRefresh) => {
  const [startY, setStartY] = useState(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    const scrollPosition = document.getElementById('id-scroll')?.scrollTop;

    if (scrollPosition === 0) {
      setStartY(event.touches[0].clientY);
    }
  };

  const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    if (startY !== null) {
      const currentY = event.touches[0].clientY;
      const distance = currentY - startY;
      if (distance > 100 && isUserAtTop()) {
        window.location.reload();
      }
    }
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  return (
    <div
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className="hide-scrollbar"
      style={{ overflowY: 'scroll' }}>
      {children}
    </div>
  );
};

export default PullToRefresh;
