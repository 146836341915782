import React from 'react';
import classNames from 'classnames';
import { ChatColor, ChatPosition } from './ChatMessages';
import Icon from '../../../../Icon/Icon';
import { MessageStatus } from '../../../../../utils/generated/graphql';
import { FLIGHT_CHAT } from '../../../../../utils/constants';
import Button from '../../../../Button/Button';

interface IChatBubble {
  text: string;
  position: ChatPosition;
  color: ChatColor;
  showTail?: boolean;
  status: string;
  className?: string;
  retrySendMessage: () => void;
  deleteChatMessage: () => void;
  actionsDisabled: boolean;
}

const statusMapping = {
  DELIVERED: 'messageDelivered',
  FAILED: 'messageFailed',
  READ: 'messageRead',
  SENT: 'messageSent',
  SENDING: 'messageSending',
};

const ChatBubble = ({
  text,
  position,
  color,
  showTail = false,
  status,
  className,
  retrySendMessage,
  deleteChatMessage,
  actionsDisabled = false,
}: IChatBubble) => {
  return (
    <div
      className={classNames(className, 'relative', {
        'ml-32': position === 'left',
        'mr-0': position === 'right',
        'mb-8': !showTail,
        'mb-32 mobile:mb-0': showTail,
      })}>
      {position === 'right' && (
        <span className="w-32 h-32 flex items-center justify-center self-end">
          <Icon width={14} height={14} variant={statusMapping[status]} />
        </span>
      )}
      <pre
        className={classNames(
          'inline-block px-16 py-8 rounded-16 font-body-text text-14 max-w-[75%] break-words whitespace-pre-wrap',
          {
            'bg primary opacity-70': status === MessageStatus.FAILED,
            'bg-primary text-white dark:bg-grey-100': color === 'primary',
            'bg-grey-12 text-primary dark:text-grey-100': color === 'grey',
          }
        )}>
        {text}
      </pre>
      {status === MessageStatus.FAILED && position === 'right' && (
        <>
          <Button
            text={FLIGHT_CHAT.DELETE}
            onClick={() => deleteChatMessage()}
            className={classNames(
              'text-red text-14 font-body-text underline self-center mr-8',
              { 'opacity-50': actionsDisabled }
            )}
            disabled={actionsDisabled}
          />
          <Button
            text={FLIGHT_CHAT.RETRY}
            onClick={() => retrySendMessage()}
            className={classNames(
              'text-red text-14 font-body-text underline self-center mr-8',
              { 'opacity-50': actionsDisabled }
            )}
            disabled={actionsDisabled}
          />
        </>
      )}
      {showTail && (
        <div
          className={classNames(
            'absolute w-0 h-0 border-t-10 border-b-10 border-y-transparent',
            {
              '-bottom-10 right-48 border-r-10 border-r-primary dark:border-r-grey-100':
                position === 'right',
              '-bottom-10 left-48 border-l-10 border-r-grey-12':
                position === 'left',
            }
          )}></div>
      )}
    </div>
  );
};

export default ChatBubble;
