import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMqttState, getMqttToken } from '../../redux/reducers/mqttReducer';
import { AppDispatch } from '../../redux/store';
import UtilFactory from '../../factory/UtilFactory';
import { updateFlightNotificationsHistory } from '../../redux/reducers/newsfeedReducer';
import { setNewChatMessagesReceived } from '../../redux/reducers/chatReducer';

export const useMqtt = (isLoggedIn: boolean) => {
  const dispatch = useDispatch<AppDispatch>();
  const mqttState = useSelector(getMqttState);
  const randomUUID = Math.floor(Math.random() * 9000) + 1000;
  const clientId = mqttState.clientId
    ? mqttState.clientId
    : (process.env.REACT_APP_TAC_NOTIFICATIONS_CLIENT_ID ?? '') + randomUUID;

  const messageHandler = UtilFactory.newMessageHandler();

  const onReconnect = () => {
    dispatch(updateFlightNotificationsHistory());
    dispatch(setNewChatMessagesReceived());
  };

  const shouldUseNotifications = () =>
    process?.env?.REACT_APP_TAC_NOTIFICATIONS_FEATURE_DISABLED?.toUpperCase() !==
      'TRUE' && isLoggedIn;

  useEffect(() => {
    if (
      shouldUseNotifications() &&
      (!mqttState.mqttJwtToken || !mqttState.clientId)
    ) {
      dispatch(getMqttToken({ clientId }));
    }
  }, []);

  useEffect(() => {
    if (
      shouldUseNotifications() &&
      mqttState.mqttJwtToken &&
      mqttState.clientId
    ) {
      messageHandler.connect(
        mqttState.mqttJwtToken,
        mqttState.clientId,
        onReconnect
      );
    }
  }, [mqttState.mqttJwtToken, isLoggedIn, mqttState.clientId]);
};
