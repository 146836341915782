import React, {
  useState,
  TextareaHTMLAttributes,
  ForwardedRef,
  forwardRef,
} from 'react';
import LettersCounter from '../LettersCounter/LettersCounter';
import classNames from 'classnames';
import { INVALID_INPUT } from '../../utils/constants';
import { isNotificationInputValid } from '../../utils/helpers';

interface ITextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  areaClassName?: string;
  maxLength?: number;
  defaultValue?: string;
  rows?: number;
  lettersCounterActive?: boolean;
  onChange?: any;
  id?: string;
}

const TextArea = forwardRef(
  (
    {
      containerClassName,
      areaClassName,
      maxLength,
      defaultValue,
      rows,
      lettersCounterActive = true,
      onChange,
      id,
      ...props
    }: ITextArea,
    ref?: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [lettersCounter, setLettersCounter] = useState(0);
    const [isError, setIsError] = useState(false);
    const onBlurHandle = (e) => {
      const currentValue = e?.target?.value;

      isNotificationInputValid(currentValue)
        ? setIsError(false)
        : setIsError(true);
    };

    const textAreaContainerClass = classNames(containerClassName, {
      'border-red': isError,
    });
    const errorClassName = 'text-red pt-[4px]';
    return (
      <div className="grid w-full">
        <div className={textAreaContainerClass}>
          <textarea
            id={id}
            name="message"
            defaultValue={defaultValue ?? ''}
            rows={rows}
            className={areaClassName}
            onBlur={onBlurHandle}
            maxLength={maxLength}
            onChange={(e) => {
              setLettersCounter(e.target.value.length);
              onChange?.(e);
            }}
            ref={ref}
            {...props}
          />
          {lettersCounterActive && maxLength && (
            <LettersCounter
              maxNumber={maxLength}
              currentNumber={lettersCounter}
            />
          )}
        </div>
        {isError && <p className={errorClassName}>{INVALID_INPUT}</p>}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
export default TextArea;
