import React from 'react';
import { toast } from 'react-toastify';
import FlyInNotification from '../../components/FlyInNotification/FlyInNotification';
import { Notification } from '../generated/graphql';

export const createFlyInNotification = async (
  notification: Notification,
  isCheckedIn: boolean,
  useUTC: boolean,
  use24Format: boolean
) => {
  toast(
    <FlyInNotification
      notification={notification}
      isCheckedIn={isCheckedIn}
      use24Format={use24Format}
      useUTC={useUTC}
    />
  );
};
