import { gql } from '@apollo/client';
import { USER_SETTINGS_FRAGMENT } from '../fragments';

export const SET_USER_SETTINGS = gql`
  mutation SetUserSettings($userSettings: UserSettingsInput!) {
    addUserSettings(userSettings: $userSettings) {
      ...UserSettings
    }
  }
  ${USER_SETTINGS_FRAGMENT}
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings(
    $userSettings: UserSettingsInput!
    $sourceLocation: UpdateSettingsLocations!
  ) {
    updateUserSettings(
      userSettings: $userSettings
      sourceLocation: $sourceLocation
    ) {
      data {
        ...UserSettings
      }
      errors {
        code
        message
        description
      }
    }
  }
  ${USER_SETTINGS_FRAGMENT}
`;
