import React from 'react';
import { useSelector } from 'react-redux';
import useCountdown from '../../utils/hooks/useCountdown';
import FlightDetailsHeaderSticky from './FlightDetailsHeaderSticky';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { calculateTime } from '../../utils/calculateTime';
import { RootState } from '../../redux/store';

const FlightHeaderContainer = ({ ...others }) => {
  const {
    headerTypeTablet,
    flightName,
    departureAirport,
    arrivalAirport,
    type,
    registration,
    bestTimeLogic,
    departureTimes,
    themeColor,
    isCheckedIn,
  } = useSelector((state: RootState) => state.headerReducer);
  const timeLeft = useCountdown(bestTimeLogic?.timeLeft);

  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const time = calculateTime(isUTC, is24Format, departureTimes);

  return (
    <div {...others}>
      {headerTypeTablet && (
        <div className="">
          <FlightDetailsHeaderSticky
            flightName={flightName}
            type={type}
            departureAirport={departureAirport}
            arrivalAirport={arrivalAirport}
            registration={registration}
            time1={{
              value: time,
              extraDetails: bestTimeLogic?.delay
                ? `+${bestTimeLogic?.delay} min`
                : null,
            }}
            time2={timeLeft}
            themeColor={themeColor}
            isCheckedIn={isCheckedIn}
          />
        </div>
      )}
    </div>
  );
};

export default FlightHeaderContainer;
