import React from 'react';
import MenuHeader from '../Navigation/Header/MenuHeader/MenuHeader';
import classNames from 'classnames';
import Button from '../Button/Button';
import { getFlightName } from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setIsMenuOpen } from '../../redux/reducers/headerReducer';
import { setIsBriefingPackageOpen } from '../../redux/reducers/briefingPackageReducer';
import { BRIEFING_PACKAGE } from '../../utils/constants';
import Title from '../Title/Title';
import Icon from '../Icon/Icon';

interface IBriefingPackage {
  handleCloseBriefingPackage: () => void;
  flightId: string;
  airlineColor: string;
}

const downloadButtonClassName =
  'rounded-4 w-[-webkit-fill-available] py-12 mx-[50px] my-[30px] mt-[25px] bg-primary';

const downloadButtonTextClass = 'font-body-text font-bold text-white text-14';

const textColorClassName = 'text-primary text-14 font-body-text font-bold';

const BriefingPackage = ({
  handleCloseBriefingPackage,
  flightId,
  airlineColor,
}: IBriefingPackage) => {
  const dispatch = useDispatch();

  return (
    <div className="h-full overflow-y-clip flex flex-col relative dark:bg-grey-90">
      <MenuHeader
        className="tablet:hidden laptop:hidden desktop:hidden"
        themeColor={classNames(airlineColor, 'dark:bg-grey-90')}
        onMenuClick={() => {
          dispatch(setIsMenuOpen(true));
        }}
        hasBackArrow
        onBackArrowClick={() => {
          dispatch(setIsBriefingPackageOpen(false));
        }}
      />
      <div className="p-16 flex justify-between content-center items-center border-b-grey-12 border-b-1 pb-16">
        <div>
          <div className="text-14 text-primary dark:text-white font-body-text uppercase font-semibold">
            {BRIEFING_PACKAGE.TITLE}
          </div>
          <div className="text-12 text-primary dark:text-white font-bold font-body-text">
            {getFlightName(flightId)}
          </div>
        </div>
        <Button
          text={BRIEFING_PACKAGE.CANCEL}
          className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
          textClassName="text-14 !text-primary font-body-text leading-[18px]"
          onClick={handleCloseBriefingPackage}
        />
      </div>
      <div className="ml-[15px] overflow-y-auto">
        <div className="flex flex-row my-[50px]">
          <div className="place-content-start">
            <Icon width={50} height={50} variant="info" />
          </div>

          <Title
            title={BRIEFING_PACKAGE.INFO_TEXT}
            titleColorClass={
              'text-primary text-14 font-body-text leading-normal'
            }
            className="px-[20px]"
          />
        </div>
        <Title
          title={BRIEFING_PACKAGE.DIGITAL_BRIEFING}
          titleColorClass={textColorClassName}
        />

        <Button
          text={BRIEFING_PACKAGE.DOWNLOAD}
          className={downloadButtonClassName}
          textClassName={downloadButtonTextClass}
        />

        <Title
          title={BRIEFING_PACKAGE.PAPER_BRIEFING}
          titleColorClass={textColorClassName}
        />

        <Button
          text={BRIEFING_PACKAGE.DOWNLOAD}
          className={downloadButtonClassName}
          textClassName={downloadButtonTextClass}
        />
        {false && (
          <>
            <Title
              title={BRIEFING_PACKAGE.HIL_BRIEFING}
              titleColorClass={textColorClassName}
            />
            <Button
              text={BRIEFING_PACKAGE.DOWNLOAD}
              className={downloadButtonClassName}
              textClassName={downloadButtonTextClass}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BriefingPackage;
