import React from 'react';
import Icon from '../Icon/Icon';
import PulseNotificationIcon from '../PulseNotificationIcon/PulseNotificationIcon';
import classNames from 'classnames';
import { EMPTY_STATE } from '../../utils/constants';

const flightNameClassNames = 'font-head-bold text-white text-24 leading-[26px]';
const textClassNames = 'font-body-thin text-white text-18 leading-[17px]';
const extraDetailsClassNames =
  'self-end text-12 text-white font-body-text leading-[12px]';
const timeClassNames = 'font-body-thin text-white  text-30 leading-[33px]';
const smallContainerClassNames = 'flex gap-32 items-center';
const routeClassNames = 'flex items-center mobile:hidden';
const routeAirportClassNames = 'font-head-light text-18 text-white';
const emptyState = '-';

interface ITimeObject {
  value: string;
  extraDetails: null | string;
}

interface IFlightDetailsHeaderStickyProps {
  flightName: string;
  type: string;
  departureAirport: string;
  arrivalAirport: string;
  registration: string;
  time1: ITimeObject;
  time2: string | null;
  themeColor: string | null;
  isCheckedIn?: boolean;
}

const FlightDetailsHeaderSticky = ({
  flightName,
  type = emptyState,
  departureAirport,
  arrivalAirport,
  registration = emptyState,
  time1,
  time2,
  themeColor,
  isCheckedIn,
  ...others
}: IFlightDetailsHeaderStickyProps) => {
  const containerClassNames =
    'flex justify-between h-[76px] p-24 dark:bg-grey-90 dark:border-2 dark:border-black';

  return (
    <div className={classNames(containerClassNames, themeColor)} {...others}>
      <div className={smallContainerClassNames}>
        <div className="flex gap-4 items-center">
          <div className={flightNameClassNames}>{flightName}</div>
          {process.env.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED?.toUpperCase() !==
            'TRUE' &&
            isCheckedIn && <PulseNotificationIcon />}
        </div>
        <div className={routeClassNames}>
          <div className={routeAirportClassNames}>{departureAirport}</div>
          <div className="px-8">
            <Icon variant="arrow" className="fill-white" />
          </div>
          <div className={routeAirportClassNames}>{arrivalAirport}</div>
        </div>
        <div className={textClassNames}>{`${type || EMPTY_STATE}/${
          registration || EMPTY_STATE
        }`}</div>
      </div>
      <div className={smallContainerClassNames}>
        <div className="flex gap-8">
          <div className={textClassNames}>{time1.value}</div>
          {time1.extraDetails && (
            <div className={extraDetailsClassNames}>{time1.extraDetails}</div>
          )}
        </div>
        <div className={timeClassNames}>{time2}</div>
      </div>
    </div>
  );
};

export default FlightDetailsHeaderSticky;
