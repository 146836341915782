import moment from 'moment';

export const processTimeLeft = ({ start, end }, currentTime) => {
  //Determine if process is in the past
  const processTimePassedValue =
    (moment(end, 'DD-MM-YYYY HH:mm').format('X') -
      moment(currentTime, 'HH:mm').format('X')) /
    60

  //If process has passed, don't show remainign time.
  if (!processTimePassedValue.toString().includes('-')) {
    let startTime = moment(start, 'DD-MM-YYYY HH:mm').format('HH:mm');
    let isAfterTime = moment(startTime, 'HH:mm').isAfter(
      moment(currentTime, 'HH:mm'),
      'minute'
    );
    if (!isAfterTime) {
      const processDuration = moment
        .duration(processTimePassedValue, 'minutes')
        .format('h:mm', { trim: false });
      return processDuration.toString().replace('-', '');
    }
  }
}
