import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IInitialState {
  query: string | null;
}

const initialState: IInitialState = {
  query: null,
};

export const searchReducer = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
  },
});

export const { setQuery } = searchReducer.actions;

export const selectQuery = (state: RootState) => state.searchReducer.query;

export default searchReducer.reducer;
