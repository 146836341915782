import React from 'react';

interface ILettersCounter {
  maxNumber: number;
  currentNumber: number;
}

const LettersCounter = ({
  maxNumber = 50,
  currentNumber = 0,
}: ILettersCounter) => {
  return (
    <div className="absolute bottom-0 right-[7px] text-[11px] text-grey-40">
      {currentNumber}/{maxNumber}
    </div>
  );
};

export default LettersCounter;
