import moment from 'moment';

export const processDelayTime = ({ delayStart, start }) => {
  const processTimePassedValue =
    (moment(delayStart, 'DD-MM-YYYY HH:mm').format('X') -
      moment(start, 'DD-MM-YYYY HH:mm').format('X')) /
    60;
  const processDuration = moment
    .duration(processTimePassedValue, 'minutes')
    .format('h:mm', { trim: false });

  return processDuration !== '0:00' ? `+ ${processDuration.replace('-', '')}` : true;
};
