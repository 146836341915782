import { setContext } from '@apollo/client/link/context';
import {
  ApolloLink,
  FetchResult,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import {
  getAccessToken,
  getIdToken,
  getIsTokenRefreshing,
  getRefreshToken,
  getUserId,
} from './helpers';
import { createUniqueId } from './createUniqueId';
import { onError } from '@apollo/client/link/error';
import { logout, refreshToken } from '../redux/reducers/authReducer';
import { AppDispatch } from '../redux/store';
import { INVALID_TOKEN } from './constants';

const QUERIES_TO_CACHE = [
  'CachedTelephonyBook',
  'ChatMessages',
  'CachedMyFlights',
  'GetUserSettings',
  'FlightCheckedInRoles',
  'UpdateUserSettings',
  'GetMyFlights',
  'SearchFlights',
  'NotificationsHistory',
  'Notifications',
];

const filterCache = (
  operationName: string,
  cache: InMemoryCache,
  response: FetchResult<Record<string, any>>
) => {
  const shouldCache = QUERIES_TO_CACHE.includes(operationName);

  if (shouldCache) {
    console.log(`Caching response for ${operationName}`);
  } else {
    cache.evict({ id: 'ROOT_QUERY', fieldName: operationName });
  }

  return response;
};

export const getApolloLinks = (dispatch: AppDispatch, cache: InMemoryCache) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_TAC_BE_URL}/graphql`,
  });

  const offlineLink = new ApolloLink((operation, forward) => {
    if (!navigator.onLine) {
      return null;
    }
    return forward(operation);
  });

  const customCacheLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) =>
      filterCache(operation.operationName, cache, response)
    )
  );

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${getAccessToken()}`,
        'id-token': getIdToken(),
        'X-Request-ID': createUniqueId(),
        'user-id': getUserId(),
        'x-tac-authorization': getAccessToken(),
      },
    };
  });

  const errorLink = onError((errorHandler) => {
    const { graphQLErrors, networkError } = errorHandler;

    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) {
      if (
        ('statusCode' in networkError && networkError.statusCode === 401) ||
        ('result' in networkError &&
          typeof networkError.result !== 'string' &&
          networkError.statusCode === 403 &&
          networkError.result.message === INVALID_TOKEN)
      ) {
        const rToken = getRefreshToken();
        const isTokenRefreshing = getIsTokenRefreshing();
        if (rToken && !isTokenRefreshing) {
          dispatch(refreshToken({ rToken }))
            .then(() => {
              window.location.reload();
            })
            .catch(() => dispatch(logout()));
        } else if (!rToken && !isTokenRefreshing) {
          dispatch(logout());
          return;
        }
      }
      console.error(`[Network error]: ${networkError}`);
    }
  });

  return from([customCacheLink, offlineLink, authLink, errorLink, httpLink]);
};
