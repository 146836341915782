import { useLazyQuery, useQuery } from '@apollo/client';
import { SEARCH_TELEPHONY } from '../../graphql/telephony/searchTelephony.queries';
import { Query, TelephonySearchOutput } from '../generated/graphql';
import { IDataResponse } from '../types';

interface ISearchLazy extends IDataResponse<TelephonySearchOutput> {
  getSearchValue: (searchValue: string) => Promise<TelephonySearchOutput>;
}

export const useSearchTelephony = (
  searchValue: String
): IDataResponse<TelephonySearchOutput> => {
  const { data, loading, error } = useQuery<Pick<Query, 'searchTelephony'>>(
    SEARCH_TELEPHONY,
    {
      variables: {
        searchValue,
      },
    }
  );

  return {
    data: data?.searchTelephony ?? null,
    loading,
    error,
  };
};

export const useSearchTelephonyLazy = (): ISearchLazy => {
  const [onSearch, { data, loading, error }] =
    useLazyQuery<Pick<Query, 'searchTelephony'>>(SEARCH_TELEPHONY);

  const getSearchValue = async (searchValue: string) => {
    const result = await onSearch({
      variables: {
        searchValue,
      },
    });

    return result?.data?.searchTelephony ?? null;
  };

  return {
    data: data?.searchTelephony ?? null,
    loading,
    error,
    getSearchValue,
  };
};
