import { useQuery } from '@apollo/client';
import { GET_USER_SETTINGS } from '../../graphql/settings/settings.queries';
import { Query, UserSettingsOutput } from '../generated/graphql';
import { IDataResponse } from '../types';

interface IGetUserSettingsParams {
  skip?: boolean;
}

export const useGetUserSettings = (
  options: IGetUserSettingsParams | null = null
): IDataResponse<UserSettingsOutput> => {
  const { data, loading, error } = useQuery<Pick<Query, 'getUserSettings'>>(
    GET_USER_SETTINGS,
    options ?? undefined
  );

  return { data: data?.getUserSettings ?? null, loading, error };
};
