import { useMutation } from '@apollo/client';
import {
  EtdFlightUpdateInput,
  Mutation,
  SetEtdForFlightOutput,
} from '../generated/graphql';
import { IMutationResponse } from '../types';
import { UPDATE_ETD_FOR_FLIGHT } from '../../graphql/etdSetting/updateEtd.mutation';

interface IUpdateEtd extends IMutationResponse<SetEtdForFlightOutput> {
  onUpdateEtd: (
    input: EtdFlightUpdateInput
  ) => Promise<SetEtdForFlightOutput | null>;
}

export const useUpdateEtd = (): IUpdateEtd => {
  const [updateETD, { data, error, loading }] = useMutation<
    Pick<Mutation, 'updateEtdForFlight'>
  >(UPDATE_ETD_FOR_FLIGHT);

  const onUpdateEtd = async (input: EtdFlightUpdateInput) => {
    try {
      const result = await updateETD({
        variables: {
          input,
        },
      });

      return result?.data?.updateEtdForFlight ?? null;
    } catch (error) {
      return null;
    }
  };
  return {
    onUpdateEtd,
    data: data?.updateEtdForFlight ?? null,
    loading,
    error,
  };
};
