import React, { useState } from 'react';
import LettersCounter from '../LettersCounter/LettersCounter';
import classNames from 'classnames';
import { INVALID_INPUT } from '../../utils/constants';
import { isNotificationInputValid } from '../../utils/helpers';

interface IInput {
  containerClassName?: string;
  inputClassName?: string;
  maxLength?: number;
  defaultValue?: string;
  id?: string;
}

const Input = ({
  containerClassName,
  inputClassName,
  maxLength,
  defaultValue,
  id,
}: IInput) => {
  const [lettersCounter, setLettersCounter] = useState(0);
  const [isError, setIsError] = useState(false);
  const onBlurHandle = (e) => {
    const currentValue = e?.target?.value;

    isNotificationInputValid(currentValue)
      ? setIsError(false)
      : setIsError(true);
  };

  const inputContainerClass = classNames(containerClassName, {
    'border-red': isError,
  });
  const errorClassName = 'text-red pt-[4px]';

  return (
    <div className="grid w-full">
      <div className={inputContainerClass}>
        <input
          id={id}
          name="subject"
          type="text"
          onBlur={onBlurHandle}
          defaultValue={defaultValue ?? ''}
          className={inputClassName}
          maxLength={maxLength}
          onChange={(e) => setLettersCounter(e.target.value.length)}
        />
        {maxLength && (
          <LettersCounter
            maxNumber={maxLength}
            currentNumber={lettersCounter}
          />
        )}
      </div>
      {isError && <p className={errorClassName}>{INVALID_INPUT}</p>}
    </div>
  );
};

export default Input;
