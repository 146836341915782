import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import { NavLink } from 'react-router-dom';
import {
  MAIN_NAV_LINKS,
  ALL_CHATS,
  NOTIFICATIONS_CENTER,
  TELEPHONY,
  MY_FLIGHTS,
  SEARCH_FLIGHTS,
  BROADCAST,
} from '../../../utils/constants';
import Grid from '../../Grid/Grid';
import Button from '../../Button/Button';
import Badge from '../../Badge/Badge';
import { MainNavIconVariant } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTotalUnreadMessagesCount,
  toTitleCase,
} from '../../../utils/helpers';
import { selectChatMessages } from '../../../redux/reducers/chatReducer';
import {
  selectCheckedInUsers,
  selectShouldFetchCheckedinFlights,
  setShouldFetchCheckedinFlights,
} from '../../../redux/reducers/checkinReducer';
import useCheckPermissions, {
  CheckType,
} from '../../../utils/hooks/useCheckPermissions';
import { Permission } from '../../../utils/generated/graphql';
import { useGetMyFlights } from '../../../utils/hooks/useGetMyFlights';

const containerClassNames = 'z-70 h-[100dvh] bg-grey-6 dark:bg-black';
const closeButtonClassNames = 'h-56 flex justify-end';
const navItemClassNames = 'list-none bg-grey-6 dark:bg-black';
const linkClassNames = 'flex flex-col gap-1.5 items-center py-24';
const nameClassNames = 'text-primary text-14 font-head-light dark:text-grey-12';

interface IMainNavLinkMobile {
  icon: MainNavIconVariant;
  name?: string;
  link: string;
  onClose: () => void;
  isCheckedIn?: boolean;
}

interface IMainNav {
  onClose: () => void;
  notificationCount: number;
  handleNotificationClick: () => void;
  handleChatClick: () => void;
  handleTelephonyClick: () => void;
}

const MainNavLinkMobile = ({
  icon,
  name,
  link,
  onClose,
  isCheckedIn,
}: IMainNavLinkMobile) => {
  return (
    <li className={navItemClassNames} onClick={() => onClose?.()}>
      <NavLink to={link} className={linkClassNames}>
        {({ isActive }) => {
          const iconClassNames = classNames({
            'fill-primary dark:fill-grey-12': !isActive,
            'fill-white dark:fill-grey-80': isActive,
          });
          const iconContainerClassNames = classNames(
            'w-[58px] h-56 flex justify-center items-center relative',
            {
              'bg-primary rounded-4 dark:bg-grey-12': isActive,
            }
          );
          return (
            <>
              <div className={iconContainerClassNames}>
                {link === '/favourites' && isCheckedIn && (
                  <span
                    className={
                      'bg-secondary absolute top-0 h-2 w-2 rounded-full right-[5px] top-[5px]'
                    }></span>
                )}
                <Icon
                  width={icon === 'admin' ? 65 : 40}
                  height={icon === 'admin' ? 65 : 40}
                  variant={icon}
                  className={iconClassNames}
                />
              </div>
              <div className={nameClassNames}>{name}</div>
            </>
          );
        }}
      </NavLink>
    </li>
  );
};

const MainNav = ({
  onClose,
  notificationCount,
  handleNotificationClick,
  handleChatClick,
  handleTelephonyClick,
}: IMainNav) => {
  const dispatch = useDispatch();
  const { data, refetchMyFlights } = useGetMyFlights();
  const shouldFetchCheckedin = useSelector(selectShouldFetchCheckedinFlights);
  const isCheckedIn = !!data?.filter((flight) => flight.isCheckedIn)?.length;
  const chatMessages = useSelector(selectChatMessages);
  const checkedInRoles = useSelector(selectCheckedInUsers);
  const noNewMessages = getTotalUnreadMessagesCount(
    chatMessages,
    checkedInRoles
  );

  const hasAdminAccess = useCheckPermissions(
    [Permission.READ_ALL_NOTIFICATIONS, Permission.READ_STATION_NOTIFICATIONS],
    CheckType.PARTIAL
  );

  const hasChatAccess = useCheckPermissions(
    [Permission.CHAT_READ, Permission.CHAT_WRITE],
    CheckType.PARTIAL
  );

  const hasTelephonyAccess = useCheckPermissions([Permission.TELEPHONY]);

  const hasMyFlightsAccess = useCheckPermissions([
    Permission.MANAGE_MY_FLIGHTS,
  ]);

  const hasSearchFlightsAccess = useCheckPermissions(
    [Permission.SEARCH_FLIGHTS, Permission.SEARCH_FLIGHTS_BY_CARRIER],
    CheckType.PARTIAL
  );

  const hasNotificationAccess = useCheckPermissions([
    Permission.DISPLAY_NOTIFICATIONS,
  ]);

  const noPermissionLinks: string[] = [];

  !hasSearchFlightsAccess && noPermissionLinks.push(SEARCH_FLIGHTS);
  !hasMyFlightsAccess && noPermissionLinks.push(MY_FLIGHTS);

  const filteredNavigationLinks = MAIN_NAV_LINKS.filter(
    (link) => !noPermissionLinks.includes(link.name)
  );

  const isNavCountOdd = filteredNavigationLinks.length % 2 === 1;

  const emptyCard = <div className="bg-grey-6 dark:bg-black"></div>;

  useEffect(() => {
    if (shouldFetchCheckedin) {
      refetchMyFlights();
      dispatch(setShouldFetchCheckedinFlights(false));
    }
  }, [shouldFetchCheckedin]);

  return (
    <div className={containerClassNames}>
      <div className={closeButtonClassNames}>
        <button
          aria-label="close"
          onClick={() => onClose?.()}
          className="px-[27px] py-[19px]">
          <Icon variant="close" className="fill-primary dark:fill-grey-12" />
        </button>
      </div>
      <nav className="flex flex-col justify-between">
        <Grid
          col={[2, 'px', 'px']}
          className="bg-grey-12 border-y-1 border-grey-12 dark:bg-grey-80 dark:border-grey-80">
          {filteredNavigationLinks.map((item) => (
            <MainNavLinkMobile
              key={item.icon}
              icon={item.icon}
              link={item.link}
              name={item.name}
              onClose={onClose}
              isCheckedIn={isCheckedIn}
            />
          ))}

          {isNavCountOdd && !hasAdminAccess && !hasTelephonyAccess && emptyCard}

          {hasTelephonyAccess && (
            <>
              <div className="flex justify-center pt-[20px] pb-[20px] bg-grey-6 dark:bg-black">
                <Button
                  onClick={handleTelephonyClick}
                  Icon={
                    <div className="flex gap-x-[11px] items-center justify-center">
                      <Icon
                        variant="call"
                        width={40}
                        height={40}
                        className=" dark:fill-grey-12 fill-primary"
                      />
                    </div>
                  }
                  text={
                    <div className="text-primary text-14 font-head-light dark:text-grey-12 mt-10">
                      {TELEPHONY}
                    </div>
                  }
                />
              </div>
              {!isNavCountOdd && !hasAdminAccess && emptyCard}
            </>
          )}
          {hasAdminAccess && (
            <>
              <MainNavLinkMobile
                key="admin"
                icon="admin"
                link="/admin"
                name={BROADCAST}
                onClose={onClose}
                isCheckedIn={isCheckedIn}
              />
              {((isNavCountOdd && hasTelephonyAccess) ||
                (!isNavCountOdd && !hasTelephonyAccess)) &&
                emptyCard}
            </>
          )}
        </Grid>

        <Grid
          col={[2, 'px', 'px']}
          className="bg-grey-12 border-y-1 border-grey-12 dark:bg-grey-80 dark:border-grey-80 fixed bottom-0 w-screen">
          <div className="flex justify-center pt-[20px] pb-[20px] bg-grey-6 dark:bg-black">
            {hasChatAccess &&
              process?.env?.REACT_APP_TAC_CHAT_FEATURE_DISABLED?.toUpperCase() !==
                'TRUE' && (
                <Button
                  onClick={handleChatClick}
                  Icon={
                    <div className="flex gap-x-[11px] items-center justify-center">
                      <Icon
                        variant="chat"
                        width={24}
                        height={24}
                        className=" dark:fill-grey-12 fill-primary"
                      />
                      {!!noNewMessages && (
                        <Icon
                          variant="simpleMessage"
                          className="fill-secondary"
                          width={28}
                          height={28}
                          text={noNewMessages}
                          textClassName="text-primary text-12 dark:text-grey-90"
                        />
                      )}
                    </div>
                  }
                  text={
                    <div className="text-primary text-14 font-head-light dark:text-grey-12 mt-10">
                      {toTitleCase(ALL_CHATS.MY_CHATS.toLowerCase())}
                    </div>
                  }
                />
              )}
          </div>

          <div className="flex justify-center pt-[20px] pb-[20px] bg-grey-6 dark:bg-black">
            {hasNotificationAccess && (
              <Button
                onClick={handleNotificationClick}
                Icon={
                  <div className="flex gap-x-[11px] items-center justify-center">
                    <Icon
                      variant="mute"
                      width={19}
                      height={19}
                      className=" dark:fill-grey-12 fill-primary"
                    />
                    {!!notificationCount && (
                      <Badge
                        text={notificationCount}
                        className="bg-secondary min-w-[42px]"
                        textClassName="text-primary"
                      />
                    )}
                  </div>
                }
                text={
                  <div className="text-primary text-14 font-head-light dark:text-grey-12 mt-10">
                    {NOTIFICATIONS_CENTER}
                  </div>
                }
              />
            )}
          </div>
        </Grid>
      </nav>
    </div>
  );
};

export default MainNav;
